import React, { Component } from "react";

import { Link } from "react-router-dom";

import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'
import {getPrivilegeByName} from '../../privilegeUtils';
import avatar  from "../../../images/user-avatar.jpg";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
let context = null;
const data = [];
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px ',
    position: 'Absolute',
    zIndex: '30000',
    backgroundColor: 'none',
  }
};

export default class pageEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thread: [],
      sliderState: 1,
      superSliderState:'additional',
      modules:'',
      entry: {
        workEx: [],
        education: [],
        dependents: [],
        attributes:[],
       
      },
    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber) {

    this.setState({ sliderState: orderNumber });

  }
  //function for changing the status button on click to open or close
  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }


  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true });
  }



  closeModal() {
    this.setState({ modalIsOpen: false });
  }


  componentDidMount() {
    const id = this.props.match.params.id;

    this.fetchQueryById(this, id);
    // this.fetchThreadById(this, id);
    //this.markAsRead(this,id);
  }

  changeSuperSliderState(name) {

    this.setState({ superSliderState: name });
    if(name==='additional'){
      this.setState({ sliderState: 1 });
    }
    else{
      this.setState({ sliderState: 13 });
    }
  

  }

  markAsRead(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/read/" +
        id
      )
      .then(function (response) {


      })
      .catch(function (error) {
        console.log(error);
      });
  }


  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/getEmployeeById/" +
        id
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  delete = (id) => {
    var result= window.confirm("Are you sure you want to delete?");
    if (result==true){
    axios
      .delete(
        apiBaseUrl2+"businusquery/getEmployeeById/" +
          id
      )
      .then(function(response) {
        debugger;
        console.log(response.data);
        alert('Employee has been deleted');
       // that.setState({ entry: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  }}
  
  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }

  getFormattedDate(millis) {

    return new Date(millis).toDateString();


  }

  toggleOpen = status => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...this.state.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };

  changeDocType(type) {

    this.setState({ docType: type });
  }

  sendMail() {
    var query = context.state.entry;
    axios
      .post(
        apiBaseUrl2+"businusquery/send",
        {

          "name": query.name,
          "email": query.email,
          "message": context.state.message,
          "company": query.company,
          "toe": query.toe,
          "subject": context.state.subject,
          "attachment": context.state.attachment,
          "attachmentName": context.state.attachmentName,
          "queryId": query.id,
          "from": "client",
          "recordType": "MAIL",
          "docType": context.state.docType,
          "senderEmail": context.state.senderEmail
        })
      .then(function (response) {
        debugger;
        alert("SENT");
        context.fetchThreadById(context, query.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    localStorage.setItem("MAILURL", url);


  }




  //updating the status button

  render() {
    
    const {superSliderState, entry} = this.state;    
    const { sliderState} = this.state;
    const active = " active-button"
    const deactive = "";
    return (
      <div className="dedicated-div">
        <div className="row breadCrumb1-row">
          <h5>{entry.firstName} {entry.lastName}   {getPrivilegeByName('Employee').canEdit == true ? <Link style={{marginRight:'50px'}} className="pull-right" to={"/masters/employee/edit-employee/"+entry.id}><i className="fa fa-edit"></i>Edit</Link>:''}
          {getPrivilegeByName('Client').canDelete == true ?   
       <div style={{marginRight:'50px'}} className=" pull-right" onClick={()=>this.delete(entry.id)}><i className="fa fa-trash"></i>Delete</div>:''}
       </h5> </div>
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page">
            <div className="header-div ">
              <div
                className="bar"
                style={{ borderTop: "#1E5B81", color: "blue" }}
              />
              
                  <div className="row ">
                    <div className="col-md-12 info-column account-col2">
                      <div id="field">

                        <div className="container">
                          <div className="row">
                            <h3 className="account-head" > Basic info </h3>
                            <div className="col-md-8">


                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Employee ID :
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.employeeId}</h4>
                                </div>
                              </div>


                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  {" "}
                                  Employee Name :{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.firstName} {entry.lastName}</h4>
                                </div>
                              </div>



                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  {" "}
                                  Email ID :{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.email}</h4>
                                </div>
                              </div>

                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  {" "}
                                  Nick Name :{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.nickName}</h4>
                                </div>
                              </div>



                            </div>
                            <div class="col-md-4">

<img style={{height:'100px'}}src={(this.state.entry.profile!="" && this.state.entry.profile!=undefined) ? this.state.entry.profile : avatar}  className="avatar"/>   

</div>
                          </div>



                          <div className="row " style={{marginTop:'40px'}}>
     <div className={superSliderState == 'additional' ? "col-md-2 active1 superoptions" : "col-md-2 superoptions"} >
       <div className=" " onClick={() => this.changeSuperSliderState('additional')} >Additional Info</div>
     </div>

     <div className={superSliderState == 'access' ? "col-md-2 active1 superoptions" : "col-md-2 superoptions"} >
       <div className="" onClick={() => this.changeSuperSliderState('access')}>Access Controls</div>
     </div>
    
   </div>


{superSliderState==='additional' ? 

   <div className="row " style={{borderTop:'2px solid #4985BD', backgroundColor:'#f7f7f7'}}>
     <div className={sliderState == 1 ? "col-md-1 active2 " : "col-md-1"} >
       <div className="options2 "  onClick={() => this.changeSliderState(1)} ><i className="fa fa-user optionsIcon" style={{fontSize:'12px'}}></i>Personal</div>
     </div>
     <div className={sliderState == 2 ? "col-md-1 active2 " : "col-md-1"} >
       <div className="options5"  onClick={() => this.changeSliderState(2)}><i className="fa fa-briefcase optionsIcon" style={{fontSize:'12px'}}></i>Work</div>
     </div>
     <div className={sliderState == 3 ? "col-md-1 active2 " : "col-md-1"} >
       <div className="options2"  onClick={() => this.changeSliderState(3)}><i className="fa fa-file optionsIcon" style={{fontSize:'12px'}}></i>Summary</div>
     </div>
     <div className={sliderState == 4 ? "col-md-2 active2 " : "col-md-2"} >
       <div className="options3"  onClick={() => this.changeSliderState(4)}><i className="fa fa-suitcase optionsIcon" style={{fontSize:'12px'}}></i>Work Experience</div>
     </div>
     <div className={sliderState == 5 ? "col-md-1 active2 " : "col-md-1"} >
       <div className="options2"  onClick={() => this.changeSliderState(5)}><i className="fa fa-graduation-cap optionsIcon" style={{fontSize:'12px'}}></i>Education</div>
     </div>
     <div className={sliderState == 6 ? "col-md-2 active2 " : "col-md-2"} >
       <div className="options6"  onClick={() => this.changeSliderState(6)}><i className="fa fa-users optionsIcon" style={{fontSize:'12px'}}></i>Dependant</div>
     </div>
     <div className={sliderState == 8 ? "col-md-1 active2 " : "col-md-1"} >
       <div className="options2"  onClick={() => this.changeSliderState(8)}><i className="fa fa-address-card optionsIcon" style={{fontSize:'12px'}}></i>Attributes</div>
     </div>
     <div className={sliderState == 7 ? "col-md-2 active2 " : "col-md-2"} >
       <div className="options3"  onClick={() => this.changeSliderState(7)}><i className="fa fa-university optionsIcon" style={{fontSize:'12px'}}></i>Bank Details</div>
     </div>
    
   </div>

   : superSliderState==='access' ?

  
   <div className="row">
   <h4 className="account-head">Masters Access<button className="add-button" onClick={() => this.addNewContact('modules')} > <i className="fa fa-plus "></i></button></h4>
   <div className="col-md-8">
   
     <table className="add-customer-table" style={{ width: "100%" }}>
       <tr>
         <th className="table-head">Module Name</th>
         <th className="table-head">Add</th>
   
         <th className="table-head">Edit </th>
         <th className="table-head">View </th>
         <th className="table-head">Delete </th>
   
   
       </tr>
   
       {this.state.entry.modules.map((module, i) => (
   
         <tr>
   
           <td className="table-item">
             {module.module}
           </td>
           <td className="table-item"><input 
           disabled={true} type="checkbox" name="canAdd" value={this.state.entry.modules[i].canAdd} checked={this.state.entry.modules[i].canAdd}  /></td>
           <td className="table-item"><input 
           disabled={true} type="checkbox" name="canEdit" value={this.state.entry.modules[i].canEdit} checked={this.state.entry.modules[i].canEdit}  /></td>
           <td className="table-item"><input 
            type="checkbox" name="canView"
            disabled={true}
             value={this.state.entry.modules[i].canView} checked={this.state.entry.modules[i].canView}  /></td>
           <td className="table-item"><input 
           disabled={true} type="checkbox" name="canDelete" value={this.state.entry.modules[i].canDelete} checked={this.state.entry.modules[i].canDelete}  /></td>
         </tr>
   
   
       ))}
   
   
   
     </table></div></div>
   
   
:''}

{/*--------------------------------------------------------1-------------------------------*/}


{sliderState==2 ?

                          <div className="row">
                            <h3 className="account-head">Work</h3>
                            <div className="col-md-6">

                              <div className="form-group">
                                <label className="control-label col-md-4">Department :</label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.department}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">Reporting To :</label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.reportingTo}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">Source of Hire :</label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.sourceOfHire}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">Seating Location :</label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.seatingLocation}</h4>
                                </div>
                              </div>


                            </div>

                            <div className="col-md-6">

                              <div className="form-group">
                                <label className="control-label col-md-4">Location :</label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.location}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">Title :</label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.title}</h4>
                                </div>
                              </div>

                              <div className="form-group">
                                <label className="control-label col-md-4">Date of joining :</label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.dateOfJoining}</h4>
                                </div>
                              </div>

                              <div className="form-group">
                                <label className="control-label col-md-4">Employee Status:</label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.employeeStatus}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">Employee Type :</label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.employeeType}</h4>
                                </div>
                              </div>

                            </div>
                          </div>

:''}

{sliderState==1 ?
                          <div className="row">
                            <h3 className="account-head">Personal</h3>
                            <div className="col-md-6">

                              <div className="form-group">
                                <label className="control-label col-md-4">Mobile :</label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.mobile}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">Other Email :</label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.otherEmail}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">Date of Birth :</label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.dateOfBirth}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">Marital Status :</label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.maritalStatus}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                          <label className="control-label col-md-4">Date of Anniversary:</label>
                          <div className="col-md-7">
                          <h4 className="detail-fields">{entry.dateOfAnn}</h4>
                            
                          </div>
                        </div>



                            </div>

                            <div className="col-md-6">

                              <div className="form-group">
                                <label className="control-label col-md-4">Address :</label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.address}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">Tags :</label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields" >{entry.tags}</h4>
                                </div>
                              </div>



                            </div>
                          </div>

:''}
{sliderState==3 ?
                          <div className="row">
                            <h3 className="account-head">Summary</h3>
                            <div className="col-md-6">

                              <div className="form-group">
                                <label className="control-label col-md-4">Job Description :</label>
                                <div className="col-md-7">
                                  <p className="detail-fields">{entry.jobDescription}</p>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">Ask me About /  Expertise :</label>
                                <div className="col-md-7">
                                  <p className="detail-fields">{entry.expertise}</p>
                                </div>
                              </div>




                            </div>
                            <div className="col-md-6">

                              <div className="form-group">
                                <label className="control-label col-md-4">About Me :</label>
                                <div className="col-md-7">
                                  <p className="detail-fields">{entry.aboutMe}</p>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">Date of Exit :</label>
                                <div className="col-md-7">
                                  <h4 className="detail-fields">{entry.dateOfExit}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-md-4">Gender :</label>
                                <div className="col-md-7">
                                  <h4 className="detail-fields">{entry.gender}</h4>
                                </div>
                              </div>



                            </div>
                          </div>

                          :''}
    {sliderState==4 ?
                          <div className="row">
                            <h3 className="account-head">Work Experience </h3>
                            <div className="col-md-10">

                              <table className="add-customer-table" style={{ width: "100%" }}>
                                <tr>
                                  <th className="table-head">Previous Company</th>
                                  <th className="table-head">Job Title</th>

                                  <th className="table-head">From Date </th>
                                  <th className="table-head">To Date</th>
                                  <th className="table-head">Job Description</th>
                                </tr>
                                {entry.workEx.map((person, i) => (

                                  <tr>
                                    <td className="table-item">
                                      {person.previousCompany}
                                    </td>
                                    <td className="table-item">    {person.jobTitle} </td>
                                    <td className="table-item">
                                      {person.fromDate} </td>
                                    <td className="table-item">
                                      {person.toDate} </td>
                                    <td className="table-item">
                                      {person.jobDescription}</td>
                                  </tr>


                                ))}


                              </table></div></div>
                              :''}

{sliderState==5 ?
                          <div className="row">
                            <h3 className="account-head">Education</h3>
                            <div className="col-md-10">

                              <table className="add-customer-table" style={{ width: "100%" }}>
                                <tr>
                                  <th className="table-head">School Name</th>
                                  <th className="table-head">Degree/Diploma</th>
                                  <th className="table-head">Field(s) of Study</th>
                                  <th className="table-head">Date of completion </th>

                                  <th className="table-head">Additional Notes</th>
                                  <th className="table-head">Interests</th>
                                </tr>
                                {entry.education.map((person, i) => (

                                  <tr>
                                    <td className="table-item">
                                      {person.schoolName}
                                    </td>
                                    <td className="table-item">                       {person.degree}</td>
                                    <td className="table-item">

                                      {person.fieldOfStudy} </td>
                                    <td className="table-item">

                                      {person.dateOfCompletion} </td>

                                    <td className="table-item">
                                      {person.additionalNotes} </td>
                                    <td className="table-item"> {person.interests}  </td>
                                  </tr>


                                ))}


                              </table></div></div>

                              :''}
{sliderState==6 ?
                          <div className="row">
                            <h3 className="account-head">Dependent </h3>
                            <div className="col-md-10">

                              <table className="add-customer-table" style={{ width: "100%" }}>
                                <tr>
                                  <th className="table-head">Name</th>
                                  <th className="table-head">Relationship</th>

                                  <th className="table-head">Date of Birth </th>


                                </tr>
                                {entry.dependents.map((person, i) => (

                                  <tr>

                                    <td className="table-item">{person.name} </td>
                                    <td className="table-item">{person.relationship} </td>
                                    <td className="table-item">{person.dateOfBirth}  </td>

                                  </tr>


                                ))}


                              </table></div></div>


  :''}

{sliderState==7 ?   <div className="container row">
  <div className="col-md-12">
    <div className="row" style={{marginBottom:'-20px'}}>
      <label className="control-label add-header col-md-12">
        Banking Details
      </label>
    </div>
    <div className="form-group">
      <label className="control-label col-md-4">Bank Name :</label>
      <div className="col-md-6">
      <h4 className="detail-fields">{entry.bankName}</h4>
      </div>
    </div>
    <div className="form-group">
      <label className="control-label col-md-4">Branch Name:</label>
      <div className="col-md-6">
      <h4 className="detail-fields">{entry.branchName}</h4>
      </div>
    </div>

    <div className="form-group">
      <label className="control-label col-md-4">Account No:</label>
      <div className="col-md-6">
      <h4 className="detail-fields">{entry.accountNo}</h4>
      </div>
    </div>

    <div className="form-group" >
      <label className="control-label col-md-4">Account Type:</label>
      <div className="col-md-6">
      <h4 className="detail-fields">{entry.accountType}</h4>
      </div>
    </div>
    <div className="form-group">
      <label className="control-label col-md-4">IFSC Code :</label>
      <div className="col-md-6">
      <h4 className="detail-fields">{entry.ifscCode}</h4>
      </div>
    </div>
    <div className="form-group">
      <label className="control-label col-md-4">MICR Code :</label>
      <div className="col-md-6">
      <h4 className="detail-fields">{entry.micrCode}</h4>
      </div>
    </div>
  
  </div>

  
  
</div>      :''}


{sliderState == 8 ?
<div>
  <div className="row" style={{marginTop:'20px'}}>
    <div className="col-md-12">
    <div className="row" style={{marginBottom:'-20px'}}>
      <label className="control-label add-header col-md-12">
        Attributes
      </label>
    </div>
  <div className="text-right" style={{marginRight:'40px'}}>
 {/* <button className="add-button" onClick={this.addNewAttribute} > <i className="fa fa-plus "></i></button> */}
  </div>
              <table className="add-customer-table" style={{width:"100%"}}>
  <tr>
    <th className="table-head">Attributes</th>
    <th className="table-head">Attribute Value</th>
  
    <th className="table-head">Attribute Attachment </th>
   
  </tr>
  {this.state.entry.attributes!=undefined ? this.state.entry.attributes.map((attribute, i) => (
         
         <tr>
            <td className="table-item">
             {attribute.name}
       </td>
         <td className="table-item">{attribute.value}</td>
         <td className="table-item"> 
         <a href={attribute.attachment} download className='form-text cell'>Download</a> </td>
         
         </tr>
         
        
         )) : ''}
 
 
</table></div></div></div> :''}

       
                        </div>


                      </div>
                    </div>
                  </div>
            



            </div>


            
          </div>
        </div>
      </div>
    );
  }
}

