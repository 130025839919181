import React, { Component } from "react";
import "../../style/agency.css";
import "../../animations.css";

/**
 * TeamSectionModal
 * It is the code for adding team section in the template
 * @module TeamSectionModal
 */
class TeamSectionModal extends Component {
  constructor(props) {
    super();
    this.state = {
      title: "",
      products: "",
    };
    this.saveProductSection = this.saveProductSection.bind(this);
  }

  componentDidMount() {}

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);

    console.log(this.state);
  };

  saveProductSection() {
    this.props.saveTeamSection(this.state);
    this.props.toggleModal();
  }

  render() {
    return (
      <div className="modal-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <form>
                <div
                  id="contact-form"
                  className="form-container"
                  data-form-container
                  style={{ color: "rgb(46, 125, 50)", background: "white" }}
                >
                  <div className="row modal-back-color">
                    <div
                      style={{ marginLeft: "38px" }}
                      onClick={this.props.toggleStyleModal}
                    >
                      <h5
                        className="close-button"
                        style={{ marginLeft: "440px" }}
                      >
                        X
                      </h5>
                    </div>
                    <div className="form-title text-center">
                      <h5
                        className="modal-head"
                        style={{ padding: "5px 151px 5px 160px" }}
                      >
                        {" "}
                        Edit Team{" "}
                      </h5>
                    </div>
                  </div>
                  <div className="input-container">
                    <div className="row">
                      <div className="col-md-12">
                        <form id="field">
                          <div className="row modalrow">
                            <div className="col-md-3">Title :</div>
                            <div className="col-md-9 ">
                              {" "}
                              <input
                                className="fieldbox"
                                onChange={this.onChange}
                                type="text"
                                value={this.state.title}
                                name="title"
                              />
                              <br />
                            </div>
                          </div>
                          <div className="row modalrow">
                            <div className="col-md-3"> No. of Cards :</div>
                            <div className="col-md-9 ">
                              {" "}
                              <input
                                className="fieldbox"
                                onChange={this.onChange}
                                type="number"
                                value={this.state.products}
                                name="products"
                              />
                              <br />
                            </div>
                          </div>

                          {/* <button type="button"   className="imagebutton"> Save </button>*/}
                        </form>
                      </div>
                    </div>

                    <div className="row submit-row">
                      <div className="col-md-2"></div>
                      <div className="col-md-4">
                        <button
                          type="button"
                          onClick={() => this.saveProductSection()}
                          className="btn btn-block savebutton submit-form valid"
                        >
                          Save
                        </button>
                      </div>
                      <div className="col-md-4">
                        <button
                          type="button"
                          onClick={() => this.props.toggleModal()}
                          className="btn btn-block cancelbutton submit-form valid"
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamSectionModal;
