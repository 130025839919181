import React, { Component } from "react";

import "../../style/agency.css";
import "../../style/animate.css";
import StyleModal from "../Common/StyleModal";
import QueryModal from "../Query/QueryModal";
import ButtonModal from "../Common/ButtonModal";
import { apiBaseUrl, isValid } from "../apiUtils";
import axios from "axios";

let context = null;

/**
 * QueryModal
 * It is the code for showing section of query in the template .
 * @module Query
 */
class Query extends Component {
  constructor(props) {
    super();
    this.state = {
      sections: [],
      title: "heelllo",
      toggleStyleMode: false,
      hideModal: true,
      Products: [],
      showQueryModal: false,
      to: "",
      cc: "",
      subject: "Queries from users ",
      subhead: "And we will get back to you ASAP",
      newUrl: "",
      styles: {
        backgroundImage:
          "https://www.thesun.co.uk/wp-content/uploads/2021/08/NINTCHDBPICT000459619878-1.jpg?strip=all&quality=100&w=1200&h=800&crop=1",
        backgroundColor: "white",
        padding: "60px 10px 60px 10px",
        margin: "0px 0px 0px 0px",
        color: "black",
      },
      btnBackgroundColor: "#1266FF",
      btnColor: "#fff",
      btnLink: "products",
      btnText: "Tell Me More",
      btnHighlightColor: "blue",

      name: "",
      contact: "",
      company: "",
      email: "",
      showButtonModal: false,
      query: "",
      statename: "",
      city: "",
    };

    context = this;

    this.toggleButtonModal = this.toggleButtonModal.bind(this);
    this.saveStyle = this.saveStyle.bind(this);
    this.toggleStyleModal = this.toggleStyleModal.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.saveMailOptions = this.saveMailOptions.bind(this);
    this.toggleQueryModal = this.toggleQueryModal.bind(this);
  }
  componentDidMount() {
    this.setState({ title: this.props.title });
    this.setState({ styles: this.props.productStyle });
    this.setState({ cc: this.props.cc });
    this.setState({ subject: this.props.subject });
    this.setState({ to: this.props.to });
    this.setState({ subhead: this.props.subhead });
  }

  /**
   * toggleStyleModal()
   * This segment of code allows toggling style modal.
   * @function toggleStyleModal()
   */
  toggleStyleModal() {
    var status = !this.state.toggleStyleMode;
    this.setState({ toggleStyleMode: status });
  }

  toggleButtonModal() {
    var status = !this.state.toggleButtonModal;
    this.setState({ toggleButtonModal: status });
  }
  /**
   * toggleStyleModal()
   * This segment of code allows saves info of the button.
   * @function saveBtnInfo()
   */
  saveBtnInfo(btnInfo) {
    context.setState({ btnBackgroundColor: btnInfo.btnBackgroundColor });
    context.setState({ btnColor: btnInfo.btnColor });
    context.setState({ btnLink: btnInfo.btnLink });
    context.setState({ btnText: btnInfo.btnText });
    context.setState({ btnHighlightColor: btnInfo.btnHighlightColor });
  }

  /**
   * saveStyle()
   * This segment saves element and style of saveStyle.
   * @function saveStyle()
   */
  saveStyle(newstyle) {
    this.setState({ styles: newstyle });
    this.setState({ title: newstyle.title });
    this.props.saveSectionProperties(
      this.props.sectionKey,
      newstyle.title,
      newstyle,
      "no products"
    );
  }

  /**
   * sendMail()
   * This segment makes the api call to server for sending mail
   * @function sendMail()
   */

  sendMail() {
    if (
      isValid(
        this.state.company,
        this.state.statename,
        this.state.email,
        this.state.name,
        this.state.contact,
        this.state.query
      )
    ) {
      alert("Fields cannot be blank");
      return false;
    }

    var data = {
      name: "megha",
      email: this.state.to,
      message: "message",
      subject: "Query: " + this.state.subject,
      userEmail: this.state.email,
      name: this.state.name,
      phone: this.state.contact,
      company: this.state.company,
      query: this.state.query,
      statename: this.state.statename,
      city: this.state.name,
    };
    axios
      .post(apiBaseUrl + "/sendmail", data)
      .then(function (response) {
        console.log("success");
      })
      .catch(function (error) {
        console.log(error);
        {
          alert("Your query has been sent");
        }
      });
  }

  toggleQueryModal() {
    var status = !this.state.showQueryModal;
    this.setState({ showQueryModal: status });
  }

  /**
   * sendMail()
   * This segment explicitly takes the data for
   * @function saveMailOptions()
   */

  saveMailOptions(mail) {
    this.setState({ to: mail.to });
    this.setState({ cc: mail.cc });
    this.setState({ subject: mail.subject });
    this.setState({ subhead: mail.subhead });
  }

  /**
   * toggleHover()
   * This segment is the code for changing the state from btnhover to normal state.
   * @function toggleHover()
   */
  toggleHover(index) {
    this.setState({ isBtnHover: !this.state.isBtnHover });
  }

  onChange = (e) => {
    const state = this.state;
    state[e.target.id] = e.target.value;
    this.setState(state);

    console.log(this.state);
  };

  render() {
    const { styles } = this.state;
    return (
      <div>
        {/* Contact */}
        <section
          style={{
            color: styles.color,
            backgroundColor: styles.backgroundColor,
            backgroundImage:
              styles.backgroundImage != "no image"
                ? styles.backgroundImage
                :  "url('https://c4.wallpaperflare.com/wallpaper/269/648/952/simple-background-texture-digital-art-textured-wallpaper-preview.jpg')",
            padding: styles.padding,
            margin: styles.margin,
          }}
          id={this.props.title.replace(/\s/g, "")}
        >
          {this.state.toggleButtonModal ? (
            <ButtonModal
              sections={this.state.sections}
              websiteTitle={this.state.websiteTitle}
              saveBtnInfo={this.saveBtnInfo}
              btnLink={this.state.btnLink}
              btnText={this.state.btnText}
              btnColor={this.state.btnColor}
              btnBackgroundColor={this.state.btnBackgroundColor}
              btnHighlightColor={this.state.btnHighlightColor}
              subTitle={this.state.subTitle}
              bannerImageUrl={this.state.bannerImageUrl}
              saveButtonInstance={this.saveButtonInstance}
              saveWebsiteInfo={this.saveWebsiteInfo}
              toggleButtonModal={this.toggleButtonModal}
            />
          ) : (
            ""
          )}
          {this.state.toggleStyleMode ? (
            <StyleModal
              title={this.state.title}
              saveProduct={this.saveStyle}
              toggleStyleModal={this.toggleStyleModal}
              saveStyle={this.saveStyle}
              styleProp={this.state.styles}
            />
          ) : (
            ""
          )}

          {this.state.showQueryModal ? (
            <QueryModal
              saveSocialIcons={this.saveMailOptions}
              toggleQueryModal={this.toggleQueryModal}
              to={this.state.to}
              subhead={this.state.subhead}
              cc={this.state.cc}
              subject={this.state.subject}
            />
          ) : (
            ""
          )}
          <div className="container">
            <div className="row head-row">
              <div className="col-lg-12 text-center animatedParent">
                <h2 className="section-heading  contactdiv animated fadeInUp">
                  <span
                    className="editclass editsign"
                    onClick={() => this.toggleStyleModal()}
                  >
                    <i className="fa editclass fa-edit"></i>
                  </span>
                </h2>
                <h3 className="query-head" style={{ color: styles.color }}>
                  {this.state.title}{" "}
                  <i
                    className="fa editclass fa-edit"
                    onClick={this.toggleQueryModal}
                  ></i>
                </h3>
                <h3 className="query-subhead" style={{ color: styles.color }}>
                  {this.state.subhead == undefined
                    ? "We'll get back to you as soon as we can"
                    : this.state.subhead}
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="webzy-query-fields webzy-form-group animatedParent ">
                      <input
                        className="form-control animated contactdiv fadeInUp "
                        id="name"
                        type="text"
                        placeholder="Your Name *"
                        onChange={this.onChange}
                        required="required"
                        data-validation-required-message="Please enter your name."
                      />
                      <p className="help-block text-danger" />
                    </div>

                    <div className="webzy-query-fields webzy-form-group animatedParent ">
                      <input
                        className="form-control animated contactdiv fadeInUp "
                        id="contact"
                        type="text"
                        placeholder="Contact *"
                        onChange={this.onChange}
                        required="required"
                        data-validation-required-message="Please enter your name."
                      />
                      <p className="help-block text-danger" />
                    </div>

                    <div className="webzy-query-fields  webzy-form-group animatedParent ">
                      <input
                        className="form-control animated contactdiv fadeInUp"
                        id="email"
                        type="email"
                        onChange={this.onChange}
                        placeholder="Email *"
                        required="required"
                        data-validation-required-message="Please enter your email address."
                      />
                      <p className="help-block text-danger" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="webzy-query-fields webzy-form-group animatedParent ">
                      <input
                        className="form-control animated contactdiv fadeInUp "
                        id="statename"
                        type="text"
                        onChange={this.onChange}
                        placeholder="State *"
                        required="required"
                        data-validation-required-message="Please enter your name."
                      />
                      <p className="help-block text-danger" />
                    </div>
                    <div className="webzy-query-fields webzy-form-group animatedParent ">
                      <input
                        className="form-control animated contactdiv fadeInUp "
                        id="city"
                        type="text"
                        onChange={this.onChange}
                        placeholder="City *"
                        required="required"
                        data-validation-required-message="Please enter your name."
                      />
                      <p className="help-block text-danger" />
                    </div>

                    <div className="webzy-query-fields webzy-form-group animatedParent ">
                      <input
                        className="form-control contactdiv animated fadeInUp"
                        id="query"
                        placeholder="Query *"
                        onChange={this.onChange}
                        required="required"
                        data-validation-required-message="Please enter a message."
                        defaultValue={""}
                      />
                      <p className="help-block text-danger" />
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="col-lg-12 text-center">
                    <div id="success " className="animatedParent " />
                    <button
                      onMouseEnter={() => this.toggleHover("btnKnow")}
                      onMouseLeave={() => this.toggleHover("btnKnow")}
                      style={{
                        backgroundColor: this.state.isBtnHover
                          ? this.state.btnHighlightColor
                          : this.state.btnBackgroundColor,
                        color: this.state.btnColor,
                        border: "none",
                        marginTop: "39px",
                      }}
                      className="btn btn-primary btn-xl text-uppercase js-scroll-trigger"
                    >
                      <a href={"#" + this.state.btnLink}>
                        {this.state.btnText}
                      </a>
                    </button>
                    <br />{" "}
                    <i
                      className="fa editclass fa-edit"
                      onClick={this.toggleButtonModal}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Query;
