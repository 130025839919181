import React, { Component } from "react";
import BreadCrumbs from "../../BreadCrumbs";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';

import $ from "jquery";


import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'
import {getPrivilegeByName} from '../../privilegeUtils';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
let context = null;
const data = [];
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               :'0px ',
    position               :'Absolute',
    zIndex                 :'30000'     ,
    backgroundColor         :'none',  
  }
};
 
export default class pageMail extends Component {
  constructor(props) {
    super(props);
    this.state = {
   thread:[],
      sliderState:1,
      superSliderState:'additional',
      mail:{
        contactPersons:[],
        

      },
    };
    context = this;
    this.openModal = this.openModal.bind(this);
   
    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber){

    this.setState({sliderState:orderNumber});

}
  //function for changing the status button on click to open or close
  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }
  
  
  openModal(type) {
    this.changeDocType(type);
    this.setState({modalIsOpen: true});
  }
 
  
 
  closeModal() {
    this.setState({modalIsOpen: false});
  }
 

  componentDidMount() {
    const id = this.props.match.params.id;

    this.fetchQueryById(this, id);
   // this.fetchThreadById(this, id);
    //this.markAsRead(this,id);
  }


  markAsRead(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/read/" +
          id
      )
      .then(function(response) {
       
       
      })
      .catch(function(error) {
        console.log(error);
      });
  }


  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/getmail/" +
          id
      )
      .then(function(response) {
        debugger;
        console.log(response.data);
        that.setState({ mail: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  
  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  delete = (id) => {
    var result= window.confirm("Are you sure you want to delete?");
    if (result==true){
    axios
      .delete(
        apiBaseUrl1+"businusquery/getOrganisationById/" +
          id
      )
      .then(function(response) {
        debugger;
        console.log(response.data);
        alert('Organisation has been deleted');
       // that.setState({ entry: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  }}
  
  fetchThreadById(that, id) {
    axios
      .get(
        apiBaseUrl1+"businusquery/listmail/" +
          id
      )
      .then(function(response) {
        debugger;
        console.log(response.data);
        var threads = response.data=="" ? [] : _.sortBy(response.data,'date').reverse();
        that.setState({ thread: threads});

        var documents = _.where(threads,{docType:"Document"});
        var proposals = _.where(threads,{docType:"Proposal"});
        var mails = _.where(threads,{docType:"Mail"});
        that.setState({documents});
        that.setState({proposals});
        that.setState({mails});
        
      
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }

  getFormattedDate(millis){

    return new Date(millis).toDateString();


  }

 
  
  changeDocType(type){

    this.setState({docType:type});
  }

  sendMail() {
     var query = context.state.entry;
    axios
      .post(
        apiBaseUrl1+"businusquery/send",
        {
          
      "name": query.name,
      "email": query.email,
      "message":context.state.message,
      "company":query.company, 
      "toe":query.toe,
      "subject":context.state.subject,
      "attachment":context.state.attachment,
      "attachmentName":context.state.attachmentName,
      "queryId":query.id,
      "from":"client",
      "recordType":"MAIL",
      "docType":context.state.docType,
      "senderEmail":context.state.senderEmail
        })
      .then(function(response) {
        debugger;
      alert("SENT");
      context.fetchThreadById(context,query.id);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  setMailPageUrl(){
  var url = window.location.href.replace(window.location.origin,"");
  localStorage.setItem("MAILURL",url);


  }

  goBack = () =>{
    window.history.back();
  }




  //updating the status button
  
  render() {
    const {sliderState, entry,superSliderState} = this.state;
    const active = " active-button"
    const deactive = "";
    
  
    return (
      <div className="Div">
        <div className="row breadcrumb1-row" style={{marginLeft:'-5px'}} >
      <div className="col-md-12"><BreadCrumbs/></div>
    
  </div>
{/* showing all the details of a single item from the table at the backend*/}

<div className="row">
      
      <div className="dedicated-page" style={{padding:'0px'}}>
   <div className="row-resource-head">
                 <h2 className="resource-head">{this.state.mail.docType!=undefined ? 
     this.state.mail.docType="mail" ? "Message" :this.state.mail.docType.replace(/([A-Z]+)/g, ' $1') : ''}</h2>
                 </div>
        
          <div className="row">
            <div className="col-md-10">
            
            <h2 className="mail-head">
           
           <span> {this.state.mail.from =="admin" ? 'To: ' : 'From: '}
            {(typeof this.state.mail.sendTo =="object" && this.state.mail.sendTo!=null) ? this.state.mail.sendTo.map((item,i)=>{
              return <>{item.email}, </>
            }): this.state.mail.sendTo  } </span>
            </h2>
          
            </div>
           
           <div className="col-md-2"><button onClick={this.goBack} className="back-buttons"><i className="fa fa-arrow-left" aria-hidden="true"></i>Back</button></div>
      
       
       
            </div>


            <div className="row content1-div  text-left">
             <div className="row">
               <div className="col-md-2">
              
               </div>
               <div className="col-md-8">
                 
               </div>
               <div className="col-md-2">
               <div className="date-text">{this.getFormattedDate(this.state.mail.date)}</div>
               </div>
               </div>   
               <div className="row">
               <div className="col-md-10">
                
               <div>
               <div className="subject-text"><i className="fa fa-caret-right" style={{color:'#1e5a81;',fontSize:'25px'}} aria-hidden="true"></i>{this.state.mail.subject}</div>
               <div className="message-text" dangerouslySetInnerHTML={{ __html: this.state.mail.message}}></div>
              </div>
               </div>
               <div className="col-md-2">
              
               </div>
               </div>   
               <div className="row" style={{marginTop:'50px'}}>

               {this.state.mail.attachment!="NA" && this.state.mail.attachment  ? this.state.mail.attachment.map((item,index) => (
                     <div className="col-md-4">
                <a href={item.attachment} download><button className="panel-buttons"><i class="fa fa-paperclip" aria-hidden="true"></i>Download {item.name}</button></a>
                </div>  )):  <button   style={{border:'none !important '}}>Attachment not uploaded </button>}
             
                </div> 
            <div className="row">
   

             <div className="col-md-1 message-text"  style={{color:'black'}}>Tags:</div>
             <div className="col-md-11">
            {this.state.mail.tags? this.state.mail.tags.map((item,i)=>


      { return <button className="tags" >{item.value}</button>;
            }):""}
            </div></div>
            
            
         

                
                
                 

</div> 
            </div>

          
           </div>

           

     

          
           
  
      



            
       
      </div>
    



 
   
   );
 }
}

