import React, { Component } from "react";

import BreadCrumbs from "../../BreadCrumbs";
import { Link } from "react-router-dom";

import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1, apiBaseUrl5, domain } from "../../apiUtils";
import { apiBaseUrl2 } from "../../apiUtils";
import FileBase64 from "react-file-base64";
import Modal from "react-modal";
import { ChatFeed, Message } from "react-chat-ui";
import { getPrivilegeByName } from "../../privilegeUtils";
import { Multiselect } from "multiselect-react-dropdown";
import ReactExport from "react-export-excel";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import _ from "underscore";
import AssignmentUserCommunication from "../../common/AssignmentUserCommunication";
import { memberExpression } from "@babel/types";
let context = null;
const data = [];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px ",
    position: "Absolute",
    zIndex: "30000",
    backgroundColor: "none",
  },
};

export default class editAssignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      subject: "",
      message: "",
      priority: "",
      id: "",
      attachment: "NA",
      thread: [],
      sendTo: "",
      documents: [],
      proposals: [],
      mails: [],
      entry: {
        checklists: [],
        selectedOrganisation: "",
      },
      assignmentDocuments: [],
      assignmentComments: [],
      paymentStatusList: [],
      progressStatusList: [],
      templates: [],
      issues: [],
      expenses: [],
      progressLogs: [],
      modalIsOpen: false,
      docType: "MAIL",
      sliderState: 1, //sta//state for storing the response from the backend,
      superSliderState: "clients",
    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber) {
    this.setState({ sliderState: orderNumber });
  }

  //function for changing the status button on click to open or close
  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);

    console.log(this.state);
  };

  handleOnTemplateChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    state["selectedUpdate"] = e.target.value;

    this.setState(state);

    console.log(this.state);
  };

  onChangeStatus = (e) => {
    const entry = this.state.entry;
    entry[e.target.name] = e.target.value;
    this.setState({ entry: entry });

    this.updateStatus();
  };

  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  componentDidMount() {
    const id = this.props.match.params.id;

    this.setState({ id });
    this.fetchQueryById(this, id);
    // this.fetchThreadById(this, id);
    this.fetchTemplatesForUpdate(this);
    // this.fetchTemplates(this);
    this.fetchStatus(this, "payment");
    this.fetchStatus(this, "progress");
    //this.markAsRead(this, id);
  }

  getColor(status) {
    var paymentStatus = _.findWhere(this.state.paymentStatusList, {
      paymentStatus: status,
    });
    return paymentStatus != undefined ? paymentStatus.paymentStatusColor : "grey";
  }
  getProgressColor(status) {
    var progressStatus = _.findWhere(this.state.progressStatusList, {
      progressStatus: status,
    });
    return progressStatus != undefined
      ? progressStatus.progressStatusColor
      : "grey";
  }
  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(apiBaseUrl1 + "businusquery/getProjectById/" + id)
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  delete = (id) => {
    axios
      .delete(
        apiBaseUrl1 +
          "businusquery/getProjectById/" +
          id +
          "/" +
          localStorage.getItem("employeeId")
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        alert("entry deleted");
        // that.setState({ entry: response.data });
        window.location.href= domain+"/assignment-master/assignment/view-assignment"
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  
  getFiles(files) {
    this.setState({ selectedFile: files[0] });
  }

  getFormattedDate(millis) {
    return new Date(millis).toDateString();
  }

  addNewCheckList() {
    var entry = this.state.entry;

    if (entry.checklists != undefined) {
      entry.checklists.push({
        name: "",
        isDone: false,
        description: "",
      });
    } else {
      entry["checklists"] = [];
      entry.checklists.push({
        name: "",
        isDone: false,
        description: "",
      });
    }

    this.setState({ entry });
  }

  onCheckboxClick = (e, i) => {
    var query = context.state.entry;

    query.checklists[i].isDone = !query.checklists[i].isDone;

    axios
      .post(apiBaseUrl1 + "businus/project/update/" + context.state.id, {
        status: query.status != undefined ? query.status : "open",
        paymentStatus:
          query.paymentStatus != undefined
            ? query.paymentStatus
            : '',
        checklists: query.checklists,
      })
      .then(function (response) {
        context.setState({ entry: query });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  addNewWorkflow = (work) => {
    var query = context.state.entry;

    let workflows = query.workflow;

    if (workflows) {
      workflows.push(work);
    } else {
      workflows = [work];
    }

    axios
      .post(apiBaseUrl1 + "businus/project/update/" + context.state.id, {
        workflow: workflows,
      })
      .then(function (response) {
        context.setState({ entry: query });
        alert("Workflow added");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  addNewModule = (type, selected) => {
    var query = context.state.entry;

    let list = query[type];

    if (list) {
      list.push(selected);
    } else {
      list = [selected];
    }

    axios
      .post(apiBaseUrl1 + "businus/project/update/" + context.state.id, {
        [type]: list,
      })
      .then(function (response) {
        context.setState({ entry: query });
        alert(type + " added");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  updateStatus = (type, value) => {
    var query = context.state.entry;

    if (type == "checklists") {
      if (query.checklists != undefined) {
        query.checklists.push({
          name: value,
          isDone: false,
        });
      } else {
        query["checklists"] = [];
        query.checklists.push({
          name: value,
          isDone: false,
        });
      }
    }

    axios
      .post(apiBaseUrl1 + "businus/project/update/" + context.state.id, {
        status: query.status != undefined ? query.status : "open",
        paymentStatus:
          query.paymentStatus != undefined
            ? query.paymentStatus
            : "Invoice Pending",
        checklists: query.checklists != undefined ? query.checklists : "NA",
      })
      .then(function (response) {
        debugger;
        alert("success");
        //context.fetchThreadById(context, query.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  toggleOpen = (status) => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...this.state.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };

  changeDocType(type) {
    this.setState({ docType: type });
  }

  onSelect(optionsList, selectedItem) {
    context.setState({ team: optionsList });

    console.log(optionsList, selectedItem);
  }

  onRemove(optionList, removedItem) {
    context.setState({ team: optionList });
  }

  fetchTemplatesForUpdate(that, source) {
    axios
      .get(apiBaseUrl1 + "businus/template/list/Update")
      .then(function (response) {
        console.log(response.data);
        that.setState({ templates: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fetchStatus(that, status) {
    axios
      .get(
        apiBaseUrl1 +
          "businus/status/list/" +
          status +
          "/" +
          localStorage.getItem("employeeId")
      )
      .then(function (response) {
        console.log(response.data);

        if (status == "payment") {
          that.setState({ paymentStatusList: response.data });
        } else {
          that.setState({ progressStatusList: response.data });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    localStorage.setItem("MAILURL", url);
  }

  //updating the status button

  render() {
    const { sliderState, superSliderState, entry } = this.state;
    const active = " active-button";
    const deactive = "";
    return (
      <div className="Div">
        <div className="row breadcrumb-row">
          <BreadCrumbs />
          <h4>
            {" "}
            {/* {entry.parentId != "0" ? (
              <button className="download-button-wrapper">
                {" "}
                <Link
                  style={{ marginRight: "50px" }}
                  className="pull-right"
                  to={"/assignment/view-assignment/" + entry.parentId}
                >
                  <i className="fa fa-plus"></i>Created From
                </Link>
              </button>
            ) : (
              ""
            )} not sure of this as of now*/}
            {getPrivilegeByName("Assignment").canEdit == true ? (
              <button
                className="download-button-wrapper"
                style={{ marginLeft: "20px", marginRight: "20px" }}
              >
                {" "}
                <Link
                  style={{ marginRight: "50px" }}
                  className="pull-right"
                  to={
                    "/assignment-master/assignment/edit-assignment/" + entry.id
                  }
                >
                  <i className="fa fa-edit"></i>Edit
                </Link>
              </button>
            ) : (
              ""
            )}
            {getPrivilegeByName("Assignment").canDelete == true ? (
              <button className="download-button-wrapper">
                {" "}
                <div
                  style={{ marginRight: "50px" }}
                  className=" pull-right"
                  onClick={() => this.delete(entry.id)}
                >
                  <i className="fa fa-trash"></i>Delete
                </div>
              </button>
            ) : (
              ""
            )}
          </h4>
        </div>

        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page">
            <div className="header-div2 ">
              <div
                className="bar"
                style={{ borderTop: "#1E5B81", color: "blue" }}
              />
              <div className="row">
                <div className="col-md-9">
                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        Template:
                      </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">
                        {entry.selectedTemplate}
                      </h4>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        Assignment Id:
                      </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">{entry.assignmentId}</h4>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        Assignment Title:
                      </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">{entry.assignmentTitle}</h4>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        Assignment Overview:
                      </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <p
                        style={{ padding: "20px" }}
                        className="detail-fields max-200"
                      >
                        <div
                          className=""
                          dangerouslySetInnerHTML={{
                            __html: entry.assignmentOverview,
                          }}
                        ></div>
                      </p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        Start Date:
                      </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">{entry.startDate}</h4>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        End Date:
                      </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">{entry.endDate}</h4>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        Billing Type:
                      </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">{entry.billingType}</h4>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        Priority:
                      </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">{entry.priority}</h4>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        Tags:
                      </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">
                        {entry.tags != undefined
                          ? entry.tags.map((member) => {
                              return (
                                <span>
                                  {member.label} , <br />
                                </span>
                              );
                            })
                          : ""}
                      </h4>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        Visible To Client:
                      </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">
                        <span>{entry.visibleToClient}</span>
                      </h4>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        Client:
                      </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">
                        {entry.selectedClient != undefined
                          ? entry.selectedClient.name
                          : ""}
                      </h4>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        Client Company:
                      </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">
                        {entry.selectedClient != undefined &&
                        entry.selectedClient.companyName != undefined
                          ? entry.selectedClient.companyName
                          : "Not available"}
                      </h4>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        Organisation:
                      </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">
                        <span>{entry.selectedOrganisation.name || "NA"}</span>
                      </h4>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        Team Lead:
                      </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">
                        {entry.teamLead != undefined ? entry.teamLead.name : ""}
                      </h4>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        Team Members:
                      </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">
                        {entry.team != undefined
                          ? entry.team.map((member) => {
                              return (
                                <span>
                                  {member.name} , <br />
                                </span>
                              );
                            })
                          : ""}
                      </h4>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        Recurrance Frequency:
                      </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">
                        {entry.recurringFrequency
                          ? entry.recurringFrequency
                          : "Not assinged"}
                      </h4>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        Reminding Frequency:
                      </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">
                        {entry.remindingFrequency
                          ? entry.remindingFrequency
                          : "Not assinged"}
                      </h4>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        Occurances:
                      </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">
                        {entry.recurringNo ? entry.recurringNo : "Not assigned"}
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="head-button1">
                    <select
                      onChange={this.onChangeStatus}
                      style={{
                        backgroundColor: this.getColor(entry.paymentStatus),
                      }}
                      className="payStatus"
                      name="paymentStatus"
                      value={entry.paymentStatus}
                    >
                      {this.state.paymentStatusList.map((value) => {
                        return <option>{value.paymentStatus}</option>;
                      })}
                    </select>
                  </div>
                  <div className="head-button2">
                    <select
                      onChange={this.onChangeStatus}
                      style={{
                        backgroundColor: this.getProgressColor(entry.status),
                      }}
                      className="processStatus"
                      value={entry.status}
                      name="status"
                    >
                      {this.state.progressStatusList.map((value) => {
                        return <option>{value.progressStatus}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="multidiv">
              {this.state.id != "" ? (
                <AssignmentUserCommunication
                  id={this.state.id}
                  isDedicatedPage={false}
                  addNewWorkflow={this.addNewWorkflow}
                  addNewModule={this.addNewModule}
                  entry={this.state.entry}
                  type="assignment"
                  onCheckboxClick={this.onCheckboxClick}
                  addNewChecklist={this.updateStatus}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
