export const baseFilePath = "template-engine-images/uploadedImages";

//FOR LOCAL
// export const domain="http://localhost:3000";

//on deployment to whichever domain change this to the domain
export const domain = "test.teamdashboard.in";
export const enviroment = "LIVE";

//FOR LIVE 
export const apiBaseUrl =
  "https://0i22oiysad.execute-api.us-east-1.amazonaws.com/live/";

//for assignment  
export const apiBaseUrl1 =
  "https://j6z8ay0a1f.execute-api.us-east-1.amazonaws.com/live/";
// organisations
export const apiBaseUrl2 =
  "https://0i22oiysad.execute-api.us-east-1.amazonaws.com/live/";
// employees

export const apiBaseUrl3 =
  "https://2o68aa4y7c.execute-api.us-east-1.amazonaws.com/live/";
//checklists,resources
export const apiBaseUrl4 =
  "https://3vdyc7gjgj.execute-api.us-east-1.amazonaws.com/dev/";

export const apiBaseUrl5 =
  " https://p494o2epsk.execute-api.us-east-1.amazonaws.com/live/";

//slegal,queries
export const apiBaseUrl6 =
  "https://io9jvi51rg.execute-api.us-east-1.amazonaws.com/dev/templateengine"; //"https://86plcsddn8.execute-api.us-east-1.amazonaws.com/live/templateengine";
//webzy

export const apiIssueMaster =
  "https://48249ea0fl.execute-api.us-east-1.amazonaws.com/live/";
//issue

export const apiLogsMaster =
  "https://2zjs4kqxqd.execute-api.us-east-1.amazonaws.com/live/";
// logs
export const apiTaskMaster =
  " https://9by0a6kkfa.execute-api.us-east-1.amazonaws.com/live/";
// task

export const apiWebzyUrl =
  "https://86plcsddn8.execute-api.us-east-1.amazonaws.com/live/";
export const apiBaseUrl20 =
  "https://15fpr7apjl.execute-api.us-east-1.amazonaws.com/dev/businusProd/";
export const apiBaseUrl21 =
  "https://8b7s4c7efa.execute-api.us-east-1.amazonaws.com/dev/businusProd/";
export const domainUrl = "http://businus.school/";
export const s3BucketUrl = "https://businus-files-live.s3.amazonaws.com/image/";

//FOR TEST UNCOMMENT BELOW
/*
   export const domain="test.teamdashboard.in";
//  export const enviroment = "TEST"; 
export const apiBaseUrl = "https://287qejmrtb.execute-api.us-east-1.amazonaws.com/dev/";
export const apiBaseUrl1 = "https://ndougjrj9j.execute-api.us-east-1.amazonaws.com/dev/";
//workflow, organisations ,template

export const apiBaseUrl2 = "https://287qejmrtb.execute-api.us-east-1.amazonaws.com/dev/";
//employee, list mail, uploadAttach, count mail, send mail
export const apiBaseUrl3 = "https://ffajatue3i.execute-api.us-east-1.amazonaws.com/dev/";
//resources, checklists
export const apiBaseUrl4 = "https://6ptppk1gfg.execute-api.us-east-1.amazonaws.com/dev/"; 
export const apiBaseUrl5 = "https://5tueagkdg6.execute-api.us-east-1.amazonaws.com/dev/";  
//legal

export const apiBaseUrl6 = "https://io9jvi51rg.execute-api.us-east-1.amazonaws.com/dev/templateengine"; 
//webzy
export const apiIssueMaster = "https://hpm7qog2ue.execute-api.us-east-1.amazonaws.com/dev/";
export const apiLogsMaster = "https://8vokoznlha.execute-api.us-east-1.amazonaws.com/dev/"; 
export const apiTaskMaster = "https://l3dkepgajb.execute-api.us-east-1.amazonaws.com/dev/";
export const apiWebzyUrl = "https://h9hir67oah.execute-api.us-east-1.amazonaws.com/dev/";
export const apiBaseUrl20='https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/';
export const apiBaseUrl21='https://3j0okb7n5h.execute-api.us-east-1.amazonaws.com/dev/businus/';


export const domainUrl='https://knowledge-hub-ui.web.app/';
export const s3BucketUrl="https://businus-files.s3.amazonaws.com/image/"

*/
