import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

export const getCommonColumns = ({ dedicatedLink, hasAttachment,multiAttachment }) => {
  const columns = [
    {
      Header: (props) => (
        <span className="form-textHead cell-head">Shared To</span>
      ),
      accessor: (d) => {
        return { title: d.assignmentTitle, id: d.id };
      },
      filterMethod: (filter, row) => {
        return true;
      },

      id: "teamMembers",
      Cell: (props) => (
        <Link className="link" to={`./${dedicatedLink}/` + props.value.id}>
          View Team
        </Link>
      ),
      minWidth: 180,
    },
    {
      Header: (props) => (
        <span className="form-textHead cell-head">Client</span>
      ),
      accessor: (d) => {
        return { name: d.selectedClient.name, id: d.selectedClient.id };
      },
      filterMethod: (filter, row) => {
        return row.selectedClient?.name
          ?.toLowerCase()
          .includes(filter.value.toLowerCase());
      },
      id: "selectedClient",
      Cell: (props) => (
        <span className="form-text cell">
          {props.value != undefined ? props.value.name : "-"}
        </span>
      ),
      minWidth: 170,
    },
    {
      Header: (props) => (
        <span className="form-textHead cell-head">Organisation</span>
      ),
      accessor: (d) => {
        return {
          name: d.selectedOrganisation ? d.selectedOrganisation.name : "",
        };
      },
      filterMethod: (filter, row) => {
        return row.selectedOrganisation.name
          .toLowerCase()
          .includes(filter.value.toLowerCase());
      },
      id: "selectedOrganisation",
      Cell: (props) => (
        <span className="form-text cell">
          {props.value != undefined ? props.value.name : "-"}
        </span>
      ),
      minWidth: 170,
    },
    {
      Header: (props) => <span className="form-textHead cell-head">By</span>,
      accessor: "by",
      Cell: (props) => <span className="form-text cell">{props.value}</span>,
      minWidth: 170,
    },
    {
      Header: (props) => (
        <span className="form-textHead cell-head">Date of Creation</span>
      ),
      accessor: (d) => {
        return { date: d.date, id: d.id };
      },
      filterMethod: (filter, row) => {
        return row.name.assignmentTitle
          .toLowerCase()
          .includes(filter.value.toLowerCase());
      },

      id: "teamMembers",
      Cell: (props) => new Date(props.value.date).toDateString(),
      minWidth: 180,
    },
    {
      Header: (props) => (
        <span className="form-textHead cell-head">Time of Creation</span>
      ),
      accessor: (d) => {
        return { date: d.date, id: d.id };
      },
      filterMethod: (filter, row) => {
        return row.name.assignmentTitle
          .toLowerCase()
          .includes(filter.value.toLowerCase());
      },

      id: "teamMembers",
      Cell: (props) => new Date(props.value.date).toLocaleTimeString(),
      minWidth: 280,
    },
  ];

  if (hasAttachment) {
    columns.unshift({
      Header: (props) => (
        <span className="form-textHead cell-head">Attachment</span>
      ),
      accessor: "attachment",
      Cell: (props) =>
        props.value !== "NA" ? <a href={props.value}>Download</a> : props.value,
      minWidth: 240,
    });
  }

  if(multiAttachment){
    return [{
      Header: (props) => (
        <span className="form-textHead cell-head">Attachment</span>
      ),
      accessor: "attachments",
      Cell: (props) =>
       props.value ? props.value.map((t,i)=>{
        return <a href={t.attachment}>Download [{i+1}], </a>  ;
       }) : 'NA',
      minWidth: 240,
      
    }]
  }

  return columns;
};
