import React, { Component } from "react";
import reactCSS from "reactcss";
import "../../style/agency.css";
import axios from "axios";

import { apiBaseUrl, baseFilePath } from "../apiUtils";
import { SketchPicker } from "react-color";
import "../../animations.css";
import FileBase64 from "react-file-base64";
let context = null;

/**
 * Button modal component
 * this is the code of modal which takes in the data and style of button elements.
 * @module Button-Modal
 */
class ButtonModal extends Component {
  constructor(props) {
    super();
    this.state = {
      btnText: "",
      btnLink: "",
      btnColor: "",
      btnBackgroundColor: "",
      btnHighlightColor: "",
      displayColorPicker: false,
      displayBgColorPicker: false,
    };

    context = this;
    this.saveButtonInstance = this.saveButtonInstance.bind(this);
    this.uploadApi = this.uploadApi.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  uploadApi() {
    var data = {
      image: this.state.files.base64,
      fileName: this.state.files.name,
    };
    axios
      .post(apiBaseUrl + "/uploadImage", data)
      .then(function (response) {
        context.setState({ bannerImageUrl: response.data.Location });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  componentDidMount() {
    this.setState({ btnBackgroundColor: this.props.btnBackgroundColor });
    this.setState({ btnColor: this.props.btnColor });

    this.setState({ btnLink: this.props.btnLink });
    this.setState({ btnText: this.props.btnText });
    this.setState({ btnHighlightColor: this.props.btnHighlightColor });
  }

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);

    console.log(this.state);
  };

  saveButtonInstance() {
    this.props.saveBtnInfo(this.state);
    this.props.toggleButtonModal();
  }

  hideModal(key) {
    this.props.hideModal(key);
  }

  toggleColorPicker = (colorPicker) => {
    if (colorPicker == "bg") {
      this.setState({ displayBgColorPicker: !this.state.displayBgColorPicker });
    }
    if (colorPicker == "highlight") {
      this.setState({
        displayhighlightColorPicker: !this.state.displayhighlightColorPicker,
      });
    } else if (colorPicker == "textcolor") {
      this.setState({ displayColorPicker: !this.state.displayColorPicker });
    }
  };

  handleChangeComplete = (color) => {
    this.setState({ btnBackgroundColor: color.hex });
  };
  handleChangeColorComplete = (color) => {
    this.setState({ btnColor: color.hex });
  };

  handleChangeHighlightColorComplete = (color) => {
    this.setState({ btnHighlightColor: color.hex });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
    this.setState({ displayBgColorPicker: false });
    this.setState({ displayhighlightColorPicker: false });
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: "100%",
          height: "14px",
          borderRadius: "2px",
          background: `${this.state.btnBackgroundColor}`,
        },
        color2: {
          width: "100%",
          height: "14px",
          borderRadius: "2px",
          background: `${this.state.btnColor}`,
        },
        color3: {
          width: "100%",
          height: "14px",
          borderRadius: "2px",
          background: `${this.state.btnHighlightColor}`,
        },
        swatch: {
          width:"100%",
          padding: "9px",
          background: "#fff",
          borderRadius: "1px",
          border: "1px solid rgb(239 239 239)",
          display: "inline-block",
          cursor: "pointer",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });

    return this.state.hideModal ? (
      <div></div>
    ) : (
      <div className="modal-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <form>
                <div
                  id="contact-form"
                  className="form-container"
                  data-form-container
                  style={{ color: "rgb(46, 125, 50)", background: "white" }}
                >
                   <div className="row">
                    <div className="modal-head">
                       <div
                      className="close-button"
                      onClick={this.props.toggleButtonModal}
                      >
                      <h5 className="close-icon">
                        X
                      </h5>
                    </div>
                    </div>
                  </div>
                 
                  <div className="input-container">
                 
                    <div className="row form-columns">
                      <div className="col-md-12">
                      <div className="div-wrapper">
                  <h5 className="modal-title"> Edit Button Details </h5>
                  </div>
                     
                        <form id="field">
                          <div className="row ">
                            <div className="col-md-6">
                              <h5>Button Background Color:{" "}</h5>
                            </div>{" "}
                            <div className="col-md-6 ">
                              {" "}
                              <div
                                style={styles.swatch}
                                onClick={() => this.toggleColorPicker("bg")}
                              >
                                <div style={styles.color} />
                              </div>
                              {this.state.displayBgColorPicker ? (
                                <div style={styles.popover}>
                                  <div
                                    style={styles.cover}
                                    onClick={this.handleClose}
                                  />
                                  <SketchPicker
                                    color={this.state.btnBackgroundColor}
                                    onChangeComplete={this.handleChangeComplete}
                                  />
                                  <br />
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="row ">
                            <div className="col-md-6">
                             <h5> Button Highlight Color:{" "}</h5>
                            </div>{" "}
                            <div className="col-md-6 ">
                              {" "}
                              <div
                                style={styles.swatch}
                                onClick={() =>
                                  this.toggleColorPicker("highlight")
                                }
                              >
                                <div style={styles.color3} />
                              </div>
                              {this.state.displayhighlightColorPicker ? (
                                <div style={styles.popover}>
                                  <div
                                    style={styles.cover}
                                    onClick={this.handleClose}
                                  />
                                  <SketchPicker
                                    color={this.state.btnHighlightColor}
                                    onChangeComplete={
                                      this.handleChangeHighlightColorComplete
                                    }
                                  />
                                
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="row ">
                            <div className="col-md-6"><h5>Button Text Color:</h5></div>{" "}
                            <div className="col-md-6 ">
                              {" "}
                              <div
                                style={styles.swatch}
                                onClick={() =>
                                  this.toggleColorPicker("textcolor")
                                }
                              >
                                <div style={styles.color2} />
                              </div>
                              {this.state.displayColorPicker ? (
                                <div style={styles.popover}>
                                  <div
                                    style={styles.cover}
                                    onClick={this.handleClose}
                                  />
                                  <SketchPicker
                                    color={this.state.btnColor}
                                    onChangeComplete={
                                      this.handleChangeColorComplete
                                    }
                                  />
                                  <br />
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6"><h5>Button Text:</h5></div>
                            <div className="col-md-6">
                              {" "}
                              <input
                                className="fieldbox"
                                onChange={this.onChange}
                                type="text"
                                value={this.state.btnText}
                                name="btnText"
                              />
                            </div>
                            <br />
                          </div>

                          {/* <button type="button"   className="imagebutton"> Save </button>*/}
                        </form>
                      </div>
                    </div>

                    <div className="row submit-row">
                      <div className="col-md-2"></div>
                      <div className="col-md-4">
                        <button
                          type="button"
                          onClick={() => this.saveButtonInstance()}
                          className="btn btn-block savebutton submit-form valid"
                        >
                          Save
                        </button>
                      </div>
                      <div className="col-md-4">
                        <button
                          type="button"
                          onClick={() => this.props.toggleButtonModal()}
                          className="btn btn-block cancelbutton submit-form valid"
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ButtonModal;
