import React, { Component } from "react";
import { apiBaseUrl1, apiBaseUrl3 } from "../../apiUtils";
import { Link } from "react-router-dom";
import axios from "axios";

import { apiBaseUrl2 } from "../../apiUtils";
import BreadCrumbs from "../../BreadCrumbs";
import $ from "jquery";
import ViewDoc from "./ViewDoc";
import FileBase64 from "react-file-base64";
import Modal from "react-modal";
import { ChatFeed, Message } from "react-chat-ui";
import { getPrivilegeByName } from "../../privilegeUtils";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  entryle,
} from "reactstrap";
import _ from "underscore";
let context = null;
const data = [];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px ",
    position: "Absolute",
    zIndex: "30000",
    backgroundColor: "none",
  },
};

export default class pageMail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thread: [],
      sliderState: 1,
      superSliderState: "additional",
      mail: {
        contactPersons: [],
      },
      DocId: "",
      entry: {
        sharedTo:[]
      },
    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber) {
    this.setState({ sliderState: orderNumber });
  }
  //function for changing the status button on click to open or close
  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);

    console.log(this.state);
  };

  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.setState({ DocId: id });
    let mailType = this.props.match.params.docType;

    mailType = mailType.charAt(0).toUpperCase() + mailType.slice(1);

    this.setState({ docType: mailType });
    this.fetchQueryById(this, id);
  }

  markAsRead(that, id) {
    axios
      .get(apiBaseUrl2 + "businusquery/read/" + id)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  }

  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(apiBaseUrl3 + "businus/getWorkflowDocById/" + id)
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
        console.log(this.state.entry)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fileUploadHandler = (that, docType) => {
    that.setState({ loading: true });
    debugger;
    axios
      .post(apiBaseUrl2 + "businusquery/uploadAttachment", {
        uploadImage: that.state.selectedFile.base64,

        imageName: that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({
            [docType]:
              "https://businus-files-live.s3.amazonaws.com/mailAttachments/" +
              that.state.selectedFile.name,
          });

          //that.successNotification("Uploaded Succesfully");
        } else {
          //   that.errorNotification("Some Error Has Occured");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  delete = (id) => {
    var result = window.confirm("Are you sure you want to delete?");
    if (result == true) {
      axios
        .delete(apiBaseUrl1 + "businusquery/getOrganisationById/" + id)
        .then(function (response) {
          debugger;
          console.log(response.data);
          alert("Organisation has been deleted");
          // that.setState({ entry: response.data });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  fetchThreadById(that, id) {
    axios
      .get(apiBaseUrl1 + "businusquery/listmail/" + id)
      .then(function (response) {
        debugger;
        console.log(response.data);
        var threads =
          response.data == "" ? [] : _.sortBy(response.data, "date").reverse();
        that.setState({ thread: threads });

        var documents = _.where(threads, { docType: "Document" });
        var proposals = _.where(threads, { docType: "Proposal" });
        var mails = _.where(threads, { docType: "Mail" });
        that.setState({ documents });
        that.setState({ proposals });
        that.setState({ mails });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getFiles(files) {
    this.setState({ selectedFile: files[0] });
  }

  getFormattedDate(millis) {
    return new Date(millis).toDateString();
  }

  changeDocType(type) {
    this.setState({ docType: type });
  }

  sendMail() {
    var query = context.state.entry;
    axios
      .post(apiBaseUrl1 + "businusquery/send", {
        name: query.name,
        email: query.email,
        message: context.state.message,
        company: query.company,
        toe: query.toe,
        subject: context.state.subject,
        attachment: context.state.attachment,
        attachmentName: context.state.attachmentName,
        queryId: query.id,
        from: "client",
        recordType: "MAIL",
        docType: context.state.docType,
        senderEmail: context.state.senderEmail,
      })
      .then(function (response) {
        debugger;
        alert("SENT");
        context.fetchThreadById(context, query.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    localStorage.setItem("MAILURL", url);
  }

  //updating the status button

  render() {
    const { sliderState, entry, superSliderState } = this.state;
    const active = " active-button";
    const deactive = "";

    return (
      <div className="Div">
        <div className="row breadcrumb-row">
          <div className="col-md-10">
            <BreadCrumbs />
          </div>
        </div>

        {/* showing all the details of a single item from the entryle at the backend*/}
        <div className="row">
          <div className="dedicated-page" style={{ padding: "0px" }}>
            <div className="row-resource-head">
              <h4>
                {this.state.docType != undefined
                  ? this.state.docType.replace(/([A-Z]+)/g, " $1")
                  : ""}
              </h4>
            </div>

            {this.state.docType == "Attachment" ? (
              <div>
                <div className="form-group">
                  <label className="control-label col-md-3">
                    Attachment Id :
                  </label>
                  <div className="col-md-7">
                    <h4 className="detail-fields">
                      {this.state.entry.workflowDocId}
                    </h4>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-3">
                    Attachment Name :
                  </label>
                  <div className="col-md-7">
                    <h4 className="detail-fields">{this.state.entry.name}</h4>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-3">
                    Attachment Link :
                  </label>
                  <div className="col-md-7">
                    <a download href={this.state.entry.attachment}>
                      <button> Download </button>
                    </a>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-3">
                    Attachment Description :
                  </label>
                  <div className="col-md-7">
                    <div
                      className="message-text"
                      dangerouslySetInnerHTML={{
                        __html: this.state.entry.description,
                      }}
                    ></div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-3">
                    Uploaded By :
                  </label>
                  <div className="col-md-7">
                    <h4 className="detail-fields">{this.state.entry.by}</h4>
                  </div>
                </div>
              </div>
            ) : this.state.docType == "Url" ? (
              <div>
                <div className="form-group">
                  <label className="control-label col-md-3">Url Id :</label>
                  <div className="col-md-7">
                    <h4 className="detail-fields">
                      {this.state.entry.workflowDocId}
                    </h4>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-3">Url Name :</label>
                  <div className="col-md-7">
                    <h4 className="detail-fields">{this.state.entry.name}</h4>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-3">Url Link :</label>
                  <div className="col-md-7">
                    <h4 className="detail-fields">
                      <a target="_blank" href={this.state.entry.url}>
                        Link to Url
                      </a>
                    </h4>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-3">
                    Url Description :
                  </label>
                  <div className="col-md-7">
                  <h4 className="detail-fields">
                    <div
                      className="message-text"
                      dangerouslySetInnerHTML={{
                        __html: this.state.entry.description,
                      }}
                    ></div>
                    </h4>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-3">
                    Uploaded By :
                  </label>
                  <div className="col-md-7">
                    <h4 className="detail-fields">{this.state.entry.by}</h4>
                  </div>
                </div>
              </div>
            ) : this.state.entry.docType == "Document" ? (
              <div>
                <div className="form-group">
                  <label className="control-label col-md-3">Doc Id :</label>
                  <div className="col-md-7">
                    <h4 className="detail-fields">
                      {this.state.entry.workflowDocId}
                    </h4>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-3">Name :</label>
                  <div className="col-md-7">
                    <h4 className="detail-fields">{this.state.entry.name}</h4>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-3">Title :</label>
                  <div className="col-md-7">
                    <h4 className="detail-fields">{this.state.entry.title}</h4>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-3">Sub Title :</label>
                  <div className="col-md-7">
                    <h4 className="detail-fields">
                      {this.state.entry.subTitle}
                    </h4>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-3">Content :</label>
                  <div className="col-md-7">
                    <div
                      className="message-text"
                      dangerouslySetInnerHTML={{
                        __html: this.state.entry.content,
                      }}
                    ></div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-3">Tags :</label>
                  <div className="col-md-7">
                    {this.state.entry.tags.map((item, i) => {
                      return <button className="tags">{item.value}</button>;
                    })}
                  </div>
                </div>
               
              </div>

            ) : (
              ""
            )}
               <div className="form-group">
                  <label className="control-label col-md-3">Shared To :</label>
                  <div className="col-md-7">
                    {this.state.entry.sharedTo.map((item, i) => {
                      return <button className="">{item.label}</button>;
                    })}
                  </div>
                </div>
          </div>
        </div>
      </div>
    );
  }
}
