import $ from "jquery";
import React, { Component } from "react";
import { Route } from 'react-router-dom';
import "../../../style/panel.css";
import addActCategory from "./addActCategory";
import addCategory from './addCategory';
import addEmailLog from "./addEmailLog";
import addExpenseLog from './addExpenseLog';
import addPhoneLog from "./addPhoneLog";
import addTimeLog from "./addTimeLog";
import Dashboard from './Dashboard';
import editEmailLog from "./editEmailLog";
import editExpenseLog from "./editExpenseLog";
import EditExpenseLog1 from "./editExpenseLog1";
import editPhoneLog from "./editPhoneLog";
import editTimeLog from "./editTimeLog";
import pageEmail from "./pageEmail";
import pageExpenseCategory from "./pageExpenseCategory";
import pageExpenses from "./pageExpenses";
import pagePhone from "./pagePhone";
import pageTime from "./pageTime";
import SidebarLog from './SidebarLog';
import viewActCategory from "./viewActCategory";
import viewCategory from "./viewCategory";
import viewEmailLog from "./viewEmailLog";
import viewExpenses from './viewExpenses';
import viewPhoneLog from "./viewPhoneLog";
import viewTimeLog from "./viewTimeLog";


















const data = [];
export default class LogsMainapp extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []

    };

  }
  componentDidMount() {
    this.handleLine();
    //this.setState({title:this.props.match.params.productType});
  }
  handleLine() {


    const tab1 = this.refs.tab1;
    const sidebar1 = this.refs.sidebar1;
    const section = this.refs.section;


    $(function () {
      $("#tab1").click(function () {
        $("#section").toggle();
      });
    });

  }

  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
      <div className="mainDiv" >

        <div className="row">
          <div className="col-md-2">
            <SidebarLog title={this.state.title} />
          </div>
          <div className="col-md-10">


            <div className="section">
            {/* <Route exact path="/issues/home" component={Home} /> */}
            <Route exact path="/logs" component={Dashboard} />

            <Route exact path="/logs/view-expenses/:id" component={pageExpenses} />
            <Route exact path="/logs/view-timeLogs/:id" component={pageTime} />
            <Route exact path="/logs/view-phoneLogs/:id" component={pagePhone} />
            <Route exact path="/logs/view-emailLogs/:id" component={pageEmail} />

            <Route exact path="/logs/edit-expenseLogs/:id" component={EditExpenseLog1} />
            <Route exact path="/logs/edit-timeLogs/:id" component={editTimeLog} />
            <Route exact path="/logs/edit-phoneLogs/:id" component={editPhoneLog} />
            <Route exact path="/logs/edit-emailLogs/:id" component={editEmailLog} />
              
              <Route exact path="/logs/add-expense" component={addExpenseLog} />
              <Route exact path="/logs/view-expenses" component={viewExpenses} />

              <Route exact path="/logs/add-phoneLogs" component={addPhoneLog} />
              <Route exact path="/logs/view-phoneLogs" component={viewPhoneLog} />

              <Route exact path="/logs/add-timeLogs" component={addTimeLog} />
              <Route exact path="/logs/view-timeLogs" component={viewTimeLog} />

              <Route exact path="/logs/add-emailLogs" component={addEmailLog} />
              <Route exact path="/logs/view-emailLogs" component={viewEmailLog} />


  
            <Route exact path="/logs/view-expenseCategory/:id" component={pageExpenseCategory} />
            <Route exact path="/logs/add-expenseCategory" component={addCategory} />
            <Route exact path="/logs/view-expenseCategory" component={viewCategory} />

          
            <Route exact path="/logs/add-activityCategory" component={addActCategory} />
            <Route exact path="/logs/view-activityCategory" component={viewActCategory} />
          
  
         {/*      <Route exact path="/issues/view-issues/:id" component={pageissues} />
              <Route exact path="/issues/edit-issues/:id" component={editissues} />
              <Route exact path="/issues/add-issues" component={addissues} />
              <Route exact path="/issues/view-issues" component={viewissues} />
              <Route exact path = "/issues/view-issues/:docType/:id" component = {PageResourceissues} />
              <Route exact path = "/issues/view-issues/checklist/page/:id" component = {pageChecklistWrapper} />
              <Route exact path="/issues/view-issuesTemplate/:id" component={pageissuesTemplate} />
              <Route exact path="/issues/add-issuesTemplate" component={addissuesTemplate} />
              <Route exact path="/issues/view-issuesTemplate" component={viewissuesTemplate} />
  
              <Route exact path="/issues/view-updateTemplate/:id" component={pageUpdateTemplate} />
              <Route exact path="/issues/view-template/:id" component={pageissuesTemplate} />
              <Route exact path="/issues/edit-template/:id" component={editissuesTemplate} />
              <Route exact path="/issues/edit-updateTemplate/:id" component={editissuesUpdateTemplate} />
              <Route exact path="/issues/add-updateTemplate" component={addissuesUpdateTemplate} />
              <Route exact path="/issues/view-updateTemplate" component={viewissuesUpdateTemplate} />
              <Route exact path="/issues-mail/:id" component={Assingmentdedicatedmailpage} />
  */}


            </div>

          </div>
        </div>

      </div>

    );
  }
}
