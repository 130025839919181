import React, { Component } from "react";

import { Link } from "react-router-dom";
import $ from "jquery";
import _ from "underscore";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import { getPrivilegeByName } from "../../privilegeUtils";
const data = [];
export default class SidebarStatus extends Component {
  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: [],
      privilege: {},
    };
  }
  componentDidMount() {
    var privileges = JSON.parse(localStorage.getItem("privileges"));
    var privilege = _.findWhere(privileges, { module: "Client" });

    this.setState({ privilege: privilege });

    this.handleLine();
    // this.setState({title:this.props.match.params.productType});
  }
  handleLine() {
    $(function () {
      $("#tab1").click(function (e) {
        $(e.currentTarget).parent().toggleClass("sidebar-secondary");
        $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
        $("#section").toggle();
      });
    });
    $(function () {
      $("#c2").click(function (e) {
        $(e.currentTarget).parent().toggleClass("sidebar-secondary");
        $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
        $("#d2").toggle();
        $("#d10").toggle();
      });
    });
    $(function () {
      $("#c3").click(function (e) {
        $(e.currentTarget).parent().toggleClass("sidebar-secondary");
        $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
        $("#d3").toggle();
        $("#d10").toggle();
      });
    });
    $(function () {
      $("#c4").click(function (e) {
        $(e.currentTarget).parent().toggleClass("sidebar-secondary");
        $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
        $("#d4").toggle();
        $("#d10").toggle();
      });
    });
    $(function () {
      $("#c5").click(function (e) {
        $(e.currentTarget).parent().toggleClass("sidebar-secondary");
        $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
        $("#d5").toggle();
        $("#d10").toggle();
      });
    });
    $(function () {
      $("#c6").click(function (e) {
        $(e.currentTarget).parent().toggleClass("sidebar-secondary");
        $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
        $("#d6").toggle();
        $("#d10").toggle();
      });
    });
    $(function () {
      $("#c7").click(function (e) {
        $(e.currentTarget).parent().toggleClass("sidebar-secondary");
        $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
        $("#d7").toggle();
      });
    });
    $(function () {
      $("#c8").click(function (e) {
        $(e.currentTarget).parent().toggleClass("sidebar-secondary");
        $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
        $("#d8").toggle();
      });
    });
  }

  toggleOpen = (id) => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter((i) => i !== id)] });
    }
  };

  render() {
    return (
      <div className="sidebar">
        <div id="sidebar1">
          <div className="sidebar-head">Status Master</div>

          <div className="oneTab">
            <div className="sidebar-elementhead">
              <Link to="/home">Home</Link>
            </div>
            <div className="sidebar-element" id="c1">
              <Link className="sidebar-link" to={"/assignment-master/status"}>
                {" "}
                <i className="fa fa-bar-chart" />
                Dashboard
                <i className="fa fa-angle-right " />{" "}
              </Link>
            </div>
            <div className="sidebar-subelement" id="d1">
              <i className="fa fa-envelope" />
              View
              <i className="fa fa-angle-right " />
            </div>
          </div>
          <div className="oneTab">
            <div className="sidebar-element" id="c3">
              <i className="fa fa-bar-chart" />
              Progress Status
              <i className="fa fa-angle-down " />
            </div>
            <div className="sidebar-subelement" id="d3">
              {getPrivilegeByName("status").canAdd == true ? (
                <div>
                  <Link
                    style={{ marginLeft: "11px" }}
                    className="sidebar-link"
                    to={"/assignment-master/status/add-progress-status"}
                  >
                    <i className="fa fa-plus" />
                    Add Progress Status
                    <i className="fa fa-angle-right " />
                  </Link>
                </div>
              ) : (
                ""
              )}
              <div>
                <Link
                  style={{ marginLeft: "11px" }}
                  className="sidebar-link"
                  to={"/assignment-master/status/view-progress-status"}
                >
                  <i className="fa fa-eye" />
                  View Progress Status
                  <i className="fa fa-angle-right " />
                </Link>
              </div>
            </div>
          </div>
          <div className="oneTab">
            <div className="sidebar-element" id="c4">
              <i className="fa fa-inr" />
              Payment Status
              <i className="fa fa-angle-down " />
            </div>
            <div className="sidebar-subelement" id="d4">
              <div>
                <Link
                  style={{ marginLeft: "11px" }}
                  className="sidebar-link"
                  to={"/assignment-master/status/add-payment-status"}
                >
                  <i className="fa fa-plus" />
                  Add Payment Status
                  <i className="fa fa-angle-right " />
                </Link>
              </div>
              <div>
                <Link
                  style={{ marginLeft: "11px" }}
                  className="sidebar-link"
                  to={"/assignment-master/status/view-payment-status"}
                >
                  <i className="fa fa-eye" />
                  View Payment Status
                  <i className="fa fa-angle-right " />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
