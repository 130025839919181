import React, { Component } from "react";
import reactCSS from "reactcss";
import "../../style/agency.css";

import { SketchPicker } from "react-color";
import "../../animations.css";
import axios from "axios";

import { apiBaseUrl, baseFilePath } from "../apiUtils";

import FileBase64 from "react-file-base64";
let context = null;
class FooterStyleModal extends Component {
  constructor(props) {
    super();
    this.state = {
      backgroundColor: "",
      height: "",
      padding: "10px 10px 10px 10px",
      logo: "",
      margin: "",
      color: "",
      cardColor: "",
      backgroundImage: "",
      displayColorPicker: false,
      displayBgColorPicker: false,
      copyright: "",
      policies: "",
      tou: "",
      dp: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      addressLine4: "",
      addressLine5: "",
      fileName: "",
      files: [],
    };
    this.saveStyle = this.saveStyle.bind(this);
    context = this;
    this.uploadApi = this.uploadApi.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }
  uploadApi() {
    var data = {
      image: this.state.files.base64,
      fileName: this.state.files.name,
    };
    axios
      .post(apiBaseUrl + "/uploadImage", data)
      .then(function (response) {
        context.setState({ policies: response.data.Location });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getFiles(files) {
    this.setState({ files: files });
    this.uploadApi();
  }
  componentDidMount() {
    var style = this.props.styleProp;
    this.setState({ backgroundColor: style.backgroundColor });
    this.setState({ height: style.height });
    this.setState({ padding: style.padding });
    this.setState({ margin: style.margin });
    this.setState({ color: style.color });
    this.setState({ cardColor: style.cardColor });
    this.setState({ backgroundImage: style.backgroundImage });
    this.setState({ policies: style.policies });
    this.setState({ dp: style.dp });
    this.setState({ copyright: style.copyright });
    this.setState({ tou: style.tou });
    this.setState({ addressLine1: style.addressLine1 });
    this.setState({ addressLine2: style.addressLine2 });
    this.setState({ addressLine3: style.addressLine3 });
    this.setState({ addressLine4: style.addressLine4 });
    this.setState({ addressLine5: style.addressLine5 });
  }

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);

    console.log(this.state);
  };

  saveStyle(index) {
    debugger;
    var context = this;
    var backgroundImage = "url('" + this.state.backgroundImage + "')";
    this.setState({ backgroundImage: backgroundImage });
    setTimeout(function () {
      context.props.saveStyle(context.state);
      context.props.toggleStyleModal();
    }, 50);
  }

  toggleColorPicker = (colorPicker) => {
    if (colorPicker == "bg") {
      this.setState({ displayBgColorPicker: !this.state.displayBgColorPicker });
    } else if (colorPicker == "color") {
      this.setState({ displayColorPicker: !this.state.displayColorPicker });
    } else {
    }
  };

  hideModal(key) {
    this.props.hideModal(key);
  }

  handleChangeComplete = (color) => {
    this.setState({ backgroundColor: color.hex });
  };
  handleChangeColorComplete = (color) => {
    this.setState({ color: color.hex });
  };
  handleChangeCardColorComplete = (color) => {
    this.setState({ color: color.hex });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
    this.setState({ displayBgColorPicker: false });
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: "100%",
          height: "14px",
          borderRadius: "2px",
          background: `${this.state.backgroundColor}`,
        },
        color2: {
          width: "100%",
          height: "14px",
          borderRadius: "2px",
          background: `${this.state.color}`,
        },
        swatch: {
          width:"100%",
          padding: "5px",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });

    return this.state.hideModal ? (
      <div></div>
    ) : (
      <div className="modal-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-9">
              <form>
                <div
                  id="contact-form"
                  className="form-container"
                  data-form-container
                  style={{ color: "rgb(46, 125, 50)", background: "white" }}
                >
                <div className="row">
                    <div className="modal-head">
                       <div
                      className="close-button"
                      onClick={this.props.toggleStyleModal}
                      >
                      <h5 className="close-icon">
                        X
                      </h5>
                    </div>
                    </div>
                  </div>
                 
                  <div className="input-container">
                 
                    <div className="row form-columns">
                      <div className="col-md-12">
                      <div className="div-wrapper">
                  <h5 className="modal-title"> Edit Footer Details </h5>
                  </div>
                        <form id="field">
                          <div className="row">
                            {/*  <div className="col-md-6">
      
      
     
        <div className="row">
           <div className="col-md-3">
        <br/> Address Line1: <p className="subscriptt">(Company Name)</p></div> <div className="col-md-9 modalrow"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.addressLine1} name="addressLine1"/><br/>
        </div>
        </div>
        <div className="row">
           <div className="col-md-3">
        <br/> Address Line2: <p className="subscriptt">(Building no,block)</p></div> <div className="col-md-9 modalrow"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.addressLine2} name="addressLine2"/><br/>
        </div>
        </div>
        <div className="row">
           <div className="col-md-3">
        <br/> Address Line3: <p className="subscriptt">(Road, Locality)</p></div> <div className="col-md-9 modalrow"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.addressLine3} name="addressLine3"/><br/>
        </div>
        </div>

        <div className="row">
           <div className="col-md-3">
        <br/> Address Line4: <p className="subscriptt">(City)</p></div> <div className="col-md-9 modalrow"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.addressLine4} name="addressLine4"/><br/>
        </div>
        </div>
        <div className="row">
           <div className="col-md-3">
        <br/> Address Line5: <p className="subscriptt">(Contact No)</p></div> <div className="col-md-9 modalrow"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.addressLine5} name="addressLine5"/><br/>
        </div>
        </div>

     
        </div> */}

                            <div className="col-md-6 form-columns">
                            
                            <div className="row">
                                <div className="col-md-5">
                                 <h5> Height:</h5>{" "}
                                  <p className="subscriptt">(in Px)</p>
                                </div>{" "}
                                <div className="col-md-7 ">
                                  <input
                                    className="fieldbox"
                                    onChange={this.onChange}
                                    type="text"
                                    value={this.state.height}
                                    name="height"
                                  />
                                
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-5">
                                <h5>Copyright:</h5>{" "}
                                  <p className="subscriptt">(Text)</p>
                                </div>{" "}
                                <div className="col-md-7">
                                  <input
                                    className="fieldbox"
                                    onChange={this.onChange}
                                    type="text"
                                    value={this.state.copyright}
                                    name="copyright"
                                  />
                                  <br />
                                </div>
                              </div>

                              <div className="row ">
                                <div className="col-md-5 ">
                                <h5>Color: </h5></div>{" "}
                                <div className="col-md-7  ">
                                  {" "}
                                  <div
                                    style={styles.swatch}
                                    onClick={() =>
                                      this.toggleColorPicker("color")
                                    }
                                  >
                                    <div style={styles.color2} />
                                  </div>
                                  {this.state.displayColorPicker ? (
                                    <div style={styles.popover}>
                                      <div
                                        style={styles.cover}
                                        onClick={this.handleClose}
                                      />
                                      <SketchPicker
                                        color={this.state.color}
                                        onChangeComplete={
                                          this.handleChangeColorComplete
                                        }
                                      />
                                      <br />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                             
                             
                              {/* <div className="row">
           <div className="col-md-3">
        <br/> Privacy Policy: <p className="subscriptt">(Image)</p></div> <div className="col-md-9 modalrow"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.policies} name="policies"/><br/>
        <FileBase64
        multiple={ false }
        onDone={ this.getFiles.bind(this) } />
        </div>
        </div>

        <div className="row">
           <div className="col-md-3">
        <br/> Terms of use: <p className="subscriptt">(Image)</p></div> <div className="col-md-9 modalrow"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.tou} name="tou"/><br/>
        <FileBase64
        multiple={ false }
        onDone={ this.getFiles.bind(this) } />
        </div>
        </div>
      
        <div className="row">
           <div className="col-md-3">
        <br/> Disclaimer Policy : <p className="subscriptt">(Image)</p></div> <div className="col-md-9 modalrow"><input className="fieldbox"  onChange={this.onChange} type="text" value={this.state.dp} name="dp"/><br/>
        <FileBase64
        multiple={ false }
        onDone={ this.getFiles.bind(this) } />
        </div>
        </div>
        
    */}
                            </div>
                            <div className="col-md-6 form-columns">
                           
                              <div className="row">
                                <div className="col-md-5">
                                 <h5>Padding:</h5>{" "}
                                  <p className="subscriptt">(in px)</p>
                                </div>{" "}
                                <div className="col-md-7 ">
                                  <input
                                    className="fieldbox"
                                    onChange={this.onChange}
                                    type="text"
                                    value={this.state.padding}
                                    name="padding"
                                  />
                                 
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-5">
                               <h5>Margin:</h5>{" "}
                                  <p className="subscriptt">(in px)</p>
                                </div>{" "}
                                <div className="col-md-7 ">
                                  <input
                                    className="fieldbox"
                                    onChange={this.onChange}
                                    type="text"
                                    value={this.state.margin}
                                    name="margin"
                                  />
                                 
                                </div>
                              </div>
                         
                              <div className="row ">
                                <div className="col-md-5 ">
                                  <h5>Background Color:{" "}</h5>
                                </div>{" "}
                                <div className="col-md-7 ">
                                  {" "}
                                  <div
                                    style={styles.swatch}
                                    onClick={() => this.toggleColorPicker("bg")}
                                  >
                                    <div style={styles.color} />
                                  </div>
                                  {this.state.displayBgColorPicker ? (
                                    <div style={styles.popover}>
                                      <div
                                        style={styles.cover}
                                        onClick={this.handleClose}
                                      />
                                      <SketchPicker
                                        color={this.state.backgroundColor}
                                        onChangeComplete={
                                          this.handleChangeComplete
                                        }
                                      />
                                      <br />
                                    </div>
                                  ) : null}
                                </div>
                              </div>

                            </div>
                          </div>

                          {/* <button type="button"   className="imagebutton"> Save </button>*/}
                        </form>
                      </div>
                    </div>
                    <div className="row div-wrapper">
                      <div className="col-md-2"></div>
                      <div className="col-md-4">
                        <button
                          type="button"
                          onClick={() => this.saveStyle(this.props.keyindex)}
                          className="btn btn-block savebutton submit-form valid"
                        >
                          Save
                        </button>
                      </div>
                      <div className="col-md-4">
                        <button
                          type="button"
                          onClick={() => context.props.toggleStyleModal()}
                          className="btn btn-block cancelbutton submit-form valid"
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FooterStyleModal;
