
import { Route } from "react-router-dom";


import React, { Component } from "react";

import Sidebar from "./sidebar";


import CalendarHome from "./CalendarHome/CalendarHome";



export default class CalendarMainapp extends Component{
    constructor(props){
        super(props);
        this.state={}
    }
    render(){
        return(
            <div className="mainDiv" >
            <div className="row">
            <div className="col-md-2">
                <Sidebar/>
            </div>
            <div className="col-md-10">
            <div className="section">
          
           
            <Route exact path="/calendar" component={CalendarHome}/>
           
            
            </div> 
            </div> 
            </div>
           
            
            </div>
        )
    }
}
