import React, { Component } from "react";

import "../../style/agency.css";
import reactCSS from "reactcss";
import axios from "axios";

import { apiBaseUrl, baseFilePath } from "../apiUtils";
import { SketchPicker } from "react-color";

import FileBase64 from "react-file-base64";
import "../../animations.css";
let context = null;

/**
 * About modal component
 * this is segment of code through which data goes in about section of the website.
 * @module About-Us-Modal
 */
class AboutUsModal extends Component {
  constructor(props) {
    super();
    this.state = {
      name: "",
      content: "",
      backgroundColor: "",
      aboutImage: "",
      color: "#00000",
      headColor: "#00000",

      files: [],
      fileName: "",
    };
    this.saveProduct = this.saveProduct.bind(this);
    context = this;
    this.uploadApi = this.uploadApi.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.toggleAboutUsModal = this.toggleAboutUsModal.bind(this);
  }

  /**
   * uploadApi component
   * This is segment of code which uploads changes made in about section.
   * @module uploadApi
   */

  uploadApi() {
    var data = {
      image: this.state.files.base64,
      fileName: this.state.files.name,
    };
    axios
      .post(apiBaseUrl + "/uploadImage", data)
      .then(function (response) {
        context.setState({ aboutImage: response.data.Location });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  /**
   * getFiles component
   * This is segment of code which uploads changes made in about section.
   * @module getFiles
   */
  getFiles(files) {
    this.setState({ files: files });
    this.uploadApi();
  }

  toggleAboutUsModal() {
    var status = !this.state.toggleAboutUsModal;
    this.setState({ toggleAboutUsModal: status });
  }

  componentDidMount() {
    var about = this.props;

    this.setState({ aboutImage: about.aboutUsImage });
    this.setState({ content: about.content });
    this.setState({ backgroundColor: about.styleProp.backgroundColor });
    this.setState({ color: about.styleProp.color });
    this.setState({ headColor: about.styleProp.headColor });
  }

  /* handlechange function*/
  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);

    console.log(this.state);
  };

  saveProduct(index) {
    this.props.saveStyle(this.state);
    this.props.toggleStyleModal(index);
  }

  /**
   * hideModal component
   * This is segment of code which hides modal.
   * @module hideModal
   */

  hideModal(key) {
    this.props.hideModal(key);
  }

  /**
   * colorPicker component
   * This is segment of code used to open the color swatch on click.
   * @module toggleColorPicker
   */

  toggleColorPicker = (colorPicker) => {
    if (colorPicker == "headColor") {
      this.setState({
        displayHeadColorPicker: !this.state.displayHeadColorPicker,
      });
    } else {
      this.setState({ displayColorPicker: !this.state.displayColorPicker });
    }
  };

  /**
   * handleChangeComplete component
   * This is segment of code used to change the color of respective segment according to hex.
   * @module handleChangeComplete
   */
  handleChangeComplete = (color) => {
    this.setState({ backgroundColor: color.hex });
  };
  handleChangeColorComplete = (color) => {
    this.setState({ color: color.hex });
  };
  handleChangeHeadColorComplete = (color) => {
    this.setState({ headColor: color.hex });
  };
  handleClose = () => {
    this.setState({ displayColorPicker: false });
    this.setState({ displayHeadColorPicker: false });
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: "100%",
          height: "14px",
          borderRadius: "2px",
          background: `${this.state.backgroundColor}`,
        },
        color2: {
          width: "100%",
          height: "14px",
          borderRadius: "2px",
          background: `${this.state.color}`,
        },
        color3: {
          width: "100%",
          height: "14px",
          borderRadius: "2px",
          background: `${this.state.headColor}`,
        },
        swatch: {
          width:"100%",
          padding: "8px",
          background: "#fff",
          borderRadius: "1px",
          border: "1px solid rgb(239 239 239)",
          display: "inline-block",
          cursor: "pointer",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });

    return this.state.hideModal ? (
      <div></div>
    ) : (
      <div className="modal-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <form>
                <div
                  id="contact-form"
                  className="form-container"
                  data-form-container
                  style={{ color: "rgb(46, 125, 50)", background: "white" }}
                >
                   <div className="row">
                    <div className="modal-head">
                      <div
                        className="close-button"
                        onClick={this.props.toggleStyleModal}
                      >
                        <h5 className="close-icon">X</h5>
                      </div>
                    </div>
                  </div>

                  <div className="input-container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="div-wrapper">
                          <h5 className="modal-title"> Edit About Us </h5>
                        </div>
                        <form id="field">
                          <div className="row">
                            <div className="col-md-4">
                              <h5>Image Url:</h5>
                              </div>{" "}
                            <div className="col-md-8">
                              <input
                                className="fieldbox"
                                onChange={this.onChange}
                                type="text"
                                value={this.state.aboutImage}
                                name="aboutImage"
                              />
                              <br />
                              <br />
                              <FileBase64
                            multiple={false}
                            onDone={this.getFiles.bind(this)}
                          />
                            </div>
                          </div>
                         

                          <div className="row margin-top-16">
                            <div className="col-md-4">
                              <h5>Content:</h5></div>
                            <div className="col-md-8">
                              <textarea
                                style={{ width: "100%", height: "140px" }}
                                className="fieldbox"
                                onChange={this.onChange}
                                type="text"
                                value={this.state.content}
                                name="content"
                              />
                              <br />
                            </div>
                          </div>
                          {/*        <div className="row">
           <div className="col-md-3">
                    <br/> 
                     Color: </div> <div className="col-md-9 modalrow"><div style={ styles.swatch} onClick={ ()=>this.toggleColorPicker('color') }>
          <div style={ styles.color2 } />
        </div>
        { this.state.displayColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker  color={this.state.color} onChangeComplete={ this.handleChangeColorComplete }/><br/>
        </div> : null }
        </div></div> */}
                          <div className="row">
                            <div className="col-md-4">
                             
                              <h5>Content Color:</h5>
                            </div>
                            <div className="col-md-8 ">
                              <div
                                className="color"
                                style={styles.swatch}
                                onClick={() =>
                                  this.toggleColorPicker("headColor")
                                }
                              >
                                <div style={styles.color3} />
                              </div>
                              {this.state.displayHeadColorPicker ? (
                                <div style={styles.popover}>
                                  <div
                                    style={styles.cover}
                                    onClick={this.handleClose}
                                  />
                                  <SketchPicker
                                    color={this.state.headColor}
                                    onChangeComplete={
                                      this.handleChangeHeadColorComplete
                                    }
                                  />
                                  <br />
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {/* <button type="button"   className="imagebutton"> Save </button>*/}
                        </form>
                      </div>
                    </div>

                    <div className="row submit-row">
                      <div className="col-md-2"></div>
                      <div className="col-md-4">
                        <button
                          type="button"
                          onClick={() => this.saveProduct(this.props.keyindex)}
                          className="btn btn-block savebutton submit-form valid"
                        >
                          Save
                        </button>
                      </div>
                      <div className="col-md-4">
                        <button
                          type="button"
                          onClick={() => this.props.toggleStyleModal()}
                          className="btn btn-block cancelbutton submit-form valid"
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUsModal;
