import React, { Component } from "react";
import reactCSS from "reactcss";
import "../../style/agency.css";
import { apiBaseUrl1, apiBaseUrl3, apiBaseUrl5, apiWebzyUrl } from "../apiUtils";
import { SketchPicker } from "react-color";
import "../../animations.css";
import { apiBaseUrl } from "../apiUtils";
import _ from "underscore";
import axios from "axios";
import { apiBaseUrl6 } from "../../../components/apiUtils";
let context = null;

/**
 * PageSectionModal
 * It is the code for adding section of products  in the template .
 * @module PageSectionModal
 */
class PageSectionModal extends Component {
  constructor(props) {
    super();
    this.state = {
      templateList: [{ name: "Fetching Templates..." }], //revise again
      title: "",
      products: "",
      order: "",
      isHomepage: false,
    };
    this.saveProductSection = this.saveProductSection.bind(this);

    context = this;
  }

  componentDidMount() {
    if (this.props.data != undefined) {
      this.setState({ pageId: this.props.data.pageId }); //setState function so (), passing Object {}
      this.setState({ title: this.props.data.title });
      this.setState({ order: this.props.data.order });
      this.setState({ isHomepage: this.props.data.isHomepage });
      this.setState({ pages: this.props.pages });
    }

    this.fetchQueries(this);
  }

  /**
   * onchange()
   * This segment of code allows state transition .
   * @function onchange()
   */

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
    console.log(this.state);
  };

  fetchQueries(that) {
    axios
      .get(apiWebzyUrl + "businuswebsite/list/all/all")
      .then(function (response) {
        console.log(response.data);
        let allData = response.data;
        var templates = _.where(allData, {
          type: "Template",
          category: "Page",
        });
        var selectAName = { name: "Select a template" }; /////////cause its an object
        templates.unshift(selectAName); //adding select a name to
        that.setState({ templateList: templates });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //earlier it was fetchhomepage        Main ID is initial id...rest all ids are APP ID
  setMainId(appId) {
    //the three scenarios : website is blank, no homepage and has a homepage

    axios.get(apiBaseUrl + "/" + appId).then(function (response) {
      const navbarData = response.data;
      debugger;

      //when already homepage has been assigned and the website id assignment to homepage is done

      if (
        navbarData.websiteInfo.pageType != undefined &&
        navbarData.websiteInfo.pageType != "main"
      ) {
        localStorage.setItem("appId", appId);
        context.fetchSections(appId);
      } else {
        localStorage.setItem("mainId", appId);
        //BLANK PAGE
        var pages = navbarData.websiteInfo.pages;

        //navigation

        if (pages) {
          localStorage.pages = JSON.stringify(pages);
        }
        context.setState({ parentId: appId });
        context.setState({ pageType: "main" });
        localStorage.navStyles = JSON.stringify(
          response.data.websiteInfo.navStyles
        );

        localStorage.setItem("publishappId", response.data.publishId);

        if (pages) {
          let homepage = _.findWhere(pages, { isHomepage: "true" });
          let homepageId = homepage ? homepage.pageId : pages[0].pageId;

          //to fetch the homepage whenever appId is called. there's no change in the homepage's original Id.
          localStorage.setItem("appId", homepageId);
          context.fetchSections(homepageId);
        } else {
          localStorage.setItem("appId", appId);
          localStorage.setItem("mainId", appId);

          context.fetchSections(appId);
        }
      }
    });
  }
  onChangeCheckbox = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.checked + "";
    this.setState(state);

    console.log(this.state);
  };

  toggleStyleModal() {
    var status = !this.state.toggleStyleMode;
    this.setState({ toggleStyleMode: status });
  }

  /**
   * saveProductSection()
   * This segment of code opens style modal.
   * @function saveProductSection()
   */
  saveProductSection(toDelete) {
    if (this.props.data == undefined) {
      //creating a new page
      /*  if (this.props.isFirstPage){
      context.props.saveProductSection(context.state,this.props.appId, toDelete);
      context.props.toggleProductSectionModal();
     } */
      /*  else */
      /*  { */
      if (this.state.chosenTemplateId == undefined) {
        this.createNewWebsite(this.state.title);
      } else {
        this.fetchTemplateSections(this.state.chosenTemplateId);
      }

      /*  } */
    } else {
      //mainPageEditing's function of the same name thats why context.props, passing the state, passing the pageId
      context.props.saveProductSection(
        context.state,
        this.state.pageId,
        toDelete
      );
      context.props.toggleProductSectionModal();
    }
  }

  /*handlePageOrder(){
 var pages=this.state.pages;
  
 if(pages.pageOrder)



 }
*/

  /**
   * register()
   * This segment is the code for generating publish id for register.
   * @function register()
   */
  fetchTemplateSections(appId) {
    axios
      .get(apiBaseUrl + "/" + appId)
      .then(function (response) {
        let templateData = response.data;
        //templateData.websiteInfo.parentId = localStorage.getItem("appId");
        let pages = templateData.websiteInfo.pages; //changing the id of template to the webappId
        if (pages) {
          let homepage = _.findWhere(pages, { isHomepage: "true" });
          let homepageId = homepage ? homepage.pageId : pages[0].pageId;

          //to fetch the homepage whenever appId is called. there's no change in the homepage's original Id.

          context.fetchSections(homepageId);
        } else {
        }
        // context.createNewWebsite(context.state.title, templateData);  //Once template data is fetched, only then page will be created.
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fetchSections(appId) {
    axios
      .get(apiBaseUrl + "/" + appId)
      .then(function (response) {
        let templateData = response.data;
        templateData.websiteInfo.parentId = localStorage.getItem("appId"); //changing the id of template to the webappId

        context.createNewWebsite(context.state.title, templateData); //Once template data is fetched, only then page will be created.
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  createNewWebsite(name, templateData) {
    debugger;
    var data = {
      pageType: "innerPage",
      footer: {
        twitter: "http://facebook.com",
        facebook: "http://facebook.com",
        linkedin: "http://facebook.com",
      },
      sections: "not created",
      websiteInfo: {
        pageType: "innerPage",
        parentId: localStorage.getItem("appId"),
        footer: { color: "#fff", backgroundColor: "red", height: "auto" },
        btnInfo: {
          btnBackgroundColor: "#ac2a18",
          btnColor: "#fff",
          btnLink: "services",
          btnText: "Tell Me More",
        },
        name: name,
        subTitle: "Website Tag Line",
        bannerImageUrl:
          "https://wallpapertag.com/wallpaper/full/a/e/1/642877-cool-wallpaper-laptop-2560x1600-for-hd-1080p.jpg",
        websiteTitle: name,
        navStyles: JSON.parse(localStorage.navStyles),
      },
      websiteName: name,
    };
    axios
      .post(apiBaseUrl6, templateData == undefined ? data : templateData)
      .then(function (response) {
        if (response.status == 200) {
          context.props.saveProductSection(context.state, response.data.id); //id of the new page created, state elements containing title and orderId and it is saveNewPage of mainPageEditing
          context.props.toggleProductSectionModal();
        }
      })
      .catch(function (error) {
        alert("Some Error has occured");
      });
  }

  render() {
    return (
      <div className="modal-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <form>
                <div
                  id="contact-form"
                  className="form-container"
                  data-form-container
                  style={{ color: "rgb(46, 125, 50)", background: "white" }}
                >
                 <div className="row">
                    <div className="modal-head">
                       <div
                      className="close-button"
                      onClick={() => this.props.toggleProductSectionModal()}
                      >
                      <h5 className="close-icon">
                        X
                      </h5>
                    </div>
                    </div>
                  </div>
                 
                  <div className="input-container">
                  <div className="row form-columns">
                      <div className="col-md-12">
                      <div className="div-wrapper">
                  <h5 className="modal-title"> Add Page</h5>
                  </div>
                        <form id="field">
                          <div className="row">
                            <div className="col-md-4"><h5>Page Name :</h5></div>
                            <div className="col-md-8 ">
                              {" "}
                              <input
                                className="fieldbox"
                                onChange={this.onChange}
                                type="text"
                                value={this.state.title}
                                name="title"
                              />
                            
                            </div>
                          </div>
                        
                            <div className="row">
                              <div className="col-md-4">
                              <h5> Choose a template :</h5>
                              </div>
                              <div className="col-md-8 ">
                                {" "}
                                <select
                                  placeholder="Choose a Template"
                                  autofocus
                                  onChange={this.onChange}
                                  name="chosenTemplateId"
                                  //onClick={()=>this.handlePageOrder()}
                                  value={this.state.chosenTemplateId}
                                  className="form-control "
                                >
                                  {this.state.templateList.map((item, i) => {
                                    return (
                                      <option value={item.appId}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              
                              </div>
                            </div>

                           <div className="row">
                            <div className="col-md-4">
                            <h5>Make Homepage :</h5>
                            </div>
                            <div className="col-md-8 ">
                              <input
                                className="fieldbox"
                                onChange={this.onChangeCheckbox}
                                type="checkbox"
                                checked={this.state.isHomepage == "true"}
                                value="true"
                                name="isHomepage"
                              />
                            </div>
                            </div>
                     

                          <div className="row">
                            <div className="col-md-4"><h5>Page Order :</h5></div>
                            <div className="col-md-8 ">
                            
                              <select
                                placeholder="Order"
                                autofocus
                                onChange={this.onOrderChange}
                                name="order"
                                //onClick={()=>this.handlePageOrder()}
                                value={this.state.order}
                                className="form-control "
                              >
                                {this.props.pages.map((item, i) => {
                                  return <option value={i + 1}>{i + 1}</option>;
                                })}

                                {this.props.data == undefined ? (
                                  <option>{this.props.pages.length + 1}</option>
                                ) : (
                                  ""
                                )}
                              </select>
                             
                            </div>
                          </div>

                          {/* <button type="button"   className="imagebutton"> Save </button>*/}
                        </form>
                      </div>
                    </div>

                    {this.state.isHomepage == "true" ? (
                      <p className="delete-disabled-text">
                        * Homepage cannot be deleted.
                        <br />
                        Please set a homepage first then delete.
                      </p>
                    ) : (
                      ""
                    )}
                    <div className="row div-wrapper">
                      <div className="col-md-1"></div>
                      <div className="col-md-4">
                        <button
                          type="button"
                          onClick={() => this.saveProductSection()}
                          className="btn btn-block savebutton submit-form valid"
                        >
                          Save
                        </button>
                      </div>
                      <div className="col-md-3">
                        <button
                          type="button"
                          onClick={() =>
                            context.props.toggleProductSectionModal()
                          }
                          className="btn btn-block cancelbutton submit-form valid"
                        >
                          Cancel
                        </button>
                      </div>
                      {this.props.data != undefined ? (
                        <div className="col-md-3">
                          <button
                            type="button"
                            disabled={
                              this.state.isHomepage == "true" ? true : false
                            } //this is to disable the button when the modal is called on a homepage
                            onClick={() => this.saveProductSection("Delete")}
                            className="btn btn-block deletebutton submit-form valid"
                          >
                            Delete
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="col-md-2"></div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageSectionModal;
