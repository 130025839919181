import Axios from "axios";
import React, { Component } from "react";
import { apiTaskMaster } from "../../apiUtils";
import AddTask from "./addTask";

export default class EditTask extends Component {
   constructor(props){
     super(props);
     this.state={
     entry:{},
     id:"",
     };

   }

   componentDidMount(){
    const id = this.props.match.params.id;
    this.setState({ id });
     this.fetchQueryById(this, id)
   }

   fetchQueryById(that, id) {
    Axios
      .get(apiTaskMaster + "businusquery/getTaskById/" + id)
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({entry: response.data})

      //   let tempTypeIndex = 0;
      //   let selectedTask = response.data.selectedTask;
      //   let taskCategories = context.state.taskList;
     
      //   for (let index = 0; index < taskCategories.length; index++) {
      //     const element = taskCategories[index];
      //     if (element.id == selectedTask.id) {
      //       tempTypeIndex = index;
      //       break;
      //     }
      //   }

      //   context.setState({ selectedCategoryIndex: tempTypeIndex });
        

      //   let tempGroupIndex = 0;
      //   let selectedGroup = response.data.selectedGroup; //selected group of the issue
      //   let groups = context.state.groups; // list of all groups
      //   for (let index = 0; index < groups.length; index++) {
      //     if (groups[index].id == selectedGroup.id) {
      //       tempGroupIndex = index;
      //       break;
      //     }
      //   }

      //   context.setState({ selectedGroupIndex: tempGroupIndex });
      //   console.log(context.state.selectedGroupIndex);
      //   ///  indexOfSelectedType = 'd5aaef60'

      //   let tempClientIndex = 0;
      //   let selectedClient = response.data.selectedClient; //selected group of the issue
      //   let clients = context.state.clients; // list of all groups
      //   for (let index = 0; index < clients.length; index++) {
      //     if (clients[index].id == selectedClient.id) {
      //       tempClientIndex = selectedClient.id;
      //       break;
      //     }
      //   }
      //   context.setState({ selectedClientId: tempClientIndex });

      //   that.setState({ entry: response.data });
      //   that.setState({sharedTo:response.data.sharedTo});
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render(){
  return(
   <>
    <AddTask entry={this.state.entry} mode="Edit" />
  
   </>

    )
  }
}