import React, { Component } from "react";
import About from "../About/About.js";
import $ from "jquery";
import { Link, withRouter } from "react-router-dom";
import Team from "../Team/Team.js";
import Timeline from "../Timeline/Testimonials.js";
import Footer from "../Footer/Footer.js";
import Products from "../Products/Products.js";
import Service from "../Services/Services.js";
import Contact from "../Contact/Contact.js";
import Query from "../Query/Query.js";
import ReactDragListView from "react-drag-listview";
import "../../style/agency.css";
import BannerImageModal from "../Banner/BannerImageModal";
import ProductSectionModal from "../Products/ProductSectionModal";
import PageSectionModal from "./PageSectionModal";
import ServiceSectionModal from "../Services/ServiceSectionModal";
import AboutSectionModal from "../About/AboutSectionModal";
import ContactSectionModal from "../Contact/ContactSectionModal";
import QuerySectionModal from "../Query/QuerySectionModal";
import TeamSectionModal from "../Team/TeamSectionModal";
import TimelineSectionModal from "../Timeline/TimelineSectionModal";
import "../../animations.css";
import axios from "axios";
import NavbarModal from "../Navbar/NavbarModal";
import SecondNavbarModal from "../Navbar/SecondNavbarModal";
import NavbarLinkModal from "../Navbar/NavbarLinkModal";
import { apiBaseUrl } from "../apiUtils";
import _ from "underscore";
import { Redirect } from "react-router";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import AddSectionModal from "../Common/AddSectionModal.js";

let context = null;

/**
 * Main Page
 * It is the combined template, comprising of the various sections.
 * @module MainPage
 */

class MainPage extends Component {
  constructor(props) {
    super();
    this.state = {
      pages: [],
      isLoggedIn: true,
      bannerImageUrl: "",
      bannerHeight: "",
      loader:
        "https://cdn.dribbble.com/users/2919618/screenshots/6995185/loader.gif",
      showLoader: false,
      navStyles: {
        backgroundColor: "white",
        height: "50px",
        padding: "",
        logo: "",
        highlightColor: "",
        margin: "",

        color: "#fff",
        backgroundImage: "",
        secNavBgColor: "",
        secNavTextColor: "",
        secNavTextHighlightColor: "",
        secNavheight: "100px",
      },
      websiteTitle: "",
      footerstyles: {
        backgroundColor: "",
        height: "120px",
        padding: "10px 10px 10px 10px",
        color: "",
        copyright: "",
        policies: "",
        tou: "",
        dp: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        addressLine4: "",
        addressLine5: "",
      },
      subTitle: "",
      subtitleColor: "",
      titleColor: "",
      showBannerImageModal: false,
     
      btnBackgroundColor: "#1266FF",
      btnColor: "#fff",
      btnLink: "products",
      btnHighlightColor: "yellow",
      btnText: "Tell Me More",
      showNavbarModal: false,
      showNavbarLinkModal: false,
      showSecondNavbarModal: false,
     
      showFooterModal: false,
      leftSideBarHidden: false,
      rightSideBarHidden: false,
      isHover: true,
      isFetchingData: true,
      isBtnHover: false,
      profile: "",
      profile: "",
      isFirstPage: true,
      navLinkStyles: "",
      secondNavLinkStyles: "",
      userName: "",
      isDropdownOpen: false,
      sections: [],
      showAddSectionModal:false,
    };
    context = this;
    this.addSection = this.addSection.bind(this);
    this.toggleBannerImageModal = this.toggleBannerImageModal.bind(this);
    this.saveBannerImage = this.saveBannerImage.bind(this);
    this.togglePageModal = this.togglePageModal.bind(this);
    this.toggleHover = this.toggleHover.bind(this);
    this.saveWebsiteInfo = this.saveWebsiteInfo.bind(this);
    this.removeLastSection = this.removeLastSection.bind(this);
    this.removeSection = this.removeSection.bind(this);
    this.fetchSections = this.fetchSections.bind(this);
    this.toggleNavbarModal = this.toggleNavbarModal.bind(this);
    this.toggleSecondNavbarModal = this.toggleSecondNavbarModal.bind(this);
    this.toggleNavbarLinkModal = this.toggleNavbarLinkModal.bind(this);
    this.saveNavStyle = this.saveNavStyle.bind(this);
    this.functionPreview = this.functionPreview.bind(this);
    this.saveWebsiteToServer = this.saveWebsiteToServer.bind(this);
    this.saveSectionProperties = this.saveSectionProperties.bind(this);
    this.logout = this.logout.bind(this);
    this.saveBtnInfo = this.saveBtnInfo.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.toggleSideBar = this.toggleSideBar.bind(this);
    this.saveFooterProperties = this.saveFooterProperties.bind(this);
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
  componentDidMount() {
    //this.setState({profile:localStorage.getItem('profile')});

    //$('.oneditShow').fadeOut();
    $(".editclass").fadeIn();
    $(window).on("beforeunload", function () {
      alert("Bye.");
    });

    const appId = this.props.match.params.appId;

    this.afterLogin(appId);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.match.params.appId != this.props.match.params.appId) {
      if (this.state.pageType != "main") {
        context.saveWebsiteToServer("save", "noPopup");
      }
      //this.setState({profile:localStorage.getItem('profile')});

      //$('.oneditShow').fadeOut();
      $(".editclass").fadeIn();

      const appId = newProps.match.params.appId;

      this.afterLogin(appId);
    }
  }

  componentDidCatch(error, errorInfo) {
    confirmAlert({
      title: "Following Error has occured",
      message: error,

      onClickOutside: () => {},
    });
  }

  componentWillUnmount() {
    confirmAlert({
      title: "Are you sure you want to exit?",
      message: "Click on save",
      buttons: [
        {
          label: "Save and exit",
          onClick: () => context.saveWebsiteToServer("save"),
        },
        {
          label: "Discard",
          onClick: () => {},
        },
      ],
      onClickOutside: () => {},
    });
  }

  showAlert(title, message) {
    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: "Ok",
          onClick: () => console.log("ok"),
        },
      ],
    });
  }

  loader() {
    let showLoader = this.state.showLoader;
    if (showLoader) {
      context.setState({ showLoader: false }); //changes the state for showing the loader
    } else {
      context.setState({ showLoader: true });
    }
  }
  /**
   * afterLogin()
   * This is the segment of code used to fetch sections after Login.
   * @function afterLogin()
   */

  afterLogin(appId) {
    //this.fetchSections(appId);
    this.setWebId(appId);
  }

  /**
   * fetchsSections()
   * This is the segment of code used to fetch sections.
   * @function fetchSections()
   */

  setWebId(appId) {
    //the three scenarios : website is blank, no homepage and has a homepage

    axios.get(apiBaseUrl + "/" + appId).then(function (response) {
      const navbarData = response.data;

      //when already homepage has been assigned and the website id assignment to homepage is done

      if (
        navbarData.websiteInfo.pageType != undefined &&
        navbarData.websiteInfo.pageType != "main"
      ) {
        localStorage.setItem("appId", appId);
        context.fetchSections(appId);
      } else {
        localStorage.setItem("mainId", appId);
        //BLANK PAGE
        var pages = navbarData.websiteInfo.pages;

        //navigation

        if (pages) {
          pages.forEach((item) => {
            item.isHover = false;
          });
          localStorage.pages = JSON.stringify(pages);
        }
        context.setState({ parentId: appId });
        context.setState({ pageType: "main" });
        localStorage.navStyles = JSON.stringify(
          response.data.websiteInfo.navStyles
        );
        localStorage.websiteTitle = response.data.name;

        localStorage.setItem("publishappId", response.data.publishId);

        if (pages && pages.length) {
          let homepage = _.findWhere(pages, { isHomepage: "true" });

          let homepageId = homepage ? homepage.pageId : pages[0].pageId;

          //to fetch the homepage whenever appId is called. there's no change in the homepage's original Id.
          localStorage.setItem("appId", homepageId);
          context.fetchSections(homepageId);
        } else {
          localStorage.setItem("appId", appId);
          localStorage.setItem("mainId", appId);

          context.fetchSections(appId);
        }
      }
    });
  }

  fetchSections(appId) {
    axios
      .get(apiBaseUrl + "/" + appId)
      .then(function (response) {
        console.log(response.data);

        // localStorage.setItem("userName",response.data.name);
        // localStorage.setItem("profile",response.data.image);

        if (response.data.sections == "not created") {
          context.setState({ sections: [] });
        } else {
          let sc = response.data.sections;
          sc.forEach((item) => {
            item.isHover = false;
          });

          context.setState({ sections: sc });
        }

        context.setState({ navStyles: response.data.websiteInfo.navStyles });
        context.setState({
          navLinkStyles: response.data.websiteInfo.navLinkStyles || {},
        });
        context.setState({
          bannerImageUrl: response.data.websiteInfo.bannerImageUrl,
        });
        context.setState({
          bannerHeight: response.data.websiteInfo.bannerHeight || "500px",
        });
        context.setState({
          bannerMargin: response.data.websiteInfo.bannerMargin || "0px",
        });
        context.setState({
          bannerPadding: response.data.websiteInfo.bannerPadding || "0px",
        });
        context.setState({ titleColor: response.data.websiteInfo.titleColor });

        var title = response.data.websiteInfo.websiteTitle;
        var subTitle = response.data.websiteInfo.subTitle;
        context.setState({ websiteTitle: title == "EMPTY" ? "" : title });
        context.setState({ subTitle: subTitle == "EMPTY" ? "" : subTitle });
        context.setState({
          subtitleColor: response.data.websiteInfo.subtitleColor,
        });
        context.setState({ name: response.data.websiteInfo.name });

        var btnInfo = response.data.websiteInfo.btnInfo;
        context.setState({ btnBackgroundColor: btnInfo.btnBackgroundColor });
        context.setState({ btnHighlightColor: btnInfo.btnHighlightColor });
        context.setState({ btnColor: btnInfo.btnColor });
        context.setState({ btnLink: btnInfo.btnLink });
        context.setState({ btnText: btnInfo.btnText });

        var footerstyles = response.data.websiteInfo.footer;
        if (footerstyles != undefined) {
          context.setState({ footerstyles: footerstyles });
        }

        var pages = response.data.websiteInfo.pages;

        var pageType = response.data.websiteInfo.pageType;
        var parentId = response.data.websiteInfo.parentId;

        if (pageType == "innerPage") {
          context.setState({ isFirstPage: false });
          context.setState({ parentId });
          context.setState({ pageType });
          context.setState({ navStyles: JSON.parse(localStorage.navStyles) });

          if (localStorage.pages) {
            context.setState({ pages: JSON.parse(localStorage.pages) });
          }
        } else {
          if (pages) {
            localStorage.pages = JSON.stringify(pages);
            context.setState({ pages });
            if (pages.length > 0) {
              context.setState({ isFirstPage: false });
            } else {
              context.setState({ isFirstPage: true });
            }
          }

          context.setState({ parentId: appId });
          context.setState({ pageType: "main" });
          localStorage.navStyles = JSON.stringify(
            response.data.websiteInfo.navStyles
          );

          localStorage.setItem("publishappId", response.data.publishId);
        }

        context.setState({ isFetchingData: false });
        context.setState({ showLoader: false });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  /**
   * saveWebsiteToServer()
   * This is the segment of code used to save website to server.
   * @function saveWebsiteToServer()
   */

  saveWebsiteToServer(mode, popUpShow, homepage) {
    var appId = localStorage.getItem("appId");

    var publishappId = localStorage.getItem("publishappId");

    var navStyle = this.state.navStyles;
    if (this.state.navStyles.logo == "") {
      navStyle.logo = "no image";
    }
    var data = {
      websiteInfo: {
        parentId: context.state.parentId,
        pageType: context.state.pageType,
        parentId: context.state.parentId,
        navStyles: navStyle,
        btnInfo: {
          btnBackgroundColor: this.state.btnBackgroundColor,
          btnColor: this.state.btnColor,
          btnLink: this.state.btnLink,
          btnText: this.state.btnText,

          btnHighlightColor: this.state.btnHighlightColor,
        },

        name: this.state.name,
        bannerImageUrl: this.state.bannerImageUrl,
        bannerHeight: this.state.bannerHeight,
        bannerMargin: this.state.bannerMargin,
        bannerPadding: this.state.bannerPadding,
        websiteTitle:
          this.state.websiteTitle == "" ? "EMPTY" : this.state.websiteTitle,
        titleColor: this.state.titleColor,
        subtitleColor: this.state.subtitleColor,
        subTitle: this.state.subTitle == "" ? "EMPTY" : this.state.subTitle,
        footer: this.state.footerstyles,
      },
      sections: this.state.sections,
    };

    var apiUrl = mode == "save" ? appId : "publish/" + publishappId;
    axios
      .put(apiBaseUrl + "/" + apiUrl, data)
      .then(function (response) {
        if (mode == "save") {
          if (popUpShow != "noPopup") {
            //doNotShow!=true )? context.showAlert('Success',"Website has been Saved") :'';
            context.showAlert("Success", "Website has been Saved");
          }
          context.fetchSections(context);
        } else {
          context.saveWebsiteToServer("save", true);
          context.showAlert("Success", "Website has been Published");
        }

        console.log("success");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  savePagesToServer = (mode, popUpShow, homepage) => {
    var appId = localStorage.getItem("mainId");

    var publishappId = localStorage.getItem("publishappId");
    var navStyle = this.state.navStyles;

    var data = {
      websiteInfo: {
        pageType: "main",
        pages: this.state.pages,
        navStyles: navStyle,
        btnInfo: {
          btnBackgroundColor: this.state.btnBackgroundColor,
          btnColor: this.state.btnColor,
          btnLink: this.state.btnLink,
          btnText: this.state.btnText,

          btnHighlightColor: this.state.btnHighlightColor,
        },
      },
      sections: this.state.sections,
    };

    var apiUrl = mode == "save" ? appId : "publish/" + publishappId;
    axios
      .put(apiBaseUrl + "/" + apiUrl, data)
      .then(function (response) {
        console.log("success");
        if (homepage == "homepageChanges") {
          //when homepage is changed, the website is refreshed to show the new homepage
          //on screen
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  /**
   * createWebsite()
   * This is the segment of code used to create website to server.
   * @function createWebsite()
   */

  createWebsite() {
    var data = {
      password: "admin123",
      userInfo: { phone: "8764738262", address: "abs noida", },
      footer: {
        twitter: "http://facebook.com",
        facebook: "http://facebook.com",
        linkedin: "http://facebook.com",
      },
      sections: [
        {
          innerProducts: [
            {
              showImageBox: false,
              showHeadBox: false,
              showContentBox: false,
              name: "Services 1",
              icon: "https://image.flaticon.com/icons/png/512/33/33750.png",
              styles: {
                color: "#000",
                backgroundColor: "#4a90e2",
                descriptionColor: "#000",
              },
              toggleEditMode: false,
              content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
            {
              showImageBox: false,
              showHeadBox: false,
              showContentBox: false,
              name: "seviec 2",
              icon: "https://image.flaticon.com/icons/png/512/33/33750.png",
              styles: {
                color: "#fa5d5d",
                backgroundColor: "#bd10e0",
                descriptionColor: "#000",
              },
              toggleEditMode: false,
              content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
            {
              showImageBox: false,
              showHeadBox: false,
              showContentBox: false,
              name: "Product1",
              icon: "https://image.flaticon.com/icons/png/512/33/33750.png",
              styles: {
                color: "#000",
                backgroundColor: "#fff",
                descriptionColor: "#000",
              },
              toggleEditMode: false,
              content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
          ],
          styles: {
            displayColorPicker: false,
            displayBgColorPicker: false,
            padding: "60px 10px 60px 10px",
            backgroundColor: "#fff",
            margin: "0px 0px 0px 0px",
            isBackgroundImage: true,
            color: "black",
            backgroundImage: "url('no image')",
            title: "pouutht",
          },
          title: "pouutht",
          type: "products",
          productCount: "3",
        },
        {
          styles: {
            displayColorPicker: false,
            displayBgColorPicker: false,
            padding: "60px 10px 60px 10px",
            backgroundColor: "#fff",
            margin: "0px 0px 0px 0px",
            isBackgroundImage: true,
            color: "#ee2d2d",
            backgroundImage:
              "url('url('https://blog.befunky.com/wp-content/uploads/2016/03/blur-background-featured-1.jpg')')",
            title: "New Products1",
          },
          innerProducts: [
            {
              showImageBox: false,
              showHeadBox: false,
              showContentBox: false,
              name: "Product 1",
              icon: "https://image.flaticon.com/icons/png/512/33/33750.png",
              styles: {
                color: "#ffffff",
                backgroundColor: "#b92626",
                descriptionColor: "#0b0202",
              },
              toggleEditMode: false,
              content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
            {
              showImageBox: false,
              showHeadBox: false,
              showContentBox: false,
              name: "megha",
              icon: "https://image.flaticon.com/icons/png/512/33/33750.png",
              styles: {
                color: "#ff2c2c",
                backgroundColor: "#f5a623",
                descriptionColor: "#000",
              },
              toggleEditMode: false,
              content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
            {
              showImageBox: false,
              showHeadBox: false,
              showContentBox: false,
              name: "Product1",
              icon: "https://image.flaticon.com/icons/png/512/33/33750.png",
              styles: {
                color: "#000",
                backgroundColor: "#aeaeae",
                descriptionColor: "#000",
              },
              toggleEditMode: false,
              content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
          ],
          title: "New Products1",
          type: "products",
          productCount: "3",
        },
        {
          innerProducts: [
            {
              bgcolor: "#fff",
              linkid: "dd",
              post: "lead",
              color: "#000",
              facebookid: "dd",
              name: "Product1",
              icon: "https://mycs.net.au/wp-content/uploads/2016/03/person-icon-flat.png",
              descriptionColor: "#000",
              toggleEditMode: false,
              twitterid: "dd",
            },
            {
              bgcolor: "#fff",
              linkid: "dd",
              post: "lead",
              color: "#000",
              facebookid: "dd",
              name: "Product1",
              icon: "https://mycs.net.au/wp-content/uploads/2016/03/person-icon-flat.png",
              descriptionColor: "#000",
              twitterid: "dd",
            },
            {
              bgcolor: "#fff",
              linkid: "dd",
              post: "lead",
              color: "#000",
              facebookid: "dd",
              name: "Product1",
              icon: "https://mycs.net.au/wp-content/uploads/2016/03/person-icon-flat.png",
              descriptionColor: "#000",
              twitterid: "dd",
            },
          ],
          styles: {
            displayColorPicker: false,
            displayBgColorPicker: false,
            padding: "60px 10px 60px 10px",
            backgroundColor: "#fff",
            margin: "0px 0px 0px 0px",
            isBackgroundImage: true,
            color: "black",
            backgroundImage:
              "url('https://images.pexels.com/photos/39811/pexels-photo-39811.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500')",
            title: "our team",
          },
          title: "our team",
          type: "team",
          productCount: "3",
        },
        {
          innerProducts: "no products",
          styles: {
            displayColorPicker: false,
            displayBgColorPicker: false,
            padding: "60px 10px 60px 10px",
            backgroundColor: "#fff",
            margin: "0px 0px 0px 0px",
            isBackgroundImage: true,
            color: "black",
            backgroundImage:
              "url('http://www.quoteambition.com/wp-content/uploads/2017/03/let-go-816x428.jpg')",
            title: "Contact us",
          },
          title: "Contact us",
          type: "contact",
          productCount: "0",
        },
        {
          innerProducts: "no products",
          styles: {
            displayColorPicker: false,
            displayBgColorPicker: false,
            padding: "60px 10px 60px 10px",
            backgroundColor: "#ec4444",
            margin: "0px 0px 0px 0px",
            isBackgroundImage: false,
            color: "black",
            backgroundImage: "no image",
            title: "Send us a QQHuery",
          },
          title: "Send us a QQHuery",
          type: "query",
          productCount: "0",
        },
        {
          aboutUsImage:
            "https://clipart.world/wp-content/uploads/2020/08/people-working-together-making-up-puzzle-pieces.jpg",
          innerProducts: [
            {
              showImageBox: false,
              showHeadBox: false,
              showContentBox: false,
              name: "Product1",
              icon: "https://image.flaticon.com/icons/png/512/33/33750.png",
              styles: {
                color: "#e92e2e",
                backgroundColor: "#fff",
                descriptionColor: "#000",
              },
              toggleEditMode: false,
              content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
            {
              showImageBox: false,
              showHeadBox: false,
              showContentBox: false,
              name: "hola1",
              icon: "https://image.flaticon.com/icons/png/512/33/33750.png",
              styles: {
                color: "#000",
                backgroundColor: "#d11b1b",
                descriptionColor: "#000",
              },
              toggleEditMode: false,
              content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
            {
              showImageBox: false,
              showHeadBox: false,
              showContentBox: false,
              name: "Product1",
              icon: "https://image.flaticon.com/icons/png/512/33/33750.png",
              styles: {
                color: "#000",
                backgroundColor: "#fff",
                descriptionColor: "#000",
              },
              toggleEditMode: false,
              content:
                "holaaaaaaaaaa consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
          ],
          styles: {
            padding: "60px 10px 60px 10px",
            backgroundColor: "#000",
            margin: "0px 0px 0px 0px",
            color: "black",
            backgroundImage: "no image",
          },
          title: "rtytyt",
          type: "products",
          productCount: "3",
          content:
            "dllus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in.",
        },
      ],
      email: "gautammegha2@gmail.com",
      id: "99d4a1d0-3d83-11e9-861d-ff0462262d64",
      name: "Megha",
      websiteInfo: {
        name: "businus.com",
        subTitle: "Template Engine",
        bannerImageUrl:
          "https://wallpapertag.com/wallpaper/full/a/e/1/642877-cool-wallpaper-laptop-2560x1600-for-hd-1080p.jpg",
        websiteTitle: "Businus",
      },
      pages: [],
    };

    axios
      .post(apiBaseUrl, data)
      .then(function (response) {
        console.log("success");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  /**
   * functionEditor()
   * This is the segment of code used to show editor onclick
   * @function functionEditor()
   */
  functionEditor() {
    context.setState({ leftSideBarHidden: false });
    context.setState({ rightSideBarHidden: false });

    $(
      function () {
        $(".editclass").fadeIn();
        $(".editclas").fadeIn();
        $(".oneditShow").fadeOut();
        $("#mainNav").css("margin-top", "47px");
      },
      function () {
        // on mouseout, reset the background colour
        $(".editclass").fadeIn();
        $(".editclas").fadeIn();
        $(".oneditShow").fadeOut();
      }
    );
  }

  /**
   * functionEditor()
   * This is the segment of code used to show preview onclick
   * @function functionPreview()
   */
  functionPreview() {
    $(
      function () {
        $(".editclass").fadeOut();
        $(".editclas").fadeOut();
        $(".oneditShow").fadeIn();
        $("#mainNav").css("margin-top", "0");
      },
      function () {
        // on mouseout, reset the background colour
        $(".editclass").fadeIn();
        $(".editclas").fadeIn();
        $(".oneditShow").fadeOut();
      }
    );
  }

  /**
   * toggleSideBar()
   * This is the segment of code used to toggle sidebar
   * @function toggleSideBar()
   */
  toggleSideBar(whichSidebar) {
    if (whichSidebar == "right") {
      var status = this.state.rightSideBarHidden;
      this.setState({ rightSideBarHidden: !status });

      status ? $("#rightSidebar").fadeIn() : $("#rightSidebar").fadeOut();
    } else {
      var status = this.state.leftSideBarHidden;
      this.setState({ leftSideBarHidden: !status });

      status ? $("#leftSidebar").fadeIn() : $("#leftSidebar").fadeOut();
    }
  }

  /**
   * addSection()
   * This is the segment of code used to add section to the template
   * @function addSection()
   */
  addSection(type, newSection) {
    if (newSection.title == "") {
      alert("name cannot be blank");
      return;
    }
    var sections = this.state.sections;

    var sectionName = newSection.title;
    sections.push({
      title: sectionName,
      type: type.toLowerCase(),
      productCount: newSection.products,
      innerProducts: [],
      content:
        "dllus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in.",
      aboutUsImage:
        "https://clipart.world/wp-content/uploads/2020/08/people-working-together-making-up-puzzle-pieces.jpg",
      styles: {
        backgroundColor: "#fffff",
        padding: "60px 10px 60px 10px",
        margin: "0px 0px 0px 0px",
        color: "black",
        backgroundImage: "no image",
      },
    });
    this.setState({ sections: sections });
  }

  saveNavStyle(newNavStyle) {
    this.setState({ navStyles: newNavStyle }); //newNavStyle modal
    localStorage.navStyles = JSON.stringify(newNavStyle);
    setTimeout(function () {
      context.savePagesToServer("save", true);
    }, 100);
  }

  saveNavLinkStyle(newStyle) {
    this.setState({ navLinkStyles: newStyle });
  }

  saveSecondNavStyle(newStyle) {
    this.setState({ secondNavStyles: newStyle });
  }

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);

    console.log(this.state);
  };

  /**
   * removeLastSection()
   * This is the segment of code used to remove last section of the template.
   * @function removeLastSection()
   */
  removeLastSection() {
    var prod = this.state.sections;
    //var productName = prod.length+1;
    prod.pop();
    this.setState({ sections: prod });
  }

  /**
   * removeSection()
   * This is the segment of code used to remove section from the sidepanel.
   * @function removeSection()
   */
  removeSection(removeAt) {
    var allsections = this.state.sections;
    //var productName = prod.length+1;
    allsections.splice(removeAt, 1);
    this.setState({ sections: allsections });
  }

  /**
   * confirmDelete()
   * This is the segment of code to delete a section.
   * @function confirmDelete()
   */
  confirmDelete(index) {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete section.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.removeSection(index),
        },
        {
          label: "No",
          onClick: () => console.log(""),
        },
      ],
    });
  }

  toggleBannerImageModal() {
    var status = !this.state.toggleBannerImageModal;
    this.setState({ toggleBannerImageModal: status });
  }

  toggleAboutUsModal() {
    var status = !this.state.toggleAboutUsModal;
    this.setState({ toggleAboutUsModal: status });
  }
  saveBannerImage(newBannerImageUrl) {
    this.setState({ bannerImageUrl: newBannerImageUrl });
  }

  /**
   * saveWebsiteInfo()
   * This is the segment of code to save some of the banner elements.
   * @function saveWebsiteInfo()
   */
  saveWebsiteInfo(
    websiteTitle,
    subTitle,
    subtitleColor,
    titleColor,
    bannerHeight,
    bannerMargin,
    bannerPadding
  ) {
    this.setState({ websiteTitle: websiteTitle });
    this.setState({ subTitle: subTitle });
    this.setState({ subtitleColor: subtitleColor });
    this.setState({ titleColor: titleColor });
    this.setState({ bannerHeight: bannerHeight });
    this.setState({ bannerMargin });
    this.setState({ bannerPadding });
  }

 
  togglePageModal(data) {
    this.setState({ pageData: data });
    var status = !this.state.showPageModal;
    this.setState({ showPageModal: status });
  }

 

  toggleNavbarModal() {
    var status = !this.state.showNavbarModal;
    this.setState({ showNavbarModal: status });
  }
  toggleSecondNavbarModal() {
    var status = !this.state.showSecondNavbarModal;
    this.setState({ showSecondNavbarModal: status });
  }

  toggleNavbarLinkModal() {
    var status = !this.state.showNavbarLinkModal;
    this.setState({ showNavbarLinkModal: status });
  }

  toggleFooterModal() {
    var status = !this.state.showFooterModal;
    this.setState({ showFooterModal: status });
  }
 
  /*---------------------------------toggleAddSectionModal--------------------------*/
  toggleAddSectionModal=(type)=>{
    let status = !this.state.showAddSectionModal;
    this.setState({ showAddSectionModal: status, type:type });
  }


  toggleHover(index) {
    if (index == "home") {
      this.setState({ isHover: !this.state.isHover });
    } else if (index == "btnKnow") {
      this.setState({ isBtnHover: !this.state.isBtnHover });
    }
    var sections = this.state.sections;

    sections.forEach(function (prod, keyindex) {
      if (index == keyindex) {
        prod.isHover = !prod.isHover;
      }
    });

    this.setState({ sections: sections });
  }

  toggleHoverPage(index) {
    var pages = this.state.pages;

    pages.forEach(function (prod, keyindex) {
      if (index == keyindex) {
        prod.isHover = !prod.isHover;
      }
    });

    this.setState({ pages: pages });
  }

  saveSectionProperties(
    index,
    newTitle,
    newStyle,
    innerProducts,
    newContent,
    headColor,
    aboutImage
  ) {
    var sections = this.state.sections;
    sections.forEach(function (prod, keyindex) {
      if (index == keyindex) {
        prod.title = newTitle;

        prod.styles = newStyle;

        prod.innerProducts = innerProducts;

        if (innerProducts == "contactInfo") {
          prod.contactInfo = newContent;
        }

        if (newContent != undefined) {
          prod.content = newContent;

          prod.aboutUsImage = aboutImage;
        }
      }
    });

    this.setState({ sections: sections });
  }

  /**
   * saveFooterProperties()
   * This is the segment of code to save style and elements of footer.
   * @function saveFooterProperties()
   */
  saveFooterProperties(newStyle) {
    var newFooter = {
      backgroundColor: newStyle.backgroundColor,
      height: newStyle.height != "" ? newStyle.height : "auto",
      color: newStyle.color != "" ? newStyle.color : "#fff",
      copyright: newStyle.copyright,
      policies: newStyle.policies,
      tou: newStyle.tou,
      dp: newStyle.dp,
      addressLine1: newStyle.addressLine1,
      addressLine2: newStyle.addressLine2,
      addressLine3: newStyle.addressLine3,
      addressLine4: newStyle.addressLine4,
      addressLine5: newStyle.addressLine5,
    };
    this.setState({ footerstyles: newFooter });
  }

  /**
   * savebtnInfo()
   *  This function will save the button info. And the info changes will directly refllect in the section of the button.
   * @function savebtnInfo
   */
  saveBtnInfo(btnInfo) {
    this.setState({ btnBackgroundColor: btnInfo.btnBackgroundColor });
    this.setState({ btnColor: btnInfo.btnColor });
    this.setState({ btnLink: btnInfo.btnLink });
    this.setState({ btnText: btnInfo.btnText });
    this.setState({ btnHighlightColor: btnInfo.btnHighlightColor });
  }

  

  /**
   * saveNewPage()
   * This is the segment of code to save new Page
   * @function saveNewPage()
   */
  saveNewPage = (pageDetails, pageId, ToDelete) => {
    if (pageDetails.title == "") {
      alert("name cannot be blank");
      return;
    }
    var pages = this.state.pages;
    var homepageChanges = false;

    if (ToDelete == "Delete") {
      pages = _.reject(pages, { pageId: pageId });
    } else {
      var pageName = pageDetails.title;
      let page = _.findWhere(pages, { pageId: pageId });

      if (pageDetails.isHomepage == "true") {
        let oldHomepage = _.findWhere(pages, { isHomepage: "true" }); //finding if any homepage exists and removes it
        //to set
        if (oldHomepage) {
          oldHomepage.isHomepage = false;
          homepageChanges = true;
        }
      }

      if (page) {
        page.title = pageDetails.title; // updation in the existing page id
        page.order = Number(pageDetails.order);
        page.isHomepage = pageDetails.isHomepage;
      } else {
        pages.push({
          isHomepage: pageDetails.isHomepage, //new page is being pushed in the array of pages
          title: pageName,
          orderId: pages.length,
          order: Number(pageDetails.order), // order being saved.
          pageId: pageId,
        });
      }
    }

    pages = _.sortBy(pages, "order");
    this.setState({ pages: pages });
    localStorage.pages = JSON.stringify(pages);
    if (pages.length > 0) {
      this.setState({ isFirstPage: false });
    } else {
      this.setState({ isFirstPage: true });
    }
    setTimeout(function () {
      context.savePagesToServer("save", true, "homepageChanges");
    }, 100);
  };



  saveSection=(sectionType, newTitle)=>{
    this.addSection(sectionType, newTitle);
  }




 


  /**
   * toggleMenu()
   * This is the segment of code to toggle menu.
   * @function toggleMenu()
   */
  toggleMenu = () => {
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
  };

  /**
   * logout()
   * This is the segment of code which you logs you out of the session.
   * @function logout()
   */
  logout = () => {
    localStorage.clear();
    this.props.history.push("/Login");
  };

  /**
   * logout()
   * This is the segment of code which removes product card from the product section
   * @function removeProduct()
   */
  removeProduct(removeAt) {
    var prod = this.state.Products;
    //var productName = prod.length+1;
    prod.splice(removeAt, 1);
    this.setState({ Products: prod });
  }

  render() {
    const that = this;
    const {type} =this.state;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const { sections } = that.state;
        const item = sections.splice(fromIndex, 1)[0];
        sections.splice(toIndex, 0, item);
        that.setState({ sections });
      },
      nodeSelector: "div",
      handleSelector: "a",
    };

    return this.state.isLoggedIn ? (
      <div id="mainPage">
        {this.state.toggleBannerImageModal ? (
          <BannerImageModal
            sections={this.state.sections}
            websiteTitle={this.state.websiteTitle}
            titleColor={this.state.titleColor}
            subtitleColor={this.state.subtitleColor}
            bannerHeight={this.state.bannerHeight}
            saveBtnInfo={this.saveBtnInfo}
            btnLink={this.state.btnLink}
            btnText={this.state.btnText}
            btnColor={this.state.btnColor}
            btnBackgroundColor={this.state.btnBackgroundColor}
            btnHighlightColor={this.state.btnHighlightColor}
            bannerMargin={this.state.bannerMargin}
            bannerPadding={this.state.bannerPadding}
            subTitle={this.state.subTitle}
            bannerImageUrl={this.state.bannerImageUrl}
            saveBannerImage={this.saveBannerImage}
            saveWebsiteInfo={this.saveWebsiteInfo}
            toggleBannerImageModal={this.toggleBannerImageModal}
          />
        ) : (
          ""
        )}
       
        {/*  */}
        {this.state.showPageModal ? (
          <PageSectionModal
            data={this.state.pageData}
            isFirstPage={this.state.isFirstPage}
            appId={this.props.match.params.appId}
            saveProductSection={this.saveNewPage}
            toggleProductSectionModal={this.togglePageModal}
            pages={this.state.pages}
          />
        ) : (
          ""
        )}
        {/*-------------------------------addSection---------------------------------------*/}
          {this.state.showAddSectionModal ? (
          <AddSectionModal
            saveSection={this.saveSection}
            toggleAddSectionModal={this.toggleAddSectionModal}
            type={type}
          />
        ) : (
          ""
        )}
        
      {this.state.showNavbarModal ? (
          <NavbarModal
            toggleNavbarModal={this.toggleNavbarModal}
            saveStyle={this.saveNavStyle}
            styleProp={this.state.navStyles}
          />
        ) : (
          ""
        )}
        {this.state.showSecondNavbarModal ? (
          <SecondNavbarModal
            toggleStyleModal={this.toggleSecondNavbarModal}
            saveStyle={this.saveNavStyle}
            styleProp={this.state.secondNavStyles}
          />
        ) : (
          ""
        )}
        {this.state.showNavbarLinkModal ? (
          <NavbarLinkModal
            toggleStyleModal={this.toggleNavbarLinkModal}
            saveStyle={this.saveNavLinkStyle}
            styleProp={this.state.navLinkStyles}
          />
        ) : (
          ""
        )}

        {/*PANEL NAV*/}

        <nav
          className="editclass navbar navbar-expand-lg navbar-dark fixed-top"
          id="topNav"
        >
          {/*  <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              Menu
              <i className="fas fa-bars" />
            </button> */}
          <div className="collapse navbar-collapse" id="navbarResponsive">
            {/*  <a className="navbar-brand js-scroll-trigger"  ><Link to="/home"> <h2 className="logo" style={{marginLeft:'30px',fontWeight:'800', marginTop:'10px'}}>Webzy</h2></Link></a>
             */}{" "}
            <ul className="navbar-nav text-uppercase ml-auto">
              {/*   <li className="nav-item panel-item" >
              <div className="nav-link js-scroll-trigger" id="dashboard"  ><Link to="/dashboard"><i className="fa fa-dashboard icons" ></i>Dashboard</Link></div></li>
              */}
              <li className="nav-item panel-item">
                <a
                  className="nav-link js-scroll-trigger"
                  id="editor"
                  onClick={() => this.toggleSideBar("left")}
                >
                  <i className="fa fa-edit icons"></i>
                  {this.state.leftSideBarHidden
                    ? "Show Left Panel"
                    : "Hide Left Panel"}
                </a>
              </li>
              <li className="nav-item panel-item">
                <a
                  className="nav-link js-scroll-trigger"
                  id="editor"
                  onClick={() => this.toggleSideBar("right")}
                >
                  <i className="fa fa-edit icons"></i>
                  {this.state.rightSideBarHidden
                    ? "Show Left Panel"
                    : "Hide Right Panel"}
                </a>
              </li>

              <li className="nav-item panel-item">
                <a
                  className="nav-link js-scroll-trigger"
                  id="preview"
                  onClick={() => this.togglePageModal()}
                >
                  <i className="fa fa-window-restore icons"></i>Add New Page
                </a>
              </li>

              <li className="nav-item panel-item">
                <a
                  className="nav-link js-scroll-trigger"
                  id="preview"
                  onClick={this.functionPreview}
                >
                  <i className="fa fa-eye icons"></i>Preview
                </a>
              </li>
              <li className="nav-item panel-item">
                <a
                  className="nav-link js-scroll-trigger"
                  onClick={() => this.saveWebsiteToServer("save")}
                >
                  <i className="fa fa-save icons"></i>Save
                </a>
              </li>

              <li className="nav-item panel-item">
                {this.state.pageType == "innerPage" ? (
                  <Link
                    className="nav-link js-scroll-trigger"
                    to={"./" + this.state.parentId}
                  >
                    <i className="fa fa-home icons"></i>Main Page
                  </Link>
                ) : (
                  ""
                )}
              </li>
              <li className="nav-item panel-item">
                <a
                  className="nav-link js-scroll-trigger"
                  onClick={() =>
                    this.saveWebsiteToServer(
                      "publish",
                      localStorage.getItem("publishappId")
                    )
                  }
                >
                  <i className="fa fa-thumbs-up icons"></i>PUBLISH
                </a>
              </li>
              {/*    <li className="nav-item panel-side-item">
            <div onClick={this.toggleMenu} className="nav-link js-scroll-trigger profile-dropdown-trigger" >
            <i className="fa fa-circle-logout icons" ></i><img className="profile-pic" src={this.state.profile} /> {this.state.userName}</div>
            
            
            <div style={{display:this.state.isDropdownOpen ? 'block' : 'none'}} className="dropdown-menu profile-dropdown dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
            <Link  className="dropdown-item" to="/account">
            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
           Profile
          </Link>
        
          <div className="dropdown-divider" />
          <a className="dropdown-item" href="#" onClick={this.logout} data-toggle="modal" data-target="#logoutModal">
            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
            Logout
          </a>
        </div>
            </li> */}

              {/*<li className="nav-item panel-side-item">
            <div className="nav-link js-scroll-trigger dropdown"><button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"><i className="fa fa-circle-login icons" ></i>Megha  <img className="img-profile rounded-circle" src={this.state.profile} /> 
            <span class="caret"></span></button>
  <ul class="dropdown-menu">
    <li><a href="#">HTML</a></li>
    <li><a href="#">CSS</a></li>
    <li><a href="#">JavaScript</a></li>
  </ul></div>
  
    </li>*/}
            </ul>
          </div>
        </nav>

        {this.state.showLoader ? (
          <div className="webzy-loader-show">
            <img className="loader" src={this.state.loader} />
          </div>
        ) : (
          <>
            <a onClick={this.functionEditor} className="oneditShow float">
              <i className="fa fa-edit my-float"></i>
            </a>

            {/*WESITE NAV*/}
            <nav
              style={{
                backgroundColor: this.state.navStyles.backgroundColor,
                height: this.state.navStyles.height,
              }}
              className="navbar webzy-nav-top  navbar-expand-lg navbar-dark fixed-top"
              id="mainNav"
            >
              <Link
                to={"./" + this.state.parentId}
                style={{ width: "150px", height: "100%" }}
                className="navbar-brand js-scroll-trigger"
                href="#page-top"
              >
                {this.state.navStyles.logo != "" &&
                this.state.navStyles.logo != "no image" ? (
                  <img
                    style={{
                      width: "100%",
                      padding: "2px 0px 0px 0px",
                      height: "99%",
                    }}
                    className="webzylogo"
                    src={"" + this.state.navStyles.logo}
                  />
                ) : (
                  localStorage.websiteTitle
                )}
              </Link>

              <i
                className="fa editclass fa-edit"
                onClick={this.toggleNavbarModal}
              ></i>
              {/*     <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              Menu
              <i className="fas fa-bars" />
            </button> */}

              <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ml-auto">
                  {/*   <li style={{marginRight:'10px',listStyle:'none'}} className="nav-item">
             
              <Link className="nav-link js-scroll-trigger" 
              onMouseEnter={()=>this.toggleHover('home')} 
              onMouseLeave={()=>this.toggleHover('home')} 
              style={{color:this.state.navStyles.color,background:(this.state.isHover ? this.state.navStyles.highlightColor : '')}} 
               >Home</Link></li> */}

                  {this.state.pages.map((prod, i) => (
                    <li
                      style={{ marginRight: "10px", listStyle: "none" }}
                      className="nav-item"
                    >
                      {" "}
                      <i
                        className="fa editclass fa-edit"
                        onClick={() => this.togglePageModal(prod)}
                      ></i>
                      <Link
                        onMouseEnter={() => this.toggleHoverPage(i)}
                        onMouseLeave={() => this.toggleHoverPage(i)}
                        onClick={() =>
                          this.props.match.params.appId !=
                          (prod.isHomepage == "true"
                            ? localStorage.mainId
                            : prod.pageId)
                            ? this.loader(i)
                            : ""
                        }
                        style={{
                          color: this.state.navStyles.color,
                          backgroundColor: prod.isHover
                            ? this.state.navStyles.highlightColor
                            : "",
                        }}
                        className="nav-link js-scroll-trigger"
                        to={
                          "./" +
                          (prod.isHomepage == "true"
                            ? localStorage.mainId
                            : prod.pageId)
                        }
                      >
                        {prod.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </nav>

            {/* EDITING FLOATING BUTTON */}

            <nav
              style={{
                backgroundColor: this.state.navStyles.secNavBgColor,
                height: this.state.navStyles.secNavHeight,
              }}
              className="webzy-nav-second navbar-expand-lg navbar-dark sticky fixed-top"
              id="secNav"
            >
              <div
                className="collapse navbar-collapse second-navbar "
                id="navbarResponsive"
              >
                <ul className="navbar-nav ml-auto">
                  {this.state.sections.map((prod, i) => (
                    <li
                      style={{ marginRight: "10px", listStyle: "none" }}
                      className="nav-item"
                    >
                      <a
                        onMouseEnter={() => this.toggleHover(i)}
                        onMouseLeave={() => this.toggleHover(i)}
                        style={{
                          color: this.state.navStyles.secNavTextColor,
                          background: prod.isHover
                            ? this.state.navStyles.secNavTextHighlightColor
                            : "",
                        }}
                        className="nav-link js-scroll-trigger"
                        href={"#" + prod.title.replace(/\s/g, "")}
                      >
                        {" "}
                        {prod.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </nav>

            {this.state.isFirstPage ? (
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <button
                  className="addPageButton"
                  onClick={() => this.togglePageModal()}
                >
                  <i className="fa fa-window-restore icons"></i>Add New Page
                </button>
              </div>
            ) : (
              <div>
                {/* Header */}
                <header
                  style={{
                    backgroundImage: `url(${this.state.bannerImageUrl})`,
                    height: this.state.bannerHeight,
                    padding: this.state.bannerPadding,
                  }}
                  className="masthead"
                >
                  <div
                    className="container text-center"
                    style={{
                      margin: this.state.bannerMargin,
                      paddingBottom: "20px",
                    }}
                  >
                    <div className="intro-text animatedParent">
                      <div className="intro-lead-in animatedParent">
                        <h1
                          contentEditable="true "
                          className="animated fadeInRight"
                          style={{ color: `${this.state.subtitleColor}` }}
                        >
                          {this.state.subTitle}
                        </h1>
                      </div>
                      <div
                        className="intro-heading text-uppercase animated fadeInLeft"
                        style={{ color: `${this.state.titleColor}` }}
                      >
                        {this.state.websiteTitle}
                      </div>
                      <button
                        onMouseEnter={() => this.toggleHover("btnKnow")}
                        onMouseLeave={() => this.toggleHover("btnKnow")}
                        style={{
                          background: this.state.isBtnHover
                            ? this.state.btnHighlightColor
                            : this.state.btnBackgroundColor,
                          border: "none",
                        }}
                        className="bannerButton btn  btn-xl text-uppercase js-scroll-trigger"
                      >
                        <a
                          style={{ color: this.state.btnColor }}
                          href={"#" + this.state.btnLink}
                        >
                          {this.state.btnText}
                        </a>
                      </button>
                      <br />{" "}
                      <i
                        className="fa editclass fa-edit"
                        style={{ marginTop: "20px" }}
                        onClick={this.toggleBannerImageModal}
                      ></i>
                    </div>
                  </div>
                </header>
                {/* Services */}

                <div>
                  <div id="rightSidebar" className="addSection editclass">
                    <span
                      className="adddivsign1 clickable"
                      onClick={() => this.toggleAddSectionModal("Products")}
                    >
                      + Products
                    </span>
                    <span
                      className="adddivsign2 clickable"
                      onClick={() => this.toggleAddSectionModal("Services")}
                    >
                      + Services
                    </span>
                    <span
                      className="adddivsign3 clickable"
                      onClick={() => this.toggleAddSectionModal("About")}
                    >
                      + About
                    </span>
                    <span
                      className="adddivsign4 clickable"
                      onClick={() => this.toggleAddSectionModal("Team")}
                    >
                      + Team
                    </span>
                    <span
                      className="adddivsign8 clickable"
                      onClick={() => this.toggleAddSectionModal("Timeline")}
                    >
                      + Timeline
                    </span>
                    <span
                      className="adddivsign5 clickable"
                      onClick={() => this.toggleAddSectionModal("Contact")}
                    >
                      + Contact
                    </span>

                    <span
                      className="adddivsign6 clickable"
                      onClick={() => this.toggleAddSectionModal("Query")}
                    >
                      + Query
                    </span>
                  </div>

                  {/* <span className="adddivsign7 clickable" onClick={this.removeLastSection}>- Remove Section</span>*/}
                </div>

                <div>
                  <div id="leftSidebar" className="sortSection editclass ">
                    <ReactDragListView {...dragProps}>
                      {this.state.sections.map((item, index) => (
                        <div className="sortitem" key={index}>
                          <i
                            onClick={() => this.confirmDelete(index)}
                            className="fas fa-times"
                          ></i>
                          &nbsp;
                          {item.title}
                          <a style={{ marginLeft: "10px" }} href="#">
                            <i className="fa fa-arrows-alt"></i>
                          </a>
                        </div>
                      ))}
                    </ReactDragListView>{" "}
                  </div>
                </div>

                {this.state.sections.map((prod, i) =>
                  prod.type == "products" ? (
                    <Products
                      saveSectionProperties={this.saveSectionProperties}
                      productStyle={prod.styles}
                      sectionKey={i}
                      innerProducts={prod.innerProducts}
                      productCount={prod.productCount}
                      title={prod.title}
                    />
                  ) : prod.type == "services" ? (
                    <Service
                      productStyle={prod.styles}
                      sectionKey={i}
                      saveSectionProperties={this.saveSectionProperties}
                      innerProducts={prod.innerProducts}
                      productCount={prod.productCount}
                      title={prod.title}
                    />
                  ) : prod.type == "about" ? (
                    <About
                      productStyle={prod.styles}
                      aboutUsImage={prod.aboutUsImage}
                      content={prod.content}
                      sectionKey={i}
                      saveSectionProperties={this.saveSectionProperties}
                      productCount={prod.productCount}
                      title={prod.title}
                    />
                  ) : prod.type == "team" ? (
                    <Team
                      productStyle={prod.styles}
                      innerProducts={prod.innerProducts}
                      sectionKey={i}
                      saveSectionProperties={this.saveSectionProperties}
                      productCount={prod.productCount}
                      title={prod.title}
                    />
                  ) : prod.type == "timeline" ? (
                    <Timeline
                      productStyle={prod.styles}
                      sectionKey={i}
                      innerProducts={prod.innerProducts}
                      saveSectionProperties={this.saveSectionProperties}
                      productCount={prod.productCount}
                      title={prod.title}
                    />
                  ) : prod.type == "contact" ? (
                    <Contact
                      productStyle={prod.styles}
                      sectionKey={i}
                      saveSectionProperties={this.saveSectionProperties}
                      contactInfo={prod.contactInfo || {}}
                      title={prod.title}
                    />
                  ) : prod.type == "query" ? (
                    <Query
                      productStyle={prod.styles}
                      sectionKey={i}
                      saveSectionProperties={this.saveSectionProperties}
                      contactInfo={prod.contactInfo || {}}
                      title={prod.title}
                    />
                  ) : (
                    ""
                  )
                )}

                {!this.state.isFetchingData ? (
                  <Footer
                    websiteTitle={this.state.websiteTitle}
                    saveFooterProperties={this.saveFooterProperties}
                    footerstyles={this.state.footerstyles}
                    footerLinks={this.state.sections}
                  />
                ) : (
                  ""
                )}
              </div>
            )}
          </>
        )}
      </div>
    ) : (
      <Redirect to="/login" push={true} />
    );
  }
}

export default withRouter(MainPage);
