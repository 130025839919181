import React, { Component }  from "react";
import { apiIssueMaster } from "../../apiUtils";

import axios from "axios";
import AddIssues from "./addIssues";

export default class EditIssues1 extends Component{
  
  constructor(props){
    super(props);
    this.state={
      entry:{},
      id:""
    }
  }

 componentDidMount(){
  const id = this.props.match.params.id;
  this.setState({ id });
   this.fetchQueryById(this, id)
 }

 fetchQueryById(that, id) {
  axios
    .get(apiIssueMaster + "businusquery/getIssueById/" + id)
    .then(function (response) {
      debugger;
      console.log(response.data);
      that.setState({ entry: response.data });

    })
    .catch(function (error) {
      console.log(error);
    });
}

  render(){
  return(
    <><AddIssues entry={this.state.entry} mode="Edit"/></>
  )

  }

}