import React, { Component } from "react";

import { Link } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1, apiBaseUrl5 } from "../../apiUtils";
import { apiBaseUrl2 } from "../../apiUtils";
import FileBase64 from "react-file-base64";
import Modal from "react-modal";
import { ChatFeed, Message } from "react-chat-ui";
import { Multiselect } from "multiselect-react-dropdown";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import _ from "underscore";
let context = null;
const data = [];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px ",
    position: "Absolute",
    zIndex: "30000",
    backgroundColor: "none",
  },
};

export default class editOrganisation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organisationId: "",
      organisationName: "",
      organisationPhone: "",
      organisationEmail: "",
      organisationDisplayName: "",
      primaryContact: "",
      mode: "",
      billingAddress: data.billingAddress,
      billingCountry: data.billingCountry,
      billingAttention: data.billingAttention,
      billingCity: data.billingCity,
      billingPhone: data.billingPhone,
      billingZipCode: data.billingZipCode,
      shippingAddress: data.shippingAddress,
      shippingCountry: data.shippingCountry,
      shippingAttention: data.shippingAttention,
      shippingCity: data.shippingCity,
      shippingPhone: data.shippingPhone,
      shippingZipCode: data.shippingZipCode,
      contactPersons: data.contactPersons,
      team: [],
      bankDetails: [],

      teamMembers: [],
      entry: { attributes: [] },
      superSliderState: "additional",
      contactPersons: [],
      sliderState: 1,
    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber) {
    this.setState({ sliderState: orderNumber });
  }
  //function for changing the status button on click to open or close
  handleOnChange = (e) => {
    debugger;
    var value = e.target.value;

    var formName = e.target.name;

    var entry = this.state.entry;
    entry[formName] = value;
    this.setState({ entry });
  };

  getFiles = (files) => {
    this.setState({ selectedFile: files[0] });
    this.fileUploadHandler(this, "logo");
  };

  fileUploadHandler = (that, docType) => {
    that.setState({ loading: true });
    debugger;
    axios
      .post(apiBaseUrl2 + "businusquery/uploadAttachment", {
        uploadImage: that.state.selectedFile.base64,

        imageName: that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({
            entry: {
              ...that.entry,
              [docType]:
                "https://businus-files-live.s3.amazonaws.com/mailAttachments/" +
                that.state.selectedFile.name,
            },
          });

          //that.successNotification("Uploaded Succesfully");
        } else {
          //   that.errorNotification("Some Error Has Occured");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fetchEmployees(that, source) {
    axios
      .get(apiBaseUrl2 + "businus/employee/list/" + localStorage.spId)
      .then(function (response) {
        debugger;
        console.log(response.data);
        var allEmployees = response.data;

        var teamLeads = _.where(allEmployees, { title: "Team Lead" });
        var teamMembers = _.filter(allEmployees, function (emp) {
          return emp.title != "Team Lead";
        });

        var teamMembersMultiSelectData = [];

        _.each(teamMembers, function (member) {
          teamMembersMultiSelectData.push({
            name: member.firstName + member.lastName,
            id: member.id,
          });
        });

        that.setState({
          teamMembers: teamMembersMultiSelectData,
          teamLeads: teamLeads,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  onSelect(optionsList, selectedItem) {
    context.setState({ team: optionsList });

    console.log(optionsList, selectedItem);
  }

  onRemove(optionList, removedItem) {
    context.setState({ team: optionList });
  }

  handleOnChangePerson = (e, i) => {
    var contactPersons = this.state.entry.contactPersons;

    contactPersons[i][e.target.name] = e.target.value;

    this.setState({
      entry: { ...this.state.entry, contactPersons: contactPersons },
    });
  };

  changeSuperSliderState(name) {
    this.setState({ superSliderState: name });
    if (name === "additional") {
      this.setState({ sliderState: 1 });
    } else {
      this.setState({ sliderState: 6 });
    }
  }

  addNewContact = () => {
    console.log("here");
    var newPerson = {
      salutation: "",
      name: "",
      email: "",
      phone: "",
    };

    var contactPersons = this.state.entry.contactPersons;
    console.log("contactPersons", contactPersons);
    contactPersons.push(newPerson);
    this.setState({
      entry: { ...this.state.entry, contactPersons: contactPersons },
    });
  };
  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.setState({ id });
    this.fetchEmployees(this);
    this.fetchQueryById(this, id);
    this.fetchThreadById(this, id);
    this.markAsRead(this, id);
  }

  markAsRead(that, id) {
    axios
      .get(apiBaseUrl2 + "businusquery/read/" + id)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  }

  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(apiBaseUrl1 + "businusquery/getOrganisationById/" + id)
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  
  fetchThreadById(that, id) {
    axios
      .get(apiBaseUrl1 + "businusquery/listmail/" + id)
      .then(function (response) {
        debugger;
        console.log(response.data);
        var threads =
          response.data == "" ? [] : _.sortBy(response.data, "date").reverse();
        that.setState({ thread: threads });

        var documents = _.where(threads, { docType: "Document" });
        var proposals = _.where(threads, { docType: "Proposal" });
        var mails = _.where(threads, { docType: "Mail" });
        that.setState({ documents });
        that.setState({ proposals });
        that.setState({ mails });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getFiles(files) {
    this.setState({ selectedFile: files[0] });
  }

  getFormattedDate(millis) {
    return new Date(millis).toDateString();
  }

  toggleOpen = (status) => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...entry.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };

  changeDocType(type) {
    this.setState({ docType: type });
  }

  fileUploadHandlerForAttachment = (that, docType, i) => {
    that.setState({ loading: true });
    debugger;
    axios
      .post(apiBaseUrl2 + "businusquery/uploadAttachment", {
        uploadImage: that.state.selectedFile.base64,

        imageName: that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });

          that.setState({
            [docType]:
              "https://businus-files-live.s3.amazonaws.com/mailAttachments/" +
              that.state.selectedFile.name,
          });

          var entry = that.state.entry;

          entry.attributes[i]["attachment"] =
            "https://businus-files-live.s3.amazonaws.com/mailAttachments/" +
            that.state.selectedFile.name;

          that.setState({ entry: entry });

          //that.successNotification("Uploaded Succesfully");
        } else {
          //   that.errorNotification("Some Error Has Occured");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleOnChangeAttribute = (e, i) => {
    var entry = this.state.entry;

    this.setState({ entry: entry });

    entry.attributes[i][e.target.name] = e.target.value;

    this.setState({ entry });
  };

  addNewAttribute = () => {
    var newAttribute = {
      name: "",
      value: "",
      attachment: "",
    };

    var entry = this.state.entry;

    entry.attributes = entry.attributes || [];

    entry.attributes.push(newAttribute);
    this.setState({ entry: entry });
  };

  sendMail() {
    var data = context.state.entry;
    axios
      .post(apiBaseUrl1 + "businus/organisation/update/" + context.state.id, {
        primaryContact: data.primaryContact,
        organisationMode: data.organisationMode,
        logo: data.logo,
        salutation: data.salutation,
        organisationType: data.organisationType,
        organisationName: data.organisationName,
        website: data.website,
        organisationDisplayName: data.organisationDisplayName,
        organisationEmail: data.organisationEmail,
        organisationPhone: data.organisationPhone,
        description: data.description,
        team: data.team,
        billingAddress: data.billingAddress,
        billingCountry: data.billingCountry,
        billingAttention: data.billingAttention,
        billingCity: data.billingCity,
        billingState: data.billingState,
        billingPhone: data.billingPhone,
        billingZipCode: data.billingZipCode,
        shippingAddress: data.shippingAddress,
        shippingCountry: data.shippingCountry,
        shippingAttention: data.shippingAttention,
        shippingCity: data.shippingCity,
        shippingState: data.shippingState,
        shippingPhone: data.shippingPhone,
        shippingZipCode: data.shippingZipCode,
        contactPersons: data.contactPersons,
        bankDetails: data.bankDetails,
        attributes: data.attributes,
        team: data.team,
      })
      .then(function (response) {
        debugger;
        alert("Success!");
        //  context.fetchThreadById(context,query.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    localStorage.setItem("MAILURL", url);
  }

  handleOnBankDetailsChange = (e) => {
    let entry = this.state.entry;

    entry.bankDetails = {
      ...entry.bankDetails,
      [e.target.name]: e.target.value,
    };

    this.setState({
      entry,
    });
  };

  //updating the status button

  render() {
    const { sliderState } = this.state;
    const { superSliderState, entry } = this.state;
    const active = " active-button";
    const deactive = "";
    return (
      <div className="Div">
        <div className="row breadcrumb-row">
          <BreadCrumbs />
        </div>
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page" style={{ padding: "0px" }}>
            <div className="row-resource-head">
              <h2 className="resource-head"> Edit Organisation </h2>
            </div>
            <div className="row ">
              <div className="col-md-12 info-column account-col2">
                <div id="field">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="form-group">
                          <label className="control-label col-md-4">
                            <span
                              className="popovercontainer text-dashed-underline ember-view"
                              data-original-title
                              title
                            >
                              {" "}
                              Mode* :{" "}
                            </span>{" "}
                          </label>
                          <div className="col-md-8">
                            <div className="radio-inline">
                              <label className="cursor-poiner">
                                <input
                                  name="organisationMode"
                                  type="radio"
                                  value="offline"
                                  checked={
                                    this.state.entry.organisationMode ==
                                    "offline"
                                  }
                                  onChange={this.handleOnChange}
                                  defaultValue="offline"
                                />
                                Offline{" "}
                              </label>
                            </div>
                            <div className="radio-inline">
                              <label className="cursor-poiner">
                                {" "}
                                <input
                                  name="organisationMode"
                                  onChange={this.handleOnChange}
                                  value="online"
                                  checked={
                                    this.state.entry.organisationMode ==
                                    "online"
                                  }
                                  type="radio"
                                  defaultValue="online"
                                />
                                Online{" "}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">
                            <span
                              id="ember295"
                              className="popovercontainer text-dashed-underline ember-view"
                              data-original-title
                              title
                            >
                              {" "}
                              Primary Contact:{" "}
                            </span>{" "}
                          </label>
                          <div
                            className="inline-fields col-md-8"
                            style={{ padding: 0 }}
                          >
                            <div className="col-md-3">
                              <select
                                placeholder="Salutation"
                                autofocus
                                onChange={this.handleOnChange}
                                name="salutation"
                                value={entry.salutation}
                                className="form-control select-form"
                              >
                                <option>Mr.</option>
                                <option>Mrs</option>
                                <option>Ms</option>
                              </select>
                            </div>

                            <div className="col-md-8">
                              <input
                                placeholder="Name"
                                autofocus
                                onChange={this.handleOnChange}
                                name="primaryContact"
                                value={entry.primaryContact}
                                className="form-control ember-text-field ember-view"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Organisation Type:
                          </label>
                          <div className="col-md-6">
                            <select
                              name="organisationType"
                              onChange={this.handleOnChange}
                              value={entry.organisationType}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            >
                              <option value="not selected">Select One</option>
                              <option value="pan">Branch</option>
                              <option value="passport">Subsidiary</option>
                              <option value="aadhar">Sister Concern</option>
                              <option value="voterid">Associate</option>
                              <option value="other">Partner</option>
                            </select>
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">
                            {" "}
                            Organisation Name:{" "}
                          </label>
                          <div className="col-md-6">
                            <input
                              onChange={this.handleOnChange}
                              name="organisationName"
                              value={entry.organisationName}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">
                            {" "}
                            Organisation Display Name:{" "}
                          </label>
                          <div className="col-md-6">
                            <input
                              id="ember301"
                              onChange={this.handleOnChange}
                              name="organisationDisplayName"
                              value={entry.organisationDisplayName}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                        {/*      <div className="form-group">
                    <label className="control-label col-md-4">
                      Customer Display Name
                    </label>
                    <div className="col-md-6">
                      <input
                          onChange={this.handleOnChange}
                          name="customerDisplayName"
                        value={entry.customerDisplayName}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div> */}

                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Organisation Email:
                          </label>
                          <div className="col-md-6">
                            <input
                              name="organisationEmail"
                              onChange={this.handleOnChange}
                              value={entry.organisationEmail}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Organisation Phone:
                          </label>
                          <div className="col-md-6">
                            <input
                              name="organisationPhone"
                              onChange={this.handleOnChange}
                              value={entry.organisationPhone}
                              className="form-control ember-text-field ember-view"
                              type="tel"
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Website:
                          </label>
                          <div className="col-md-6">
                            <input
                              name="website"
                              onChange={this.handleOnChange}
                              value={entry.website}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <img
                          src={
                            this.state.entry.logo != ""
                              ? this.state.entry.logo
                              : "avatar"
                          }
                          className="avatar"
                        />
                        <span>
                          <FileBase64
                            multiple={true}
                            onDone={this.getFiles.bind(this)}
                          ></FileBase64>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row " style={{ marginTop: "40px" }}>
                    <div
                      className={
                        superSliderState == "additional"
                          ? "col-md-2 active1 superoptions"
                          : "col-md-2 superoptions"
                      }
                    >
                      <div
                        className=" "
                        onClick={() =>
                          this.changeSuperSliderState("additional")
                        }
                      >
                        Additional Info
                      </div>
                    </div>
                  </div>

                  {superSliderState === "additional" ? (
                    <div
                      className="row "
                      style={{
                        borderTop: "2px solid #4985BD",
                        backgroundColor: "#f7f7f7",
                      }}
                    >
                      <div
                        className={
                          sliderState == 1 ? "col-md-2 active2 " : "col-md-2"
                        }
                      >
                        <div
                          className="options8 "
                          onClick={() => this.changeSliderState(1)}
                        >
                          <i
                            className="fa fa-home optionsIcon"
                            style={{ fontSize: "12px" }}
                          ></i>
                          Address
                        </div>
                      </div>
                      <div
                        className={
                          sliderState == 2 ? "col-md-3 active2 " : "col-md-3"
                        }
                      >
                        <div
                          className="options8"
                          onClick={() => this.changeSliderState(2)}
                        >
                          <i
                            className="fa fa-envelope optionsIcon"
                            style={{ fontSize: "12px" }}
                          ></i>
                          Customer Email
                        </div>
                      </div>
                      <div
                        className={
                          sliderState == 3 ? "col-md-3 active2 " : "col-md-3"
                        }
                      >
                        <div
                          className="options8"
                          onClick={() => this.changeSliderState(3)}
                        >
                          <i
                            className="fa fa-university optionsIcon"
                            style={{ fontSize: "12px" }}
                          ></i>{" "}
                          Bank Details
                        </div>
                      </div>
                      <div
                        className={
                          sliderState == 4 ? "col-md-2 active2 " : "col-md-2"
                        }
                      >
                        <div
                          className="options8"
                          onClick={() => this.changeSliderState(4)}
                        >
                          <i
                            className="fa fa-address-card optionsIcon"
                            style={{ fontSize: "12px" }}
                          ></i>{" "}
                          Attributes
                        </div>
                      </div>
                      <div
                        className={
                          sliderState == 5 ? "col-md-2 active2 " : "col-md-2"
                        }
                      >
                        <div
                          className="options8"
                          onClick={() => this.changeSliderState(5)}
                        >
                          <i
                            className="fa fa-users optionsIcon"
                            style={{ fontSize: "12px" }}
                          ></i>{" "}
                          Employees
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/*--------------------------------------------------------1-------------------------------*/}

                  {sliderState == 1 ? (
                    <div className="container row">
                      <div className="col-md-6">
                        <div className="row" style={{ marginBottom: "-20px" }}>
                          <label className="control-label add-header col-md-12">
                            Billing Address
                          </label>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Attention :
                          </label>
                          <div className="col-md-7">
                            <input
                              name="billingAttention"
                              onChange={this.handleOnChange}
                              value={entry.billingAttention}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Country :
                          </label>
                          <div className="col-md-7">
                            <input
                              name="billingCountry"
                              onChange={this.handleOnChange}
                              value={entry.billingCountry}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Address* :
                          </label>
                          <div className="col-md-7">
                            <textarea
                              placeholder="Billing Address"
                              name="billingAddress"
                              onChange={this.handleOnChange}
                              value={entry.billingAddress}
                              id="ember330"
                              className="form-control ember-text-area ember-view"
                              defaultValue={""}
                            />
                          </div>
                        </div>

                        <div
                          className="form-group"
                          style={{ marginTop: "36px" }}
                        >
                          <label className="control-label col-md-4">
                            City* :
                          </label>
                          <div className="col-md-7">
                            <input
                              id="ember332"
                              name="billingCity"
                              onChange={this.handleOnChange}
                              value={entry.billingCity}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />{" "}
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">
                            State :
                          </label>
                          <div className="col-md-7">
                            <input
                              id="ember333"
                              className="form-control ember-text-field ember-view"
                              name="billingState"
                              onChange={this.handleOnChange}
                              value={entry.billingState}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Zip Code* :
                          </label>
                          <div className="col-md-7">
                            <input
                              id="ember334"
                              name="billingZipCode"
                              onChange={this.handleOnChange}
                              value={entry.billingZipCode}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />{" "}
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Phone* :
                          </label>
                          <div className="col-md-7">
                            <input
                              id="ember335"
                              name="billingPhone"
                              onChange={this.handleOnChange}
                              value={entry.billingPhone}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />{" "}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="row" style={{ marginBottom: "-20px" }}>
                          <label className="control-label add-header col-md-12">
                            Shipping Address
                          </label>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Attention :
                          </label>
                          <div className="col-md-7">
                            <input
                              name="shippingAttention"
                              onChange={this.handleOnChange}
                              value={entry.shippingAttention}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Country :
                          </label>
                          <div className="col-md-7">
                            <input
                              name="shippingCountry"
                              onChange={this.handleOnChange}
                              value={entry.shippingCountry}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Address :
                          </label>
                          <div className="col-md-7">
                            <textarea
                              placeholder="Shipping Address"
                              name="shippingAddress"
                              onChange={this.handleOnChange}
                              value={entry.shippingAddress}
                              id="ember330"
                              className="form-control ember-text-area ember-view"
                              defaultValue={""}
                            />
                          </div>
                        </div>

                        <div
                          className="form-group"
                          style={{ marginTop: "36px" }}
                        >
                          <label className="control-label col-md-4">
                            City:
                          </label>
                          <div className="col-md-7">
                            <input
                              id="ember332"
                              name="shippingCity"
                              onChange={this.handleOnChange}
                              value={entry.shippingCity}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />{" "}
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">
                            State :
                          </label>
                          <div className="col-md-7">
                            <input
                              id="ember333"
                              className="form-control ember-text-field ember-view"
                              name="shippingState"
                              onChange={this.handleOnChange}
                              value={entry.shippingState}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Zip Code :
                          </label>
                          <div className="col-md-7">
                            <input
                              id="ember334"
                              name="shippingZipCode"
                              onChange={this.handleOnChange}
                              value={entry.shippingZipCode}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />{" "}
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Phone :
                          </label>
                          <div className="col-md-7">
                            <input
                              id="ember335"
                              name="shippingPhone"
                              onChange={this.handleOnChange}
                              value={entry.shippingPhone}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/*--------------------------------------------------------2-------------------------------*/}
                  {sliderState == 2 ? (
                    <div>
                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-12">
                          <div
                            className="row"
                            style={{ marginBottom: "-20px" }}
                          >
                            <label className="control-label add-header col-md-12">
                              Contact Persons*
                            </label>
                          </div>
                          <div
                            className="text-right"
                            style={{ marginRight: "40px" }}
                          >
                            <button
                              className="add-button"
                              onClick={() => this.addNewContact()}
                            >
                              {" "}
                              <i className="fa fa-plus "></i>
                            </button>
                          </div>
                          <table
                            className="add-customer-table"
                            style={{ width: "100%" }}
                          >
                            <tr>
                              <th className="table-head">Salutation</th>
                              <th className="table-head">Name</th>

                              <th className="table-head">Email Address </th>
                              <th className="table-head">Work Phone</th>
                            </tr>
                            {entry.contactPersons.map((person, i) => (
                              <tr>
                                <td className="table-item">
                                  <select
                                    className="form-control select-form"
                                    name="salutation"
                                    placeholder="salutation"
                                    onChange={(e) =>
                                      this.handleOnChangePerson(e, i)
                                    }
                                    value={entry.contactPersons[i].salutation}
                                  >
                                    <option>Mr.</option>
                                    <option>Mrs</option>
                                    <option>Ms</option>
                                  </select>
                                </td>
                                <td className="table-item">
                                  <input
                                    name="name"
                                    onChange={(e) =>
                                      this.handleOnChangePerson(e, i)
                                    }
                                    value={entry.contactPersons[i].name}
                                  />{" "}
                                </td>
                                <td className="table-item">
                                  <input
                                    name="email"
                                    onChange={(e) =>
                                      this.handleOnChangePerson(e, i)
                                    }
                                    value={entry.contactPersons[i].email}
                                  />{" "}
                                </td>
                                <td className="table-item">
                                  <input
                                    name="phone"
                                    onChange={(e) =>
                                      this.handleOnChangePerson(e, i)
                                    }
                                    value={entry.contactPersons[i].phone}
                                  />{" "}
                                </td>
                              </tr>
                            ))}
                          </table>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/*--------------------------------------------------------3-------------------------------*/}

                  {sliderState == 3 ? (
                    <div className="container row">
                      <div className="col-md-12">
                        <div className="row" style={{ marginBottom: "-20px" }}>
                          <label className="control-label add-header col-md-12">
                            Banking Details
                          </label>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Bank Name* :
                          </label>
                          <div className="col-md-8">
                            <input
                              name="bankName"
                              onChange={this.handleOnBankDetailsChange}
                              value={entry.bankDetails.bankName}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Branch Name*:
                          </label>
                          <div className="col-md-8">
                            <input
                              name="branchName"
                              onChange={this.handleOnBankDetailsChange}
                              value={entry.bankDetails.branchName}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Account No*:
                          </label>
                          <div className="col-md-8">
                            <input
                              name="accountNo"
                              onChange={this.handleOnBankDetailsChange}
                              value={entry.bankDetails.accountNo}
                              id="ember330"
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Account Type:
                          </label>
                          <div className="col-md-6">
                            <select
                              name="accountType"
                              onChange={this.handleOnBankDetailsChange}
                              value={entry.bankDetails.accountType}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            >
                              <option value="pan">Select One</option>
                              <option value="pan">Savings</option>
                              <option value="passport">Current</option>
                              <option value="aadhar">Credit Card A/C</option>
                              <option value="voterid">Loans</option>
                              <option value="other">Overdraft</option>
                              <option value="other">Deposit</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">
                            IFSC Code* :
                          </label>
                          <div className="col-md-8">
                            <input
                              id="ember333"
                              className="form-control ember-text-field ember-view"
                              name="ifscCode"
                              onChange={this.handleOnBankDetailsChange}
                              value={entry.bankDetails.ifscCode}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-4">
                            MICR Code :
                          </label>
                          <div className="col-md-8">
                            <input
                              id="ember334"
                              name="micrCode"
                              onChange={this.handleOnBankDetailsChange}
                              value={entry.bankDetails.micrCode}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {sliderState == 4 ? (
                    <div>
                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-12">
                          <div
                            className="row"
                            style={{ marginBottom: "-20px" }}
                          >
                            <label className="control-label add-header col-md-12">
                              Attributes
                            </label>
                          </div>
                          <div
                            className="text-right"
                            style={{ marginRight: "40px" }}
                          >
                            <button
                              className="add-button"
                              onClick={this.addNewAttribute}
                            >
                              {" "}
                              <i className="fa fa-plus "></i>
                            </button>
                          </div>
                          <table
                            className="add-customer-table"
                            style={{ width: "100%" }}
                          >
                            <tr>
                              <th className="table-head">Attributes</th>
                              <th className="table-head">Attribute Value</th>

                              <th className="table-head">
                                Attribute Attachment{" "}
                              </th>
                            </tr>
                            {this.state.entry.attributes != undefined
                              ? this.state.entry.attributes.map((person, i) => (
                                  <tr>
                                    <td className="table-item">
                                      <select
                                        className="form-control select-form"
                                        name="name"
                                        placeholder="salutation"
                                        onChange={(e) =>
                                          this.handleOnChangeAttribute(e, i)
                                        }
                                        value={
                                          this.state.entry.attributes[i].name
                                        }
                                      >
                                        <option value="pan">PAN</option>

                                        <option>Aadhar Card Number</option>
                                        <option>Authorised Capital</option>
                                        <option>Category</option>
                                        <option>CIN no</option>
                                        <option>DIN no</option>
                                        <option>Excise No</option>
                                        <option>GST No</option>
                                        <option>Incorporation Date</option>
                                        <option>Paid Up Capital</option>
                                        <option>PAN</option>
                                        <option>Pt No.(EC)</option>
                                        <option>Pt No.(RC)</option>
                                        <option>Service Tax No.</option>
                                        <option>Shop Act Expiry Date.</option>
                                        <option>Shop Act No.</option>
                                        <option>Shop Act Reg Date.</option>
                                        <option>TAN No</option>
                                        <option>VAT No.</option>
                                        <option>
                                          With Effect From (W.E.F)
                                        </option>
                                      </select>
                                    </td>
                                    <td className="table-item">
                                      <input
                                        name="value"
                                        onChange={(e) =>
                                          this.handleOnChangeAttribute(e, i)
                                        }
                                        value={
                                          this.state.entry.attributes[i].value
                                        }
                                      />{" "}
                                    </td>
                                    <td className="table-item">
                                      {" "}
                                      <div className="col-md-6">
                                        <FileBase64
                                          multiple={true}
                                          onDone={this.getFiles}
                                        />
                                      </div>
                                      <div className="col-md-5">
                                        <button
                                          className="btn btn-primary attachment"
                                          onClick={() =>
                                            this.fileUploadHandlerForAttachment(
                                              this,
                                              "attributeAttachment",
                                              i
                                            )
                                          }
                                          value="Select a File"
                                        >
                                          <i
                                            className="fa fa-paperclip"
                                            aria-hidden="true"
                                          ></i>
                                          Attachment
                                        </button>
                                      </div>{" "}
                                    </td>
                                  </tr>
                                ))
                              : ""}
                          </table>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {sliderState == 5 ? (
                    <div className="container row">
                      <div className="col-md-12">
                        <div className="row" style={{ marginBottom: "-20px" }}>
                          <label className="control-label add-header col-md-12">
                            Employees
                          </label>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Team*:
                          </label>
                          <div className="col-md-6">
                            {entry.teamMembers != undefined ? (
                              entry.teamMembers.length > 0 ? (
                                <Multiselect
                                  options={entry.teamMembers}
                                  onSelect={this.onSelect}
                                  placeholder="Select One or More"
                                  onRemove={this.onRemove}
                                  displayValue="name"
                                />
                              ) : (
                                <Multiselect
                                  options={this.state.teamMembers}
                                  onSelect={this.onSelect}
                                  placeholder="Select One or More"
                                  onRemove={this.onRemove}
                                  displayValue="name"
                                />
                              )
                            ) : (
                              <Multiselect
                                options={this.state.teamMembers}
                                onSelect={this.onSelect}
                                placeholder="Select One or More"
                                onRemove={this.onRemove}
                                displayValue="name"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {sliderState == 6 ? (
                    <div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="control-label col-md-4">
                              Module Access:
                            </label>
                            <div className="col-md-6">
                              {entry.modules.length > 0 ? (
                                <Multiselect
                                  options={entry.modules}
                                  onSelect={this.onSelect}
                                  selectedItem={entry.selectedItem}
                                  onRemove={this.onRemove}
                                  displayValue="name"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="control-label col-md-4">
                              Role* :
                            </label>
                            <div className="col-md-7">
                              <select
                                name="role"
                                onChange={this.handleOnChange}
                                value={entry.role}
                                className="form-control ember-text-field ember-view"
                                type="text"
                              >
                                <option value="admin">Admin</option>
                                <option value="employee">Employee</option>
                                <option value="customise">Customise</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        {entry.canCustomise == true ? (
                          <table
                            className="add-module-table"
                            style={{
                              width: "84%",
                              marginTop: "9%",
                              marginLeft: "5%",
                            }}
                          >
                            <tr>
                              <th
                                className="table-head"
                                style={{
                                  backgroundColor: "#e8eaed",
                                  width: "44%",
                                }}
                              >
                                Selected Modules
                              </th>
                              <th
                                className="table-head"
                                style={{ backgroundColor: "#fafac0" }}
                              >
                                View
                              </th>

                              <th
                                className="table-head"
                                style={{ backgroundColor: "#d0fac0" }}
                              >
                                Add{" "}
                              </th>
                              <th
                                className="table-head"
                                style={{ backgroundColor: "#e0dbff" }}
                              >
                                Update
                              </th>
                              <th
                                className="table-head"
                                style={{ backgroundColor: "#fac6c0" }}
                              >
                                Delete
                              </th>
                            </tr>

                            <tr>
                              <td className="table-item">
                                <select
                                  className="form-control select-form"
                                  name="salutation"
                                  placeholder="salutation"
                                  value={entry.selectedItem}
                                >
                                  {}
                                </select>
                              </td>
                              <td
                                className="table-item"
                                style={{ width: "80px" }}
                              >
                                <input
                                  name="name"
                                  value="CAN_VIEW"
                                  type="checkbox"
                                />{" "}
                              </td>
                              <td className="table-item">
                                <input
                                  name="name"
                                  value="CAN_VIEW"
                                  type="checkbox"
                                />{" "}
                              </td>
                              <td className="table-item">
                                <input
                                  name="name"
                                  value="CAN_VIEW"
                                  type="checkbox"
                                />{" "}
                              </td>
                              <td className="table-item">
                                <input
                                  name="name"
                                  value="CAN_VIEW"
                                  type="checkbox"
                                />{" "}
                              </td>
                            </tr>
                          </table>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="text-left">
                    <button
                      type="button"
                      onClick={this.sendMail}
                      className="button1"
                    >
                      {" "}
                      Submit{" "}
                    </button>
                  </div>
                  {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
