import React, { Component } from "react";

import BreadCrumbs from '../../BreadCrumbs';
import { Link } from "react-router-dom";
import axios from 'axios';
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import CsvDownloader from 'react-csv-downloader'
import ReactTable from 'react-table';
import $ from "jquery";
import _ from 'underscore';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

const data = [];


let context="null";
const columnsForExcel = [
  {
    id: 'mailId',
    displayName : "Mail ID",
    minWidth:180
  },
  {
    id: 'assignmentId',
    displayName : "Assingment ID",
width:180
  },
  {
    displayName : "Client",
    id: "client",
   
  },
  {
    displayName : "Sender Mail",
    id: "email",
   
  },
  {
    displayName : "Subject",
    id: "subject",
   
  },
  {
    displayName : "Message",
    id: "message",
   
  },
  {
    displayName : "Attachment",
    id: "attachmentName",
   
  },
  
  {
   
    id: "date",
    displayName : "Date",
  },
  {
    displayName : "Time",
    id: "time",
  
  }
];

//defined all the columns and fetched the data for every cell.
const columns = [

  
  {

    Header: props => <span className="form-textHead cell-head">Id</span>,
    accessor: d => {return {mailId:d.mailId,id:d.id,docType:d.docType}},
   
    filterMethod: (filter, row) =>
    { debugger; return row.mailId.mailId.toLowerCase().includes(filter.value.toLowerCase()) }
    ,
              
                
    id:"mailId",
    Cell: props => 
    <Link className="link" to={"../../view/page/"+props.value.docType+"/"+props.value.id}><span>{props.value.mailId}</span></Link>,
    minWidth:180
    },
  {

    Header: props => <span className="form-textHead cell-head">Assigned To</span>,
    accessor: d => {return {assignmentId:d.assignmentId,id:d.queryId}},
    filterMethod: (filter, row) =>
             { return row.assignmentId.assignmentId ? row.assignmentId.assignmentId.toLowerCase().includes(filter.value.toLowerCase) : false}
                     
                  ,
                
    id:"assignmentId",
    Cell: props => 
    <span className='form-text cell'>{props.value.assignmentId=='NA' ? props.value.assignmentId:
    <Link className="link" to={"/assignment/view-assignment/"+props.value.id}><span>{props.value.assignmentId}</span></Link>}</span> ,
    minWidth:180
    },

{
  Header: props => <span className="form-textHead cell-head">Sent To</span>,
  accessor: 'sendTo',
  filterMethod: (filter, row) =>
             { debugger; return row.sendTo.find(item=>item.email.toLowerCase().includes(filter.value.toLowerCase())); }
                     ,
          
Cell: props => <span className='form-text cell'>{typeof props.value =="object" ? (props.value!=undefined ? props.value.map(item=>item.email) : 'NA') : props.value  }</span>,
minWidth:240
},
{
  Header: props => <span className="form-textHead cell-head">Client</span>,
  accessor: 'client',
  filterMethod: (filter, row) =>
             { return row.client.toLowerCase().includes(filter.value.toLowerCase()); },
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:240
},
{
  Header: (props) => <span className="form-textHead cell-head">Organisation</span>,
  accessor: (d) => {
    return { name: d.selectedOrganisation ?  d.selectedOrganisation?.name : d.organisation.name };
  },
  filterMethod: (filter, row) => {
    return row.selectedOrganisation?.name.toLowerCase().includes(filter.value.toLowerCase());
  },
  id: "selectedOrganisation",
  Cell: (props) => (
    <span className="form-text cell">
      {props.value != undefined ? props.value.name : "-"}
    </span>
  ),
  minWidth: 170,
},
{
  Header: props => <span className="form-textHead cell-head">Subject</span>,
  accessor: 'subject',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:240
},
{
  Header: props => <span className="form-textHead cell-head">Body</span>,
  accessor: 'message',
  Cell: props => <span className='form-text cell' dangerouslySetInnerHTML={{__html:props.value}}></span>,
  minWidth:240
},
{

  Header: props => <span className="form-textHead cell-head">Attachments</span>,
  accessor: d => {return {id:d.id,attachment:d.attachment,docType:d.docType}},
  filterMethod: (filter, row) =>
           { return true}
                   
                ,
              
  id:"attachment",
  Cell: props => <span className='form-text cell'>{!props.value.attachment ? 'Not available':
  <Link className="link" to={"../../view/page/"+props.value.docType+"/"+props.value.id}><span>View Attachment</span></Link>}</span> ,
  minWidth:180
  },
{
  Header: props => <span className="form-textHead cell-head">By</span>,
  accessor: 'by',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
 
},{
  Header: (props) => (
    <span className="form-textHead cell-head">Date of Creation</span>
  ),
  accessor: (d) => {
    return { date: d.date, id: d.id };
  },
  filterMethod: (filter, row) => {
    return new Date(row.date.date).toDateString().toLowerCase().includes(filter.value.toLowerCase());
  },

  id: "date",
  Cell: (props) => new Date(props.value.date).toDateString(),
  minWidth: 180,
},
{
  Header: (props) => <span className="form-textHead cell-head">Tags</span>,
  accessor: "tags",
  id: "tags",
  Cell: (props) => (
    <span className="form-text cell">
      {props.value &&
        props.value.map((member) => {
          return (
            <span>
              {member.label} &nbsp;
            </span>
          );
        })}
    </span>
  ),
  minWidth: 170,
},
{
  Header: (props) => (
    <span className="form-textHead cell-head">Time of Creation</span>
  ),
  accessor: (d) => {
    return { date: d.date, id: d.id };
  },
  filterMethod: (filter, row) => {
    return new Date(row.date.date).toLocaleTimeString().toLowerCase().includes(filter.value.toLowerCase());
  },

  id: "time",
  Cell: (props) => new Date(props.value.date).toLocaleTimeString(),
  minWidth: 280,
},



]
export default class viewMail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      menu1:[
       



 ]
 
    };
    context = this;
  }
  componentDidMount(){

  let mailType = this.props.match.params.mailType;
  let mode =  this.props.match.params.mode;
  //mailType we get is "mail" but we want to capitalize first letter i.e Mail or Update or Document
  
  mailType = mailType.charAt(0).toUpperCase() + mailType.slice(1);
  if(mailType=="Messages"){
    mailType="Mail";
  }
 
  this.fetchQueries(this,mailType,mode)

}

generateExcelData = data => {
    
  let excelData = [];
  _.each(data, function(d) {

    
    let row = {
      mailId: d.mailId,
      assignmentId:d.assignmentId,
      email:d.email,
      client:d.client,
      name:d.name,
      status:d.status,
      subject:d.subject,
      sendTo:d.sendTo,
      
      docType:d.docType,
      message:d.message.replace(/<[^>]*>/g, ''),
      /*
     assignmentId: "NA"
client: "Seema Gautam"
date: 1585022908250
docType: "Mail"
email: "sss"
from: "admin"
id: "b862c4a0-6d85-11ea-ad20-1f382e88a1bf"
mailId: "MSG-0002"
message: "<p>sadsad</p>"
name: "Seema Gautam"
queryId: "0"
recordType: "MAIL"
sendTo: [{email: "sss", name: "Seema Gautam"}]
sendTo: [{email: "sss", name: "Seema Gautam"}]
status: "NEW"
subject: "asdsad"
type: "RED"
      */


     
      date: new Date(d.date).toDateString(),
      time: new Date(d.date).toLocaleTimeString()
    };
     
    excelData.push(row);
    console.log(excelData);
  });
  this.setState({ excelData });
};

 getCommaSeparatedTeamName=(teamMember)=>{
  console.log(teamMember)
 let names= _.map(teamMember, function(d){
 return d.name;
 })
 console.log(names)
 return names.join(";")
 }





componentWillReceiveProps(nextProps){


  if(this.props!=nextProps){

    this.setState({menu1:[]});
  let mailType = nextProps.match.params.mailType;
  let mode = nextProps.match.params.mode;
  //mailType we get is "mail" but we want to capitalize first letter i.e Mail or Update or Document
  mailType = mailType.charAt(0).toUpperCase() + mailType.slice(1);

  this.fetchQueries(this,mailType,mode)
  }

}


  toggleOpen = id => {
    var list  =this.state.menu1;
    var index = _.findIndex(list,function(entry){return entry.id==id});
    
    list[index].status =  (list[index].status == "open" || list[index].status==undefined) ? "close" : 'open';
    
    var newList = JSON.parse(JSON.stringify( list ));
    context.setState({menu1:newList});
    context.updateStatus(context,id,list[index].status);
    
    
    
      };

  
  fetchQueries(that,mailType,mode){

    let paramEmail = localStorage.userEmail;
    if(localStorage.isSp=='true'){
      paramEmail='all'
    }
    axios.get(apiBaseUrl2+"businusquery/listMailByType/all/"+mailType+"/"+localStorage.spId+"/"+paramEmail)
    .then(function (response) { 
      console.log(response.data);
    that.setState({threads:_.sortBy(response.data,"date")});
    var recievedMail = response.data;
    debugger;
    if(mode!="sent"){
      recievedMail = _.filter(response.data,(mail)=>{
        return mail.from=="client";
    console.log(recievedMail);
    
        }
         );
    }
    else{
      recievedMail = _.filter(response.data,(mail)=>{
        return mail.by==localStorage.userName;
    
    
        }
         );
    }
 
    that.setState({ menu1: recievedMail });
    that.generateExcelData(recievedMail);
  })
  .catch(function (error) {
    console.log(error);
  });

}

  

  render() {
    return (
      <div>
       
      
      <div className="Div">
      <div className="row breadcrumb-row">
          <div className="col-md-10"><BreadCrumbs/></div>
          <div className="col-md-2">
            <div className=" download-button">
            <CsvDownloader
                  text="Download in .csv format"
                  datas={this.state.excelData}
                  filename="recievedClientMails"
                  separator=","
                  wrapColumnChar=""
                  columns={columnsForExcel}
                />
                </div>
      </div>
      </div>
            <div className="row">
          
              <Row>
                <Col>
                  <div className="card">

                  <ReactTable className="myTable" resolveData={data => data.map(row => row)}  data={this.state.menu1} columns={columns} filterable />
                  


                    
                       
                      
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          </div>
    );
  }
}
