import React, { Component } from "react";
import reactCSS from "reactcss";
import "../../style/agency.css";
import axios from "axios";
import $ from "jquery";
import { apiBaseUrl, baseFilePath } from "../apiUtils";
import { SketchPicker } from "react-color";
import "../../animations.css";
import FileBase64 from "react-file-base64";
let context = null;

/**
 * Banner modal component
 * this is the code of modal which takes in the data and style of banner elements.
 * @module Banner-Modal
 */
class bannerImageModal extends Component {
  constructor(props) {
    super();
    this.state = {
      bannerImageUrl: "",
      websiteTitle: "",
      subTitle: "",
      fileName: "",
      files: [],
      btnText: "",
      btnLink: "",
      btnColor: "",
      titleColor: "",
      bannerHeight: "",
      subtitleColor: "",
      btnBackgroundColor: "",
      btnHighlightColor: "",
      bannerMargin: "",
      bannerPadding: "",
      displayColorPicker: false,
      displayBgColorPicker: false,
    };

    context = this;
    this.saveBannerImage = this.saveBannerImage.bind(this);
    this.uploadApi = this.uploadApi.bind(this);

    this.hideModal = this.hideModal.bind(this);
    this.toggleBannerImageModal = this.toggleBannerImageModal.bind(this);
  }

  uploadApi() {
    var data = {
      image: this.state.files.base64,
      fileName: this.state.files.name,
    };
    axios
      .post(apiBaseUrl + "/uploadImage", data)
      .then(function (response) {
        context.setState({ bannerImageUrl: response.data.Location });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getFiles(files) {
    this.setState({ files: files });
    this.uploadApi();
  }

  componentDidMount() {
    var bannerImage = this.props.bannerImageUrl;
    this.setState({ bannerImageUrl: bannerImage });
    this.setState({ websiteTitle: this.props.websiteTitle });
    this.setState({ subTitle: this.props.subTitle });
    this.setState({ btnBackgroundColor: this.props.btnBackgroundColor });
    this.setState({ btnHighlightColor: this.props.btnHighlightColor });
    this.setState({ btnColor: this.props.btnColor });
    this.setState({ titleColor: this.props.titleColor });
    this.setState({ subtitleColor: this.props.subtitleColor });
    this.setState({ newImageUrl: this.props.newImageUrl });
    this.setState({ btnLink: this.props.btnLink });
    this.setState({ btnText: this.props.btnText });
    this.setState({ bannerHeight: this.props.bannerHeight });
    this.setState({ bannerMargin: this.props.bannerMargin });
    this.setState({ bannerPadding: this.props.bannerPadding });
  }

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);

    console.log(this.state);
  };

  toggleBannerImageModal() {
    var status = !this.state.toggleBannerImageModal;
    this.setState({ toggleBannerImageModal: status });
  }

  /**
   * saveBannerImage
   * This is the segment of code used to save banner elements.
   * @function saveBannerImage
   */
  saveBannerImage() {
    this.props.saveBannerImage(this.state.bannerImageUrl);
    this.props.saveWebsiteInfo(
      this.state.websiteTitle,
      this.state.subTitle,
      this.state.titleColor,
      this.state.subtitleColor,
      this.state.bannerHeight,
      this.state.bannerMargin,
      this.state.bannerPadding
    );
    this.props.saveBtnInfo(this.state);
    this.props.toggleBannerImageModal();
  }

  hideModal(key) {
    this.props.hideModal(key);
  }

  toggleColorPicker = (colorPicker) => {
    if (colorPicker == "title") {
      this.setState({
        displayTitleColorPicker: !this.state.displayTitleColorPicker,
      });
    } else if (colorPicker == "subtitle") {
      this.setState({
        displaySubtitleColorPicker: !this.state.displaySubtitleColorPicker,
      });
    } else if (colorPicker == "bg") {
      this.setState({ displayBgColorPicker: !this.state.displayBgColorPicker });
    } else if (colorPicker == "highlight") {
      this.setState({
        displayHighlightColorPicker: !this.state.displayHighlightColorPicker,
      });
    } else {
      this.setState({ displayColorPicker: !this.state.displayColorPicker });
    }
  };

  toggleBannerImageModal() {
    var status = !this.state.toggleBannerImageModal;
    this.setState({ toggleBannerImageModal: status });
  }

  handleTitleComplete = (color) => {
    this.setState({ titleColor: color.hex });
  };
  handleSubtitleComplete = (color) => {
    this.setState({ subtitleColor: color.hex });
  };

  handleChangeComplete = (color) => {
    this.setState({ btnBackgroundColor: color.hex });
  };
  handleChangeHighlightComplete = (color) => {
    this.setState({ btnHighlightColor: color.hex });
  };
  handleChangeColorComplete = (color) => {
    this.setState({ btnColor: color.hex });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
    this.setState({ displayHighlightColorPicker: false });
    this.setState({ displayBgColorPicker: false });
    this.setState({ displayTitleColorPicker: false });
    this.setState({ displaySubtitleColorPicker: false });
  };

  render() {
    const styles = reactCSS({
      default: {
        color4: {
          width: "100%",
          height: "14px",
          borderRadius: "2px",
          background: `${this.state.titleColor}`,
        },
        color5: {
          width: "100%",
          height: "14px",
          borderRadius: "2px",
          background: `${this.state.subtitleColor}`,
        },
        color: {
          width: "100%",
          height: "14px",
          borderRadius: "2px",
          background: `${this.state.btnBackgroundColor}`,
        },
        color2: {
          width: "100%",
          height: "14px",
          borderRadius: "2px",
          background: `${this.state.btnColor}`,
        },
        color3: {
          width: "100%",
          height: "14px",
          borderRadius: "2px",
          background: `${this.state.btnHighlightColor}`,
        },

        swatch: {
          width:"100%",
          padding: "5px",
          background: "#fff",
          borderRadius: "1px",
          border: "1px solid rgb(239 239 239)",
          display: "inline-block",
          cursor: "pointer",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });

    return this.state.hideModal ? (
      <div></div>
    ) : (
      <div className="modal-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <div id="modalS">
                <form>
                  <div
                    id="contact-form"
                    className="form-container"
                    data-form-container
                    style={{ color: "rgb(46, 125, 50)", background: "white" }}
                  >
                     <div className="row">
                    <div className="modal-head">
                      <div
                        className="close-button"
                        onClick={this.props.toggleBannerImageModal}
                      >
                        <h5 className="close-icon">X</h5>
                      </div>
                    </div>
                  </div>

                  <div className="input-container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="div-wrapper">
                          <h5 className="modal-title"> Edit Banner Properties </h5>
                        </div>
                          <form id="field">
                            <div className="row">
                              <div className="col-md-6 form-columns">
                                <div className="row">
                                  <div className="col-md-5">
                                    <h5 >
                                      Banner Image Url:{" "}
                                    </h5>{" "}
                                  </div>
                                  <div className="col-md-7 ">
                                  <div className="flex-col-wrapper-10">

                               
                                   
                                      <input
                                        className="fieldbox"
                                        onChange={this.onChange}
                                        type="text"
                                        value={this.state.bannerImageUrl}
                                        name="bannerImageUrl"
                                      />
                                      <FileBase64
                                        multiple={false}
                                        onDone={this.getFiles.bind(this)}
                                      />
                                   
                                   </div>
                                
                                  </div>{" "}
                                </div>

                                <div className="row">
                                  <div className="col-md-5">
                                    {" "}
                                    <h5 >
                                      Website Title:
                                    </h5>{" "}
                                  </div>
                                  <div className="col-md-7">
                                
                                      <input
                                        className="fieldbox"
                                        onChange={this.onChange}
                                        type="text"
                                        value={this.state.websiteTitle}
                                        name="websiteTitle"
                                      />
                                  
                                  </div>
                                
                                </div>

                                <div className="row ">
                                  <div className="col-md-5">
                                    <h5 >
                                      Title Color:{" "}
                                    </h5>
                                  </div>
                                  <div className="col-md-7 ">
                                    <div
                                      style={styles.swatch}
                                      onClick={() =>
                                        this.toggleColorPicker("title")
                                      }
                                    >
                                      <div style={styles.color4} />
                                    </div>
                                    {this.state.displayTitleColorPicker ? (
                                      <div style={styles.popover}>
                                        <div
                                          style={styles.cover}
                                          onClick={this.handleClose}
                                        />
                                        <SketchPicker
                                          color={this.state.titleColor}
                                          onChangeComplete={
                                            this.handleTitleComplete
                                          }
                                        />
                                        <br />
                                      </div>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-5">
                                    {" "}
                                    <h5 >Subtitle: </h5>
                                  </div>
                                  <div className="col-md-7 ">
                                    <input
                                      className="fieldbox"
                                      onChange={this.onChange}
                                      type="text"
                                      value={this.state.subTitle}
                                      name="subTitle"
                                    />
                                  </div>
                               
                                </div>

                                <div className="row ">
                                  <div className="col-md-5">
                                    <h5 >
                                      Subtitle Color:{" "}
                                    </h5>{" "}
                                  </div>{" "}
                                  <div className="col-md-7 ">
                                    {" "}
                                    <div
                                      style={styles.swatch}
                                      onClick={() =>
                                        this.toggleColorPicker("subtitle")
                                      }
                                    >
                                      <div style={styles.color5} />
                                    </div>
                                    {this.state.displaySubtitleColorPicker ? (
                                      <div style={styles.popover}>
                                        <div
                                          style={styles.cover}
                                          onClick={this.handleClose}
                                        />
                                        <SketchPicker
                                          color={this.state.subtitleColor}
                                          onChangeComplete={
                                            this.handleSubtitleComplete
                                          }
                                        />
                                      
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="row ">
                                  <div className="col-md-5">
                                    <h5>
                                      Button Color:{" "}
                                    </h5>
                                  </div>{" "}
                                  <div className="col-md-7 ">
                                    {" "}
                                    <div
                                      style={styles.swatch}
                                      onClick={() =>
                                        this.toggleColorPicker("bg")
                                      }
                                    >
                                      <div style={styles.color} />
                                    </div>
                                    {this.state.displayBgColorPicker ? (
                                      <div style={styles.popover}>
                                        <div
                                          style={styles.cover}
                                          onClick={this.handleClose}
                                        />
                                        <SketchPicker
                                          color={this.state.btnBackgroundColor}
                                          onChangeComplete={
                                            this.handleChangeComplete
                                          }
                                        />
                                      
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-5">
                                    {" "}
                                    <h5 >
                                      Button Text:{" "}
                                    </h5>
                                  </div>
                                  <div className="col-md-7">
                                    {" "}
                                    <input
                                      className="fieldbox"
                                      onChange={this.onChange}
                                      type="text"
                                      value={this.state.btnText}
                                      name="btnText"
                                    />
                                  </div>
                               
                                </div>
                              </div>

                              <div className="col-md-6 form-columns">
                                <div className="row ">
                                  <div className="col-md-5">
                                    <h5 >
                                      Button Highlight:{" "}
                                    </h5>{" "}
                                  </div>{" "}
                                  <div className="col-md-7">
                                    {" "}
                                    <div
                                      style={styles.swatch}
                                      onClick={() =>
                                        this.toggleColorPicker("highlight")
                                      }
                                    >
                                      <div style={styles.color3} />
                                    </div>
                                    {this.state.displayHighlightColorPicker ? (
                                      <div style={styles.popover}>
                                        <div
                                          style={styles.cover}
                                          onClick={this.handleClose}
                                        />
                                        <SketchPicker
                                          color={this.state.btnHighlightColor}
                                          onChangeComplete={
                                            this.handleChangeHighlightComplete
                                          }
                                        />
                                       
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="row ">
                                  <div className="col-md-5">
                                    <h5 >
                                      Button Text Color:{" "}
                                    </h5>
                                  </div>{" "}
                                  <div className="col-md-7 ">
                                    {" "}
                                    <div
                                      style={styles.swatch}
                                      onClick={() =>
                                        this.toggleColorPicker("color")
                                      }
                                    >
                                      <div style={styles.color2} />
                                    </div>
                                    {this.state.displayColorPicker ? (
                                      <div style={styles.popover}>
                                        <div
                                          style={styles.cover}
                                          onClick={this.handleClose}
                                        />
                                        <SketchPicker
                                          color={this.state.btnColor}
                                          onChangeComplete={
                                            this.handleChangeColorComplete
                                          }
                                        />
                                      
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              

                                <div className="row">
                                  <div className="col-md-5">
                                  
                                    <h5 >
                                      Height:{" "}
                                    </h5>{" "}
                                    <p className="subscriptt">(Banner)</p>
                                  </div>{" "}
                                  <div className="col-md-7 ">
                                    <input
                                      className="fieldbox"
                                      onChange={this.onChange}
                                      type="text"
                                      value={this.state.bannerHeight}
                                      name="bannerHeight"
                                    />
                                   
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-5">
                                   
                                    <h5 >
                                      Margin:{" "}
                                    </h5>{" "}
                                    <p className="subscriptt">
                                      (Top Right Bottom Left)
                                    </p>
                                  </div>{" "}
                                  <div className="col-md-7">
                                    <input
                                      className="fieldbox"
                                      onChange={this.onChange}
                                      type="text"
                                      value={this.state.bannerMargin}
                                      name="bannerMargin"
                                    />
                                 
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-5">
                                   
                                    <h5 >
                                      Padding:{" "}
                                    </h5>{" "}
                                    <p className="subscriptt">
                                      (Top Right Bottom Left)
                                    </p>
                                  </div>{" "}
                                  <div className="col-md-7">
                                    <input
                                      className="fieldbox"
                                      onChange={this.onChange}
                                      type="text"
                                      value={this.state.bannerPadding}
                                      name="bannerPadding"
                                    />
                                  
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-5">
                                    <h5 >Link to: </h5>
                                  </div>
                                  <div className="col-md-7 ">
                                    <select
                                      name="btnLink"
                                      onChange={this.onChange}
                                      value={this.state.btnLink}
                                    >
                                      {this.props.sections.map((prod, i) => (
                                        <option
                                          value={prod.title.replace(/\s/g, "")}
                                        >
                                          {prod.title}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <br />
                                </div>
                              </div>
                            </div>

                            {/* <button type="button"   className="imagebutton"> Save </button>*/}
                          </form>
                        </div>
                      </div>
                      <div className="row div-wrapper">
                        <div className="col-md-2"></div>
                        <div className="col-md-4">
                          <button
                            type="button"
                            onClick={() => this.saveBannerImage()}
                            className="btn btn-block savebutton submit-form valid"
                          >
                            Save
                          </button>
                        </div>
                        <div className="col-md-4">
                          <button
                            type="button"
                            onClick={() => this.props.toggleBannerImageModal()}
                            className="btn btn-block cancelbutton submit-form valid"
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default bannerImageModal;
