import React, { Component } from "react";
import CsvDownloader from "react-csv-downloader";
import BreadCrumbs from "../../BreadCrumbs";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiBaseUrl1,apiBaseUrl5, apiIssueMaster, apiLogsMaster } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import ReactTable from "react-table";
import $ from "jquery";
import _, { template } from "underscore";
import ReactExport from "react-export-excel";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

const data = [];
let statusList = [];
let progressStatusList = [];
let context = "null";
let handleOnSelect = null;

const colForAttach = [{
  id:"attachment",
  label:"attachment",

},{  id:"name",
label:"Name"},{  id:"description",
label:"description"}]


const colForUrl = [{
  id:"urlTxt",
  label:"Url Link"},{
    id:"description",
    label:"description",
  },{  id:"urlTitle",
  label:"url"}
];




const colForComment = [{
  id:"comment",
  label:"comment"},
  {  id:"by",
  label:"by"}

]




const colForDoc = [{
  id:"title",
  label:"title"},{

    id:"description",
    label:"description"
  },
  {

    id:"docId",
    label:"docId"
  },
  {

    id:"name",
    label:"name"
  },
 
]


//defined all the columns and fetched the data for every cell.
const colformail = [{
  id:"subject",
  label:"subject"},
  {id:"message",
  label:"message"},
    {
      id:"attachment",
      label:"attachment"},
      {
        id:"from",
        label:"from"},
        {
          id:"mailId",
          label:"mailId"},
          {
            id:"client",
            label:"client"},
            {
              id:"assignmentId",
              label:"assignmentId"}]
const columnsForExcel = [
  {
    id: 'assignmentId',
    displayName : "Assingment ID",

  },
  {
    displayName : "Name",

    id: "name"
  },
  {
    displayName : "Status",
    id: "status",
   
  },
  {
    displayName : "Payment Status",
    id: "paymentStatus",
   
  },
  {
    displayName : "Start Date",
    id: "startDate",
   
  },
  {
    displayName : "End Date",
    id: "endDate",
 
  },

  {
    displayName : "Overview",
    id: "assignmentOverview",
   
  },

  {
    displayName : "Priority",
    id: "priority",
   
  },
  {
    displayName : "Client",
    id: "selectedClient",
   
  },
  {
    displayName : "Organisation",
    id: "selectedOrganisation",

  },
  {
    displayName : "Team Lead",
    id: "teamLead",
 
  },
  {
    displayName : "Team",
   
    id: "teamMembers",
   
  },
  {
    displayName : "Template",
   
    id: "selectedTemplate",
   
  },
  {
    displayName : "Recurrance Frequency",
   
    id: "recurringFrequency",
   
  },
  {
    displayName : "Priority",
   
    id: "priority",
   
  },
  {
    displayName : "By",
   
    id: "by",
   
  },
  {
    displayName : "Date",
    id: "date",
    
  },
  {
    displayName : "Time",
    id: "time",
  
  }
];
const columns = [
  {
    Header: props => (
      <span className="form-textHead cell-head">ID</span>
    ),
    accessor: "logId",
    id: "logId",
    Cell: props => <span className="form-text cell">{props.value}</span>,
    minWidth: 170
  },
  {

    Header: props => <span className="form-textHead cell-head">Assigned To</span>,
    accessor: d => {return {assignmentId:d.assignmentId,id:d.queryId}},
    filterMethod: (filter, row) =>
             { return row.name.name.toLowerCase().includes(filter.value.toLowerCase()) }
                     
                  ,
                
    id:"assignmentId",
    Cell: props => 
    <span className='form-text cell'>{props.value.assignmentId==undefined ? "NA" : props.value.assignmentId=='NA' ? props.value.assignmentId:
    <Link className="link" to={"/assignment/view-assignment/"+props.value.id}><span>{props.value.assignmentId}</span></Link>}</span> ,
    minWidth:180
    },
  {
    Header: props => <span className="form-textHead cell-head">Title</span>,
    accessor: d => {
      return { title: d.title, id: d.id };
    },
    filterMethod: (filter, row) => {
      return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase());
    },

    id: "name",
    Cell: props => (
      <Link className="link" to={"./view-expenses/" + props.value.id}>
        {props.value.title}
      </Link>
    ),
    minWidth: 180
  },
  {
    Header: props => (
      <span className="form-textHead cell-head">Date</span>
    ),
    accessor: "dueDate",
    id: "startDate",
    Cell: props => <span className="form-text cell">{props.value}</span>,
    minWidth: 170
  },
  {
    Header: props => (
      <span className="form-textHead cell-head"> Overview</span>
    ),
    accessor: "overview",
    id: "assignmentOverview",
    Cell: props => <span className="form-text cell" style={{height:'140px !important', overflowY:"scroll !important"}} >{context.removeHtmlTags(props.value)}</span>,
    minWidth: 170
  },
  {
    Header: props => <span className="form-textHead cell-head">Team</span>,
    accessor: d => {
      return { title: d.assignmentTitle, id: d.id };
    },
    filterMethod: (filter, row) => {
      return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase());
    },

    id: "teamMembers",
    Cell: props => (
      <Link className="link" to={"./view-expenses/" + props.value.id}>
        View Team
      </Link>
    ),
    minWidth: 180
  },
//   {
//     Header: props => (
//       <span className="form-textHead cell-head">Status</span>
//     ),
//     id: "status",
//     accessor: d => {
//       return {
//         title: d.title,
//         id: d.id
//       };
//     },
//     Cell: props => (
//       <select
//         onChange={e => handleOnSelect(e, props.value)}
//         name="status"
//         value={props.value.status}
//       >
//       {statusList.map((value)=>{

// return <option>{value.title}</option>
// })}
  //     </select>
  //   ),
  //   minWidth: 200
  // },
  
  {
    Header: props => <span className="form-textHead cell-head">Category</span>,
    accessor: d => {
      return { name: d.selectedCategory.title };
    },
    id: "selectedCategory",
    Cell: props => <span className="form-text cell">{props.value!=undefined ? props.value.name : "-"}</span>,
    minWidth: 170
  },
  {
    Header: props => <span className="form-textHead cell-head">Amount</span>,
    accessor: "amount",
    id: "amount",
    Cell: props => <span className="form-text cell">{props.value!=undefined ? props.value : "-"}</span>,
    minWidth: 170
  },
  {
    Header: props => <span className="form-textHead cell-head">Rate</span>,
    accessor: "rate",
    id: "rate",
    Cell: props => <span className="form-text cell">{props.value!=undefined ? props.value : "-"}</span>,
    minWidth: 170
  },
  {
    Header: props => <span className="form-textHead cell-head">Quantity</span>,
    accessor: "quantity",
    id: "quantity",
    Cell: props => <span className="form-text cell">{props.value!=undefined ? props.value : "-"}</span>,
    minWidth: 170
  },
  {
    Header: props => <span className="form-textHead cell-head">Client</span>,
    accessor: d => {
      return { name: d.selectedClient.name, id: d.selectedClient.id };
    },
    filterMethod: (filter, row) => {
      return row.name.selectedClient.toLowerCase().includes(filter.value.toLowerCase());
    },
    id: "selectedClient",
    Cell: props => <span className="form-text cell">{props.value != undefined ? props.value.name : "-" }</span>,
    minWidth: 170
  },
  {
    Header: (props) => <span className="form-textHead cell-head">Organisation</span>,
    accessor: (d) => {
      return { name: d.selectedOrganisation ?  d.selectedOrganisation.name : '' };
    },
    filterMethod: (filter, row) => {
      return row.selectedOrganisation.name.toLowerCase().includes(filter.value.toLowerCase());
    },
    id: "selectedOrganisation",
    Cell: (props) => (
      <span className="form-text cell">
        {props.value != undefined ? props.value.name : "-"}
      </span>
    ),
    minWidth: 170,
  },
  
  {
    Header: props => <span className="form-textHead cell-head">Due date</span>,
    accessor: "dueDate",
    id: "dueDate",
    Cell: props => <span className="form-text cell">{props.value}</span>,
    minWidth: 170
  },
  {
    Header: props => <span className="form-textHead cell-head">By</span>,
    accessor: "by",
    id: "by",
    Cell: props => <span className="form-text cell">{props.value}</span>,
    minWidth: 170
  },
  
  {
    Header: props => (
      <span className="form-textHead cell-head">Date of Creation</span>
    ),
    accessor: d => {
      return { date: d.date, id: d.id };
    },
    filterMethod: (filter, row) => {
      return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase());
    },

    id: "date",
    Cell: props => new Date(props.value.date).toDateString(),
    minWidth: 180
  },
  {
    Header: props => (
      <span className="form-textHead cell-head">Time of Creation</span>
    ),
    accessor: d => {
      return { date: d.date, id: d.id };
    },
    filterMethod: (filter, row) => {
      return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase());
    },

    id: "time",
    Cell: props => new Date(props.value.date).toLocaleTimeString(),
    minWidth: 280
  }
];



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
export default class viewExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attachments:[],
      urls:[],
      docs:[],
      comments:[],
      opened: [],
      menu1: [],
      excelData: []
    };
    context = this;
  }
  componentDidMount() {
    handleOnSelect = this.handleOnSelect;
    this.fetchQueries(this);
    this.fetchAllMails(this);
    this.fetchAllReources(this);
  }

  handleOnSelect = (e, query) => {
    var name = e.target.name;
    var selectedValue = e.target.value;
    var updatedData = {
      status: query.status != undefined ? query.status : "open",
      paymentStatus:
        selectedValue != undefined ? selectedValue : "Invoice Pending",
      checklists: query.checklists != undefined ? query.checklists : []
    };
    if (name == "status") {
      updatedData = {
        status: selectedValue != undefined ? selectedValue : "open",
        paymentStatus:
          query.paymentStatus != undefined
            ? query.paymentStatus
            : "Invoice Pending",
        checklists: query.checklists != undefined ? query.checklists : []
      };
    }

    var list = this.state.menu1;
    var index = _.findIndex(list, function(entry) {
      return entry.id == query.id;
    });

    if (name == "status") {
      list[index].status = selectedValue;
    } else {
      list[index].paymentStatus = selectedValue;
    }

    var newList = JSON.parse(JSON.stringify(list));
    context.setState({ menu1: newList });

    axios
      .post(
       apiBaseUrl1+"businus/project/update/" +
          query.id,
        updatedData
      )
      .then(function(response) {
        debugger;
      })
      .catch(function(error) {
        console.log(error);
      });
  };




 





  fetchAllMails(that){

  axios.get(apiBaseUrl5 +"businus/allMails/"+ localStorage.getItem("employeeId"))
    .then(function (response) {
      console.log(response.data);
      debugger;
      var threads = response.data=="" ? [] : _.sortBy(response.data,'date').reverse();

      threads =_.map(threads,function(t){

          if(typeof t.attachment == 'object'){

              t.attachment = context.getCommaSeparatedAttachment(t.attachment);


          }
          t.message = t.message.replace(/<[^>]*>/g, '');
          return t;






      });
     

      var documents = _.where(threads,{docType:"Document"});
      var proposals = _.where(threads,{docType:"Proposal"});
      var mails = _.where(threads,{docType:"Mail"});
      var teamMails = _.where(threads,{docType:"TEAM-MAILS"});
      var teamDocs = _.where(threads,{docType:"TEAM-DOCS"});
      that.setState({documents,teamDocs,teamMails});
      that.setState({proposals});
     that.setState({mails});

    })
    .catch(function (error) {
      console.log(error);
    });
  
  }


  
  fetchAllReources(that){

    axios.get(apiBaseUrl5 +"businus/listAllResources/"+ localStorage.getItem("employeeId"))
    .then(function (response) {
    


    let attachments = [];
    let docs = [];
    let urls = [];
    let comments = [];
    

    _.each(response.data,function(d){
       
        
    if(d.attachment)
      $.merge(attachments, d.attachment);
      if(d.doc)
      $.merge(docs, d.doc);
      if(d.url)
      $.merge(urls, d.url);
      if(d.comment)
      $.merge(comments,d.comment);



    })

   



    debugger;
      that.setState({attachments,docs,urls,comments});


    })
    .catch(function (error) {
      debugger;
      console.log(error);
    });
  
  }

  getFormattedDate(millis) {
    return new Date(millis).toDateString();
  }

  removeHtmlTags(text) {
    let newText= text.replace(/<[^>]+>/g, '');
    console.log(newText);
    return newText;
  }

  generateExcelData = data => {
    
    let excelData = [];
    _.each(data, function(d) {

      
      let row = {
        assignmentId: d.assignmentId,
        name: d.assignmentTitle,
        status: d.status,
        paymentStatus: d.paymentStatus,
        startDate: d.startDate,
        endDate:d.endDate,
        assignmentOverview: d.assignmentOverview.replace(/<[^>]*>/g, ''),
        selectedClient: d.selectedClient.name,
        selectedOrganisation: d.selectedOrganisation.name,
        teamLead: d.teamLead.name,
        teamMembers: context.getCommaSeparatedTeamName(d.team),
        selectedTemplate:d.selectedTemplate,
        recurringFrequency:d.recurringFrequency,
        priority:d.priority,
        by:d.by,
        date: new Date(d.date).toDateString(),
        time: new Date(d.date).toLocaleTimeString()
      };
       
      excelData.push(row);
      console.log(excelData);
    });
    this.setState({ excelData });
  };

   getCommaSeparatedTeamName=(teamMember)=>{
    console.log(teamMember)
   let names= _.map(teamMember, function(d){
   return d.name;
   })
   console.log(names)
   return names.join(";")
   }


   getCommaSeparatedAttachment = (attachments)=>{
    
   let names= _.map(attachments, function(d){
   return d.attachment;
   })
   console.log(names)
   return names.join(";")
   }




   fetchStatus(that,status){

    axios.get(apiIssueMaster +"businusquery/issue/master/EXPENSE")
    .then(function (response) {
      console.log(response.data);
  
    
        that.setState({statusList:response.data});
        statusList = response.data;
    
      that.fetchQueries(that, that.props.source);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }


  toggleOpen = id => {
    var list = this.state.menu1;
    var index = _.findIndex(list, function(entry) {
      return entry.id == id;
    });

    list[index].status =
      list[index].status == "open" || list[index].status == undefined
        ? "close"
        : "open";

    var newList = JSON.parse(JSON.stringify(list));
    context.setState({ menu1: newList });
    context.updateStatus(context, id, list[index].status);
  };

  fetchQueries(that, source) {
    axios
      .get(
       apiLogsMaster+"businusquery/Log-list/Expense/"+localStorage.spId
      )
      .then(function(response) {
        console.log(response.data);
        that.setState({ menu1: _.sortBy(response.data, "issueId") });
       // that.generateExcelData(response.data);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  render() {
    return (
      <div>
        <div className="Div">
          <div className="row breadcrumb-row">
            <div className="col-md-10">
              <BreadCrumbs />
            </div>
            <div className="col-md-2">
              <div className=" ">
             {/*    <CsvDownloader
                  text="Download in .csv format"
                  datas={this.state.excelData}
                  filename="assignment"
                  separator=","
                  wrapColumnChar=""
                  columns={columnsForExcel}
                /> */}
              <ExcelFile element={<button className="download-button-wrapper">Download Data in Excel format</button>}>
                <ExcelSheet data={this.state.excelData} name="Assignments">

                  {columnsForExcel.map(d=>  <ExcelColumn label={d.displayName} value={d.id}/>)}
                   
                   
                </ExcelSheet>

                <ExcelSheet data={this.state.mails} name="Mails"> 
                {colformail.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}
                   
              
                </ExcelSheet>
                 <ExcelSheet data={this.state.documents} name="Documents"> 
                {colformail.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}</ExcelSheet>
                <ExcelSheet data={this.state.proposals} name="Proposals"> 
                {colformail.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}</ExcelSheet> 
                <ExcelSheet data={this.state.teamMails} name="Team Mails"> 
                {colformail.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}</ExcelSheet> 
                <ExcelSheet data={this.state.teamDocs} name="Team Docs"> 
                {colformail.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}</ExcelSheet> 

                <ExcelSheet data={this.state.attachments} name="Attachments"> 
                {colForAttach.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}</ExcelSheet>

                 <ExcelSheet data={this.state.urls} name="Urls"> 
                {colForUrl.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}</ExcelSheet> 
                

                <ExcelSheet data={this.state.docs} name="docs"> 
                {colForDoc.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}</ExcelSheet> 

                <ExcelSheet data={this.state.comments} name="Comments"> 
                {colForComment.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}</ExcelSheet> 
                
             
            </ExcelFile>
              </div>
            </div>
          </div>
          <div className="row">
            <Row>
              <Col>
                <div className="card">
                  <ReactTable
                    className="myTable"
                    resolveData={data => data.map(row => row)}
                    data={this.state.menu1}
                    columns={columns}
                    filterable
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
