import _ from 'underscore';


function ValidateEmail(mail) 
{
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return (true)
  }
   
    return (false)
}


export const validate = function(type,...args){
let error = false;

if(type=="email"){

    _.each(args,function(item){
    if(!ValidateEmail(item)){


        error = true;

    }
    if(item==undefined){
        error = true;
    }

});
    

}
else if(type=="mobile"){

    _.each(args,function(item){
    debugger;
    if(item!=undefined && item!="" ){

        error =  item.length != 10  ? true : false;

    }  else if(item==undefined){
        error = true;
    }


});

}
else {

    _.each(args,function(item){

        if(item!=undefined &&  item.length == 0 ){
    
            error = true;
    
        }
        else if(item==undefined){
            error = true;
        }
    
    
    });



}


return error;



};