import React, { Component } from "react";

import "../../style/agency.css";

import "../../animations.css";

/**
 * Query Modal
 * It is the code for adding the content and style to the section of query in the template .
 * @module QueryModal
 */
class QueryModal extends Component {
  constructor(props) {
    super();
    this.state = {
      subject: "",
      to: "",
      cc: "",
      subhead: "",
    };
    this.saveSocialIcons = this.saveSocialIcons.bind(this);
  }

  componentDidMount() {
    var mail = this.props;
    this.setState({ to: mail.to });
    this.setState({ cc: mail.cc });
    this.setState({ subject: mail.subject });

    this.setState({ subhead: mail.subhead });
  }

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);

    console.log(this.state);
  };

  saveSocialIcons() {
    this.props.saveSocialIcons(this.state);
    this.props.toggleQueryModal();
  }

  hideModal(key) {
    this.props.hideModal(key);
  }

  render() {
    return (
      <div className="modal-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <form>
                <div
                  id="contact-form"
                  className="form-container"
                  data-form-container
                  style={{ color: "rgb(46, 125, 50)", background: "white" }}
                >
                   <div className="row">
                    <div className="modal-head">
                      <div
                        className="close-button"
                        onClick={this.props.toggleQueryModal}
                      >
                        <h5 className="close-icon">X</h5>
                      </div>
                    </div>
                  </div>

                  <div className="input-container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="div-wrapper">
                          <h5 className="modal-title"> Edit Query Details </h5>
                        </div>
                        <form id="field">
                          <div className="row ">
                            <div className="col-md-3"><h5>Subject:</h5></div>{" "}
                            <div className="col-md-9">
                              <input
                                className="fieldbox"
                                onChange={this.onChange}
                                type="text"
                                value={this.state.subject}
                                name="subject"
                              />
                              <br />
                            </div>
                          </div>

                          <div className="row ">
                            <div className="col-md-3"><h5>To : </h5></div>{" "}
                            <div className="col-md-9">
                              <input
                                className="fieldbox"
                                onChange={this.onChange}
                                type="text"
                                value={this.state.to}
                                name="to"
                              />
                              <br />
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-md-3"><h5>CC :</h5></div>
                            <div className="col-md-9">
                              <input
                                className="fieldbox"
                                onChange={this.onChange}
                                type="text"
                                value={this.state.cc}
                                name="cc"
                              />
                              <br />
                            </div>
                          </div>

                          <div className="row ">
                            <div className="col-md-3"><h5>Subhead:</h5></div>
                            <div className="col-md-9">
                              <input
                                className="fieldbox"
                                onChange={this.onChange}
                                type="text"
                                value={this.state.subhead}
                                name="subhead"
                              />
                              <br />
                            </div>
                          </div>

                          {/* <button type="button"   className="imagebutton"> Save </button>*/}
                        </form>
                      </div>
                    </div>

                    <div className="row div-wrapper">
                      <div className="col-md-2"></div>
                      <div className="col-md-4">
                        <button
                          type="button"
                          onClick={() =>
                            this.saveSocialIcons(this.props.keyindex)
                          }
                          className="btn btn-block savebutton submit-form valid"
                        >
                          Save
                        </button>
                      </div>
                      <div className="col-md-4">
                        <button
                          type="button"
                          onClick={() => this.props.toggleQueryModal()}
                          className="btn btn-block cancelbutton submit-form valid"
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QueryModal;
