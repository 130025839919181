import React, { Component }  from "react";
import { apiLogsMaster } from "../../apiUtils";
import AddExpenseLog from "./addExpenseLog";
import axios from "axios";

export default class EditExpenseLog1 extends Component{
  
  constructor(props){
    super(props);
    this.state={
      entry:{},
      id:""
    }
  }

 componentDidMount(){
  const id = this.props.match.params.id;
  this.setState({ id });
   this.fetchQueryById(this, id)
 }
  fetchQueryById(that, id) {
    axios
      .get(apiLogsMaster + "businusquery/getLogById/" + id)
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });

        // //category selected
        // let selectedCategory = response.data.selectedCategory;
        // //get selected category index
        // let selectedCategoryIndex = 0;

        // for (let index = 0; index < context.state.groups.length; index++) {
        //   const element = context.state.groups[index];
        //   if (element.id == selectedCategory.id) {
        //     selectedCategoryIndex = index;
        //     break;
        //   }
        // }

        // let selectedClient = response.data.selectedClient;
        // let selectedClientId = 0;

        // for (let index = 0; index < context.state.clients.length; index++) {
        //   const element = context.state.clients[index];
        //   if (element.id == selectedClient.id) {
        //     selectedClientId = selectedClient.id;
        //     break;
        //   }
        // }

        // that.setState({
        //   ...that.state,
        //   ...response.data,
        //   selectedCategory: selectedCategoryIndex,
        //   selectedClient: selectedClientId,
        // });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render(){
  return(
    <><AddExpenseLog entry={this.state.entry} mode="Edit"/></>
  )

  }

}