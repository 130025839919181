import $ from "jquery";
import React, { Component } from "react";
import { Route } from 'react-router-dom';
import "../../../style/panel.css";
import addIssueGroup from "./addIssueGroup";
import addIssues from './addIssues';
import addIssueStatus from "./addIssueStatus";
import addIssueType from "./addIssueType";
import Dashboard from './Dashboard';
import editIssues from './editIssues';
import EditIssues1 from "./editIssues1";
import pageIssueGroup from "./pageIssueGroup";
import pageIssues from './pageIssues';
import pageIssuesStatus from "./pageIssueStatus";
import pageIssueType from "./pageIssueType";
import SidebarIssue from './SidebarIssue';
import viewIssueGroup from "./viewIssueGroup";
import viewIssues from './viewIssues';
import viewIssueStatus from "./viewIssueStatus";
import viewIssueType from "./viewIssueType";

















const data = [];
export default class IssueMainapp extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []

    };

  }
  componentDidMount() {
    this.handleLine();
    //this.setState({title:this.props.match.params.productType});
  }
  handleLine() {


    const tab1 = this.refs.tab1;
    const sidebar1 = this.refs.sidebar1;
    const section = this.refs.section;


    $(function () {
      $("#tab1").click(function () {
        $("#section").toggle();
      });
    });

  }

  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
      <div className="mainDiv" >

        <div className="row">
          <div className="col-md-2">
            <SidebarIssue title={this.state.title} />
          </div>
          <div className="col-md-10">


            <div className="section">
            {/* <Route exact path="/issues/home" component={Home} /> */}
            <Route exact path="/issues" component={Dashboard} />

            <Route exact path="/issues/view-issues/:id" component={pageIssues} />
              <Route exact path="/issues/edit-issues/:id" component={EditIssues1} />
              <Route exact path="/issues/add-issues" component={addIssues} />
              <Route exact path="/issues/view-issues" component={viewIssues} />


            <Route exact path="/issues/view-issueStatus/:id" component={pageIssuesStatus} />
            <Route exact path="/issues/add-issuesStatus" component={addIssueStatus} />
            <Route exact path="/issues/view-issuesStatus" component={viewIssueStatus} />

            <Route exact path="/issues/view-issueGroup/:id" component={pageIssueGroup} />
            <Route exact path="/issues/add-issuesGroup" component={addIssueGroup} />
            <Route exact path="/issues/view-issuesGroup" component={viewIssueGroup} />
  
            <Route exact path="/issues/view-issueType/:id" component={pageIssueType} />
            <Route exact path="/issues/add-issuesType" component={addIssueType} />
            <Route exact path="/issues/view-issuesType" component={viewIssueType} />
  
         {/*      <Route exact path="/issues/view-issues/:id" component={pageissues} />
              <Route exact path="/issues/edit-issues/:id" component={editissues} />
              <Route exact path="/issues/add-issues" component={addissues} />
              <Route exact path="/issues/view-issues" component={viewissues} />
              <Route exact path = "/issues/view-issues/:docType/:id" component = {PageResourceissues} />
              <Route exact path = "/issues/view-issues/checklist/page/:id" component = {pageChecklistWrapper} />
              <Route exact path="/issues/view-issuesTemplate/:id" component={pageissuesTemplate} />
              <Route exact path="/issues/add-issuesTemplate" component={addissuesTemplate} />
              <Route exact path="/issues/view-issuesTemplate" component={viewissuesTemplate} />
  
              <Route exact path="/issues/view-updateTemplate/:id" component={pageUpdateTemplate} />
              <Route exact path="/issues/view-template/:id" component={pageissuesTemplate} />
              <Route exact path="/issues/edit-template/:id" component={editissuesTemplate} />
              <Route exact path="/issues/edit-updateTemplate/:id" component={editissuesUpdateTemplate} />
              <Route exact path="/issues/add-updateTemplate" component={addissuesUpdateTemplate} />
              <Route exact path="/issues/view-updateTemplate" component={viewissuesUpdateTemplate} />
              <Route exact path="/issues-mail/:id" component={Assingmentdedicatedmailpage} />
  */}


            </div>

          </div>
        </div>

      </div>

    );
  }
}
