import React, { Component } from "react";
import axios from "axios";
import "react-quill/dist/quill.snow.css"; // ES6
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import _ from "underscore";
import { apiBaseUrl2 } from "../apiUtils";
let context = null;

export default class CommonShareComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      organisations: [],
      teamLeads: [],
      teams: [],
      teamMembers: [],
    };
    context = this;
  }

  //function for changing the status button on click to open or close
  handleOnChange = (e) => {
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });
    this.props.handleOnChange(e);

    if (formName == "selectedClient") {
      this.fetchOrganisation(value);
    }
  };

  fetchOrganisation = (clientId) => {
    if (clientId) {
      let selectedClient = _.findWhere(this.state.clients, { id: clientId });
      let organisations = selectedClient.assignedOrganisation;
      if (organisations != "0" && organisations !== undefined) {
        organisations.unshift({
          name: "Select Organisation",
          organisationId: "",
        });
        this.setState({ organisations: organisations });
      }
    }
  };

  componentDidMount() {
    this.fetchEmployees(this);
    this.fetchClients(this);
  }

  componentWillReceiveProps(newProps) {
    let defaultValues = this.props.defaultValues;
    if (this.props.defaultValues?.id != newProps.defaultValues?.id ) {
      defaultValues = newProps.defaultValues;
      if (defaultValues) {
        //setting default values
        this.setState({
          selectedClient: defaultValues.selectedClient?.id,
          tags: defaultValues.tags,
          teamLead: defaultValues.teamLead?.id,
          team: defaultValues.team,
          selectedOrganisation: defaultValues.selectedOrganisation?.id,
        });

        if(defaultValues.selectedClient){
          this.fetchClients(this, defaultValues.selectedClient?.id);
        }
        if(defaultValues.teamLead){
          this.fetchEmployees(this, defaultValues.teamLead?.id);
        }
        
       
      }
    }
  }

  handleChange = (newValue, actionMeta) => {
    this.setState({ tags: newValue });
  };

  fetchClients = (that, selectedClient) => {
    axios
      .get(apiBaseUrl2 + "businus/customer/list/" + localStorage.spId)
      .then(function (response) {
        console.log(response.data);

        response.data.unshift({ clientId: "", companyName: "Select Client" });
        that.setState({ clients: response.data });

        that.fetchOrganisation(selectedClient);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fetchEmployees(that, teamLead) {
    axios
      .get(apiBaseUrl2 + "businus/employee/list/" + localStorage.spId)
      .then(function (response) {
        debugger;
        console.log(response.data);
        var allEmployees = response.data;

        var teamLeads = [...allEmployees];

        var teamMembers = [...allEmployees];

        var teamMembersMultiSelectData = [];

        _.each(teamMembers, function (member) {
          teamMembersMultiSelectData.push({
            value: member.id,
            label:
              member.employeeId +
              " " +
              member.firstName +
              " " +
              member.lastName,
            name:
              member.employeeId +
              " " +
              member.firstName +
              " " +
              member.lastName,
            id: member.id,
            email: member.email,
          });
        });

        teamLeads.unshift({ employeeId: "", firstName: "Select Team Lead" });
        that.setState({
          teamMembers: teamMembersMultiSelectData,
          teamLeads: teamLeads,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  onSelect = (selectedOption) => {
    this.setState({ team: selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  onRemove(optionList, removedItem) {
    context.setState({ team: optionList });
  }

  //function to get selected clients
  getSelectedClient = () => {
    let selectedClient = _.findWhere(this.state.clients, {
      id: this.state.selectedClient,
    });

    selectedClient = {
      id: selectedClient.id,
      name: selectedClient.primaryContact,
      email: selectedClient.customerEmail,
      companyName: selectedClient.companyName,
      mode: selectedClient.mode,
    };

    if (selectedClient) {
      return selectedClient;
    } else {
      alert("Client can't be blank");
      return false;
    }
  };

  getTeamLead = () => {
    let teamLead = _.findWhere(this.state.teamLeads, {
      id: this.state.teamLead,
    });

    if (teamLead) {
      teamLead = {
        id: teamLead.id,
        name: teamLead.firstName + teamLead.lastName,
      };

      return teamLead;
    } else {
      alert("Team Lead can't be blank");
      return false;
    }
  };

  getOrganisation = () => {
    let selectedOrganisation = _.findWhere(this.state.organisations, {
      id: this.state.selectedOrganisation,
    });

    if (selectedOrganisation) {
      selectedOrganisation = {
        name: selectedOrganisation.name,
        id: selectedOrganisation.id,
        logo: selectedOrganisation.logo,
        organisationId: selectedOrganisation.organisationId,
      };

      return selectedOrganisation;
    } else {
      alert("Organisation can't be blank");
      return false;
    }
  };

  getCommonShareValues = () => {
    let data = this.state;

    let selectedClient = this.getSelectedClient(data.selectedClient);
    let teamLead = this.getTeamLead(data.teamLead);
    let selectedOrganisation = this.getOrganisation(data.selectedOrganisation);

    if (!selectedClient) {
      return null;
    }

    if (!teamLead) {
      return null;
    }

    if (!selectedOrganisation) {
      return null;
    }

    return {
      selectedClient,
      teamLead,
      selectedOrganisation,
      tags: data.tags,
      team: data.team,
    };
  };

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <div className="form-group">
              <label className="control-label col-md-3">Tags</label>
              <div className="col-md-7">
                <CreatableSelect
                  isMulti
                  onChange={this.handleChange}
                  options={this.state.tags}
                  value={this.state.tags}
                />
              </div>
            </div>

            <div className="form-group">
              <label className="control-label col-md-3">Client:</label>
              <div className="col-md-7">
                <select
                  name="selectedClient"
                  onChange={this.handleOnChange}
                  value={this.state.selectedClient}
                  className="form-control ember-text-field ember-view"
                  type="text"
                >
                  {this.state.clients.map(function (client, i) {
                    return (
                      <option value={client.id}>
                        {client.customerId} {client.companyName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="form-group">
              <label className="control-label col-md-3">Organisation:</label>
              <div className="col-md-7">
                <select
                  name="selectedOrganisation"
                  onChange={this.handleOnChange}
                  value={this.state.selectedOrganisation}
                  className="form-control ember-text-field ember-view"
                  type="text"
                >
                  {this.state.organisations.map(function (organisation, i) {
                    return (
                      <option value={organisation.id}>
                        {" "}
                        {organisation.organisationId + " " + organisation.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="form-group">
              <label className="control-label col-md-3">Team Lead:</label>
              <div className="col-md-7">
                <select
                  name="teamLead"
                  onChange={this.handleOnChange}
                  value={this.state.teamLead}
                  className="form-control ember-text-field ember-view"
                  type="text"
                >
                  {this.state.teamLeads.map(function (teamLead, i) {
                    return (
                      <option value={teamLead.id}>
                        {teamLead.employeeId} {teamLead.firstName}{" "}
                        {teamLead.lastName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="form-group">
              <label className="control-label col-md-3">Team:</label>
              <div className="col-md-7">
                {this.state.teamMembers.length > 0 ? (
                  <Select
                    value={this.state.team}
                    onChange={this.onSelect}
                    options={this.state.teamMembers}
                    isMulti={true}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
