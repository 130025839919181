import React, { Component } from "react";
import CsvDownloader from "react-csv-downloader";
import BreadCrumbs from "../../BreadCrumbs";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiBaseUrl1, apiBaseUrl5 } from "../../apiUtils";
import { apiBaseUrl2 } from "../../apiUtils";
import ReactTable from "react-table";
import $ from "jquery";
import _, { template } from "underscore";
import ReactExport from "react-export-excel";
import { getCommonColumns } from "../../common/CommonColumns";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";

const data = [];
let paymentStatusList = [];
let progressStatusList = [];
let context = "null";
let handleOnSelect = null;

const colForAttach = [
  {
    id: "attachment",
    label: "attachment",
  },
  { id: "name", label: "Name" },
  { id: "description", label: "description" },
];

const colForUrl = [
  {
    id: "urlTxt",
    label: "Url Link",
  },
  {
    id: "description",
    label: "description",
  },
  { id: "urlTitle", label: "url" },
];

const colForComment = [
  {
    id: "comment",
    label: "comment",
  },
  { id: "by", label: "by" },
];

const colForDoc = [
  {
    id: "title",
    label: "title",
  },
  {
    id: "description",
    label: "description",
  },
  {
    id: "docId",
    label: "docId",
  },
  {
    id: "name",
    label: "name",
  },
];

//defined all the columns and fetched the data for every cell.
const colformail = [
  {
    id: "subject",
    label: "subject",
  },
  { id: "message", label: "message" },
  {
    id: "attachment",
    label: "attachment",
  },
  {
    id: "from",
    label: "from",
  },
  {
    id: "mailId",
    label: "mailId",
  },
  {
    id: "client",
    label: "client",
  },
  {
    id: "assignmentId",
    label: "assignmentId",
  },
];
const columnsForExcel = [
  {
    id: "assignmentId",
    displayName: "Assingment ID",
  },
  {
    displayName: "Name",

    id: "name",
  },
  {
    displayName: "Status",
    id: "status",
  },
  {
    displayName: "Payment Status",
    id: "paymentStatus",
  },
  {
    displayName: "Start Date",
    id: "startDate",
  },
  {
    displayName: "End Date",
    id: "endDate",
  },

  {
    displayName: "Overview",
    id: "assignmentOverview",
  },

  {
    displayName: "Priority",
    id: "priority",
  },
  {
    displayName: "Client",
    id: "selectedClient",
  },
  {
    displayName: "Organisation",
    id: "selectedOrganisation",
  },
  {
    displayName: "Team Lead",
    id: "teamLead",
  },
  {
    displayName: "Team",

    id: "teamMembers",
  },
  {
    displayName: "Template",

    id: "selectedTemplate",
  },
  {
    displayName: "Recurrance Frequency",

    id: "recurringFrequency",
  },
  {
    displayName: "Priority",

    id: "priority",
  },
  {
    displayName: "By",

    id: "by",
  },
  {
    displayName: "Date",
    id: "date",
  },
  {
    displayName: "Time",
    id: "time",
  },
];
const columns = [
  {
    Header: (props) => (
      <span className="form-textHead cell-head">Assignment ID</span>
    ),
    accessor: "assignmentId",
    id: "assignmentId",
    filterMethod: (filter, row) => {
      return row.assignmentId
        .toLowerCase()
        .includes(filter.value.toLowerCase());
    },

    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 170,
  },
  {
    Header: (props) => <span className="form-textHead cell-head">Title</span>,
    accessor: (d) => {
      return { title: d.assignmentTitle, id: d.id };
    },
    filterMethod: (filter, row) => {
      return row.name.title.toLowerCase().includes(filter.value.toLowerCase());
    },

    id: "name",
    Cell: (props) => (
      <Link className="link" to={"./view-assignment/" + props.value.id}>
        {props.value.title}
      </Link>
    ),
    minWidth: 180,
  },
  {
    Header: (props) => (
      <span className="form-textHead cell-head">Start Date</span>
    ),
    accessor: "startDate",
    id: "startDate",
    filterMethod: (filter, row) => {
      return new Date(row.startDate)
        .toLocaleTimeString()
        .toLowerCase()
        .includes(filter.value.toLowerCase());
    },
    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 170,
  },
  {
    Header: (props) => (
      <span className="form-textHead cell-head">End Date</span>
    ),
    filterMethod: (filter, row) => {
      return new Date(row.endDate)
        .toLocaleTimeString()
        .toLowerCase()
        .includes(filter.value.toLowerCase());
    },
    accessor: "endDate",
    id: "endDate",
    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 170,
  },
  {
    Header: (props) => (
      <span className="form-textHead cell-head">Billing Type</span>
    ),
    accessor: "billingType",
    id: "billingType",
    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 170,
  },

  {
    Header: (props) => (
      <span className="form-textHead cell-head">Assignment Overview</span>
    ),
    accessor: "assignmentOverview",
    id: "assignmentOverview",
    Cell: (props) => (
      <span
        className="form-text cell"
        style={{ height: "140px !important", overflowY: "scroll !important" }}
       
      >{context.removeHtmlTags(props.value)}</span>
    ),
    minWidth: 170,
  },
  {
    Header: (props) => (
      <span className="form-textHead cell-head">Progress Status</span>
    ),
    id: "progressStatus",
    accessor: (d) => {
      return {
        progressStatus: d.progressStatus,
        id: d.id,
        checklists: d.checklists,
        status: d.status,
      };
    },
    Cell: (props) => (
      <select
        onChange={(e) => handleOnSelect(e, props.value)}
        name="progressStatus"
        value={props.value.progressStatus}
      >
        {progressStatusList.map((value) => {
          return <option value={value.progressStatus}>{value.progressStatus}</option>;
        })}
      </select>
    ),
    minWidth: 200,
  },
  {
    Header: (props) => <span className="form-textHead cell-head">Tags</span>,
    accessor: "tags",
    id: "tags",
    Cell: (props) => (
      <span className="form-text cell">
        {props.value &&
          props.value.map((member) => {
            return (
              <span>
                {member.label} &nbsp;
              </span>
            );
          })}
      </span>
    ),
    minWidth: 170,
  },
  
  {
    Header: (props) => (
      <span className="form-textHead cell-head">Payment Status</span>
    ),
    id: "paymentstatus",
    accessor: (d) => {
      return {
        paymentStatus: d.paymentStatus,
        id: d.id,
        checklists: d.checklists,
        status: d.status,
      };
    },
    Cell: (props) => (
      <select
        onChange={(e) => handleOnSelect(e, props.value)}
        value={props.value.paymentStatus}
        name="paymentStatus"
      >
        {paymentStatusList.map((value) => {
          return <option value={value.paymentStatus}>{value.paymentStatus}</option>;
        })}
      </select>
    ),
    width: 200,
  },
  {
    Header: (props) => (
      <span className="form-textHead cell-head">Template</span>
    ),
    accessor: "selectedTemplate",
    filterMethod: (filter, row) => {
      return row.selectedTemplate
        .toLowerCase()
        .includes(filter.value.toLowerCase());
    },
    id: "selectedTemplate",
    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 170,
  },
  {
    Header: (props) => (
      <span className="form-textHead cell-head">Visible to Client</span>
    ),
    accessor: "visibleToClient",
    id: "visibleToClient",
    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 50,
  },

  {
    Header: (props) => (
      <span className="form-textHead cell-head">Recurrance Frequency</span>
    ),
    accessor: "recurringFrequency",
    id: "recurringFrequency",
    Cell: (props) => (
      <span className="form-text cell">
        {props.value != undefined ? props.value : "-"}
      </span>
    ),
    minWidth: 170,
  },

  {
    Header: (props) => (
      <span className="form-textHead cell-head">Reminder</span>
    ),
    accessor: "remindingFrequency",
    id: "remindingFrequency",
    Cell: (props) => (
      <span className="form-text cell">{props.value || "Not Assigned"}</span>
    ),
    minWidth: 170,
  },

  {
    Header: (props) => (
      <span className="form-textHead cell-head">Priority</span>
    ),
    accessor: "priority",
    id: "priority",
    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 170,
  },
  
  ...getCommonColumns({dedicatedLink:'view-assignment',hasAttachment:false}),
];

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
export default class viewAssignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attachments: [],
      urls: [],
      docs: [],
      comments: [],
      opened: [],
      menu1: [],
      excelData: [],
      menu2: [],
      progressStatus:"",
      paymentStatus:"",
    };
    context = this;
  }
  componentDidMount() {
    handleOnSelect = this.handleOnSelect;
    this.fetchStatus(this, "payment");
    this.fetchStatus(this, "progress");
    this.fetchAllMails(this);
    this.fetchAllReources(this);
  }

  handleOnSelect = (e, query) => {
    debugger;
    var name = e.target.name;
    var selectedValue = e.target.value;
    if (name== "paymentStatus" ){
     var updatedData={
        paymentStatus: selectedValue
      }
    }
    else if (name == "progressStatus") {
    var updatedData={
      progressStatus: selectedValue
    }
    }

    var list = this.state.menu1;
    var index = _.findIndex(list, function (entry) {
      return entry.id == query.id;
    });

    if (name == "progressStatus") {
      list[index].progressStatus = selectedValue;
    } else {
      list[index].paymentStatus = selectedValue;
    }

    var newList = JSON.parse(JSON.stringify(list));
    context.setState({ menu1: newList });

    axios
      .post(apiBaseUrl1 + "businus/project/update/" + query.id, updatedData)
      .then(function (response) {
        debugger;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fetchAllMails(that) {
    axios
      .get(
        apiBaseUrl5 + "businus/allMails/" + localStorage.getItem("employeeId")
      )
      .then(function (response) {
        console.log(response.data);
        debugger;
        var threads =
          response.data == "" ? [] : _.sortBy(response.data, "date").reverse();

        threads = _.map(threads, function (t) {
          if (typeof t.attachment == "object") {
            t.attachment = context.getCommaSeparatedAttachment(t.attachment);
          }
          t.message = t.message.replace(/<[^>]*>/g, "");
          return t;
        });

        var documents = _.where(threads, { docType: "Document" });
        var proposals = _.where(threads, { docType: "Proposal" });
        var mails = _.where(threads, { docType: "Mail" });
        var teamMails = _.where(threads, { docType: "TEAM-MAILS" });
        var teamDocs = _.where(threads, { docType: "TEAM-DOCS" });
        that.setState({ documents, teamDocs, teamMails });
        that.setState({ proposals });
        that.setState({ mails });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fetchAllReources(that) {
    axios
      .get(
        apiBaseUrl5 +
          "businus/listAllResources/" +
          localStorage.getItem("employeeId")
      )
      .then(function (response) {
        let attachments = [];
        let docs = [];
        let urls = [];
        let comments = [];

        _.each(response.data, function (d) {
          if (d.attachment) $.merge(attachments, d.attachment);
          if (d.doc) $.merge(docs, d.doc);
          if (d.url) $.merge(urls, d.url);
          if (d.comment) $.merge(comments, d.comment);
        });

        debugger;
        that.setState({ attachments, docs, urls, comments });
      })
      .catch(function (error) {
        debugger;
        console.log(error);
      });
  }

  getFormattedDate(millis) {
    return new Date(millis).toDateString();
  }

 removeHtmlTags(text) {
    let newText= text.replace(/<[^>]+>/g, '');
    console.log(newText);
    return newText;
  }
  
  generateExcelData = (data) => {
    let excelData = [];
    _.each(data, function (d) {
      let row = {
        assignmentId: d.assignmentId,
        name: d.assignmentTitle,
        status: d.status,
        paymentStatus: d.paymentStatus,
        startDate: d.startDate,
        endDate: d.endDate,
        assignmentOverview: context.removeHtmlTags(d.assignmentOverview),
        selectedClient: d.selectedClient.name,
        selectedOrganisation: d.selectedOrganisation.name,
        teamLead: d.teamLead.name,

        teamMembers: context.getCommaSeparatedTeamName(d.team),
        selectedTemplate: d.selectedTemplate,
        recurringFrequency: d.recurringFrequency,
        priority: d.priority,
        by: d.by,
        date: new Date(d.date).toDateString(),
        time: new Date(d.date).toLocaleTimeString(),
      };

      excelData.push(row);
      console.log(excelData);
    });
    this.setState({ excelData });
  };

  getCommaSeparatedTeamName = (teamMember) => {
    console.log(teamMember);
    let names = _.map(teamMember, function (d) {
      return d.name;
    });
    console.log(names);
    return names.join(";");
  };

  getCommaSeparatedAttachment = (attachments) => {
    let names = _.map(attachments, function (d) {
      return d.attachment;
    });
    console.log(names);
    return names.join(";");
  };

  fetchStatus(that, status) {
    axios
      .get(
        apiBaseUrl1 +
          "businus/status/list/" +
          status +
          "/" +
          localStorage.getItem("employeeId")
      )
      .then(function (response) {
        console.log(response.data);

        if (status == "payment") {
          that.setState({ paymentStatusList: response.data });
          paymentStatusList = response.data;
        } else {
          that.setState({ progressStatusList: response.data });
          progressStatusList = response.data;
        }
        that.fetchQueries(that, that.props.source);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  toggleOpen = (id) => {
    var list = this.state.menu1;
    var index = _.findIndex(list, function (entry) {
      return entry.id == id;
    });

    list[index].status =
      list[index].status == "open" || list[index].status == undefined
        ? "close"
        : "open";

    var newList = JSON.parse(JSON.stringify(list));
    context.setState({ menu1: newList });
    context.updateStatus(context, id, list[index].status);
  };

  fetchQueries(that, source) {
    axios
      .get(
        apiBaseUrl1 +
          "businus/project/list/" +
          localStorage.getItem("spId") +
          "/" +
          localStorage.getItem("employeeId")
      )
      .then(function (response) {
        console.log(response.data);

        that.setState({ menu1: _.sortBy(response.data, "projectId") });
        that.generateExcelData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    return (
      <div>
        <div className="Div">
          <div className="row breadcrumb-row">
            <div className="col-md-10">
              <BreadCrumbs />
            </div>
            <div className="col-md-2">
              <div className=" ">
                {/*    <CsvDownloader
                  text="Download in .csv format"
                  datas={this.state.excelData}
                  filename="assignment"
                  separator=","
                  wrapColumnChar=""
                  columns={columnsForExcel}
                /> */}
                <ExcelFile
                  element={
                    <button className="download-button-wrapper">
                      Download Data in Excel format
                    </button>
                  }
                >
                  <ExcelSheet data={this.state.excelData} name="Assignments">
                    {columnsForExcel.map((d) => (
                      <ExcelColumn label={d.displayName} value={d.id} />
                    ))}
                  </ExcelSheet>
                  {/* 
                <ExcelSheet data={this.state.mails} name="Mails"> 
                {colformail.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}
                   
              
                </ExcelSheet>
                 <ExcelSheet data={this.state.documents} name="Documents"> 
                {colformail.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}</ExcelSheet>
                <ExcelSheet data={this.state.proposals} name="Proposals"> 
                {colformail.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}</ExcelSheet> 
                <ExcelSheet data={this.state.teamMails} name="Team Mails"> 
                {colformail.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}</ExcelSheet> 
                <ExcelSheet data={this.state.teamDocs} name="Team Docs"> 
                {colformail.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}</ExcelSheet> 

                <ExcelSheet data={this.state.attachments} name="Attachments"> 
                {colForAttach.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}</ExcelSheet>

                 <ExcelSheet data={this.state.urls} name="Urls"> 
                {colForUrl.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}</ExcelSheet> 
                

                <ExcelSheet data={this.state.docs} name="docs"> 
                {colForDoc.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}</ExcelSheet> 

                <ExcelSheet data={this.state.comments} name="Comments"> 
                {colForComment.map(d=>  <ExcelColumn label={d.label} value={d.id}/>)}</ExcelSheet> 
          */}
                </ExcelFile>
              </div>
            </div>
          </div>
          <div className="row">
            <Row>
              <Col>
                <div className="card">
                  <ReactTable
                    className="myTable"
                    resolveData={(data) => data.map((row) => row)}
                    data={this.state.menu1}
                    columns={columns}
                    filterable
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
