import React, { Component } from "react";
import { SketchPicker } from "react-color";
import "../../style/agency.css";
import "../../style/animate.css";
import StyleModal from "../Common/StyleModal";
import { apiBaseUrl, isValid } from "../apiUtils";
import ButtonModal from "../Common/ButtonModal";
import axios from "axios";

/**
 * Button modal component
 * this is the code  which shows contact on the website.
 * @module contact
 */
import ContactModal from "./contactModal";

let context = null;
class Contact extends Component {
  constructor(props) {
    super();
    this.state = {
      showBtnModal: false,
      title: "",
      toggleStyleMode: false,
      hideModal: true,
      Products: [],
      showContactModal: false,
      to: "",
      cc: "",
      subject: "",
      header: "Contact Us",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco ",
      location: "Xyz city",
      timings: "9:00 hrs to 17:00 hrs IST",
      contact: "7977797979",
      mail: "xyz@gmail.com",
      contactInfo: {},
      newUrl: "",
      styles: {
        backgroundImage: "fg",
        backgroundColor: "rgb(250 250 250)",
        padding: "33px 10px 54px 10px",
        margin: "0px 0px 0px 0px",
        color: "black",
      },
      btnBackgroundColor: "#1266FF",
      btnColor: "#fff",
      btnLink: "products",
      btnHighlightColor: "#86CA87",
      btnText: "Tell Me More",
      isBtnHover: false,
    };

    context = this;
    this.saveStyle = this.saveStyle.bind(this);
    this.toggleStyleModal = this.toggleStyleModal.bind(this);
    this.saveMailOptions = this.saveMailOptions.bind(this);
    this.toggleContactModal = this.toggleContactModal.bind(this);
    this.toggleBtnModal = this.toggleBtnModal.bind(this);
    this.sendMail = this.sendMail.bind(this);
  }
  toggleHover(index) {
    this.setState({ isBtnHover: !this.state.isBtnHover });
  }

  /**
   * saveBtnInfo
   * This is the segment of code used to save data of button elements.
   * @function saveBtnInfo
   */
  saveBtnInfo(btnInfo) {
    context.setState({ btnBackgroundColor: btnInfo.btnBackgroundColor });
    context.setState({ btnColor: btnInfo.btnColor });
    context.setState({ btnLink: btnInfo.btnLink });
    context.setState({ btnText: btnInfo.btnText });
    context.setState({ btnHighlightColor: btnInfo.btnHighlightColor });
  }

  componentDidMount() {
    this.setState({ title: this.props.title });

    if (this.props.contactInfo.phone) {
      this.setState({ phone: this.props.contactInfo.phone });
      this.setState({ mail: this.props.contactInfo.mail });
      this.setState({ location: this.props.contactInfo.location });
      this.setState({ timings: this.props.contactInfo.timings });
      this.setState({ description: this.props.contactInfo.description });
      this.setState({ header: this.props.contactInfo.header });

      this.setState({ contactInfo: this.props.contactInfo });
    }

    if (this.props.productStyle) {
      this.setState({ styles: this.props.productStyle });
    }
  }
  toggleStyleModal() {
    var status = !this.state.toggleStyleMode;
    this.setState({ toggleStyleMode: status });
  }
  onChange = (e) => {
    const state = this.state;
    state[e.target.id] = e.target.value;
    this.setState(state);

    console.log(this.state);
  };

  toggleBtnModal() {
    var status = !this.state.showBtnModal;
    this.setState({ showBtnModal: status });
  }

  saveStyle(newstyle) {
    this.setState({ styles: newstyle });
    this.setState({ title: newstyle.title });

    this.props.saveSectionProperties(
      this.props.sectionKey,
      newstyle.title,
      newstyle,
      "no products"
    );
  }
  toggleContactModal() {
    var status = !this.state.showContactModal;
    this.setState({ showContactModal: status });
  }

  /**
   * saveMailOptions
   * This is the segment of code used to save mail options.
   * @function saveMailOptions
   */
  saveMailOptions(mail) {
    this.setState({ to: mail.to });
    this.setState({ cc: mail.cc });
    this.setState({ subject: mail.subject });
    const contactInfo = {
      phone: mail.phone,
      mail: mail.mail,
      location: mail.location,
      header: mail.header,
      description: mail.description,
      timings: mail.timings,
    };
    this.setState({
      contactInfo,
    });
    this.setState({
      phone: mail.phone,
      mail: mail.mail,
      location: mail.location,
      header: mail.header,
      description: mail.description,
      timings: mail.timings,
    });

    this.props.saveSectionProperties(
      this.props.sectionKey,
      this.state.title,
      this.state.styles,
      "contactInfo",
      contactInfo
    );
  }

  /**
   * sendMail
   * This is the segment of code used to send mail to the address set by the user.
   * @function sendMail()
   */

  sendMail() {
    if (
      isValid(
        this.state.email,
        this.state.name,
        this.state.phone,
        this.state.message
      )
    ) {
      alert("Fields cannot be blank");
      return false;
    }

    var data = {
      name: "megha",
      email: "gautammegha2@gmail.com",
      message: "message",
    };
    axios
      .post(apiBaseUrl + "/sendmail", data)
      .then(function (response) {
        console.log("success");
      })
      .catch(function (error) {
        console.log(error);
        {
          alert("Your request has been sent");
        }
      });
  }

  render() {
    const { styles } = this.state;
    return (
      <div>
        {/* Contact */}
        <section
          style={{
            color: styles.color,
            backgroundColor: styles.backgroundColor,
            backgroundImage:
              styles.backgroundImage != "no image"
                ? styles.backgroundImage
                : "",
            padding: styles.padding,
            margin: styles.margin,
          }}
          id={this.props.title.replace(/\s/g, "")}
        >
          {this.state.toggleStyleMode ? (
            <StyleModal
              title={this.state.title}
              saveProduct={this.saveStyle}
              toggleStyleModal={this.toggleStyleModal}
              saveStyle={this.saveStyle}
              styleProp={this.state.styles}
            />
          ) : (
            ""
          )}
          {this.state.showBtnModal ? (
            <ButtonModal
              saveBtnInfo={this.saveBtnInfo}
              btnText={this.state.btnText}
              btnColor={this.state.btnColor}
              btnHighlightColor={this.state.btnHighlightColor}
              btnBackgroundColor={this.state.btnBackgroundColor}
              saveButtonInstance={this.saveButtonInstance}
              toggleButtonModal={this.toggleBtnModal}
            />
          ) : (
            ""
          )}
          {this.state.showContactModal ? (
            <ContactModal
              saveSocialIcons={this.saveMailOptions}
              toggleContactModal={this.toggleContactModal}
              to={this.state.to}
              cc={this.state.cc}
              subject={this.state.subject}
              contactInfo={this.state.contactInfo}
            />
          ) : (
            ""
          )}
          <div className="container">
            <div className="row head-row">
              <div className="col-lg-12 text-center animatedParent">
                <h2 className="section-heading  contactdiv animated fadeInUp">
                  {/*{this.state.title}*/}
                  <span
                    className="editsign editclass"
                    onClick={() => this.toggleStyleModal()}
                  >
                    <i className="fa editclass fa-edit"></i>
                  </span>
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="webzy-contact-card-1">
                      <h3 className="contact-head">
                        {this.state.header}{" "}
                        <i
                          className="fa editclass fa-edit"
                          onClick={this.toggleContactModal}
                        ></i>
                      </h3>
                      <h5 className="contact-description">
                        {this.state.description}
                      </h5>
                      <h4 className="contact-details">
                        <i
                          style={{ color: "rgb(10 47 77)" }}
                          className="fa fa-envelope"
                        ></i>
                        &nbsp;{this.state.mail}
                      </h4>
                      <h4 className="contact-details">
                        <i
                          style={{ color: "rgb(10 47 77)" }}
                          className="fa fa-phone"
                        ></i>
                        &nbsp;{this.state.contact}
                      </h4>
                      <h4 className="contact-details">
                        <i
                          style={{ color: "rgb(10 47 77)" }}
                          className="fa fa-street-view"
                        ></i>
                        &nbsp;{this.state.location}
                      </h4>
                      <h4 className="contact-details">
                        <i
                          style={{ color: "rgb(10 47 77)" }}
                          className="fa fa-clock"
                        ></i>
                        &nbsp;{this.state.timings}
                      </h4>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="webzy-contact-card">
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className="webzy-contact-fields form-group animatedParent "
                            style={{ padding: "10px 0px 10px 20px" }}
                          >
                            <input
                              className="form-control animated contactdiv fadeInUp "
                              onChange={this.onChange}
                              id="name"
                              type="text"
                              placeholder=" Name *"
                              required="required"
                              data-validation-required-message="Please enter your name."
                            />
                            <p className="help-block text-danger" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className="webzy-contact-fields form-group animatedParent "
                            style={{ padding: "10px 20px 10px 0px" }}
                          >
                            <input
                              className="form-control animated contactdiv fadeInUp"
                              onChange={this.onChange}
                              id="email"
                              type="email"
                              placeholder=" Email *"
                              required="required"
                              data-validation-required-message="Please enter your email address."
                            />
                            <p className="help-block text-danger" />
                          </div>
                        </div>
                      </div>
                      <div className="webzy-contact-fields form-group animatedParent ">
                        <input
                          className="form-control animated contactdiv fadeInUp"
                          onChange={this.onChange}
                          id="phone"
                          type="phone"
                          placeholder=" Phone *"
                          required="required"
                          data-validation-required-message="Please enter your phone."
                        />
                        <p className="help-block text-danger" />
                      </div>
                      <div className="webzy-contact-fields form-group animatedParent ">
                        <textarea
                          className="form-control contactdiv animated fadeInUp"
                          onChange={this.onChange}
                          id="message"
                          placeholder=" Message *"
                          required="required"
                          data-validation-required-message="Please enter a message."
                          defaultValue={""}
                        />
                        <p className="help-block text-danger" />
                      </div>
                      <div
                        id="success "
                        className="animatedParent text-center"
                      />
                      <button
                        onMouseEnter={() => this.toggleHover("btnKnow")}
                        onMouseLeave={() => this.toggleHover("btnKnow")}
                        style={{
                          backgroundColor: this.state.isBtnHover
                            ? this.state.btnHighlightColor
                            : this.state.btnBackgroundColor,
                          color: this.state.btnColor,
                          marginTop: "25px",
                        }}
                        id="sendMessageButton"
                        className="btn  contactdiv  btn-xl text-uppercase animated fadeInUp"
                        type="button"
                        onClick={this.sendMail}
                      >
                        {this.state.btnText}
                      </button>
                      <br />{" "}
                      <i
                        className="fa editclass fa-edit"
                        onClick={this.toggleBtnModal}
                      ></i>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix" />
              <div className="col-lg-12 text-center"></div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Contact;
