import React, { Component } from "react";
import reactCSS from "reactcss";
import "../../style/agency.css";
import "../../style/webzyApp.scss";
import { SketchPicker } from "react-color";
import "../../animations.css";

/**
 * About add section modal component
 * this is the code of modal through which about section gets added to the website.
 * @module About-Modal
 */
class AddSectionModal extends Component {
  constructor(props) {
    super();
    this.state = {
      title: "",
      cards: "0",
    };
    this.saveSection = this.saveSection.bind(this);
  }

  componentDidMount() {}

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  /**
   * saveAboutSection component
   * This is segment of code which saves changes made in about section.
   * @module saveAboutUs
   */
  saveSection() {
    this.props.saveSection(this.props.type, this.state);
    this.props.toggleAddSectionModal(this.props.type);
  }

  render() {
    return (
      <div className="modal-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <form>
                <div
                  id="contact-form"
                  className="form-container"
                  data-form-container
                  style={{ color: "rgb(46, 125, 50)", background: "white" }}
                >
                   <div className="row">
                    <div className="modal-head">
                       <div
                      className="close-button"
                      onClick={() => this.props.toggleAddSectionModal(this.props.type)}
                      >
                      <h5 className="close-icon">
                        X
                      </h5>
                    </div>
                    </div>
                  </div>
                 
                  <div className="input-container">
                 
                 
                 
                
                    <div className="row">
                      <div className="col-md-12">
                      <div className="div-wrapper">
                  <h5 className="modal-title"> Edit Style for {this.props.type} </h5>
                  </div>
                        <form id="field">
                          <div className="row">
                            <div className="col-md-4">
                              <h5>Title :</h5>
                            </div>
                            <div className="col-md-8 ">
                              <input
                                className="fieldbox"
                                onChange={this.onChange}
                                type="text"
                                value={this.state.title}
                                name="title"
                              />
                            </div>
                          </div>
                        {((["Products","Services","Team","Timeline"]).includes(this.props.type))?
                          <div className="row modalrow">
                            <div className="col-md-4"> 
                            <h5>No. of Cards :</h5>
                            </div>
                            <div className="col-md-8 ">
                            
                              <input
                                className="fieldbox"
                                onChange={this.onChange}
                                type="number"
                                value={this.state.products}
                                name="products"
                              />
                              <br />
                            </div>
                          </div>
                          :""}
                        </form>
                      </div>
                    </div>

                    <div className="row div-wrapper">
                      <div className="col-md-2"></div>
                      <div className="col-md-4">
                        <button
                          type="button"
                          onClick={() => this.saveSection()}
                          className="btn btn-block savebutton submit-form valid"
                        >
                          Save
                        </button>
                      </div>
                      <div className="col-md-4">
                        <button
                          type="button"
                          onClick={() => this.props.toggleAddSectionModal(this.props.type)}
                          className="btn btn-block cancelbutton submit-form valid"
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddSectionModal;
