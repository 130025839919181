import React, { Component } from "react";
import CsvDownloader from "react-csv-downloader";
import BreadCrumbs from "../../BreadCrumbs";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  apiBaseUrl1,
  apiBaseUrl5,
  apiIssueMaster,
  apiTaskMaster,
} from "../../apiUtils";
import { apiBaseUrl2 } from "../../apiUtils";
import ReactTable from "react-table";
import $ from "jquery";
import _, { template } from "underscore";
import ReactExport from "react-export-excel";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";

const data = [];
let statusList = [];
let progressStatusList = [];
let context = "null";
let handleOnSelect = null;

const columnsForExcel = [
  {
    id: "taskId",
    displayName: "Task ID",
  },
  {
    displayName: "Task Title",

    id: "title",
  },
  {
    displayName: "Billing",
    id: "billingType",
  },
  {
    displayName: "Start Date",
    id: "startDate",
  },
  {
    displayName: "Due Date",
    id: "dueDate",
  },

  {
    displayName: "Overview",
    id: "overview",
  },

  {
    displayName: "Priority",
    id: "priority",
  },
  {
    displayName: "Client",
    id: "selectedClient",
  },

  {
    displayName: "Team",

    id: "teamMembers",
  },
  {
    displayName: "Selected Task",

    id: "selectedTask",
  },

  {
    displayName: "By",

    id: "by",
  },
  {
    displayName: "Date",
    id: "date",
  },
  {
    displayName: "Time",
    id: "time",
  },
];
const columns = [
  {
    Header: (props) => <span className="form-textHead cell-head">ID</span>,
    accessor: "taskId",
    filterMethod: (filter, row) => {
      return row.taskId.toLowerCase().includes(filter.value.toLowerCase());
    },
    id: "taskId",
    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 170,
  },
  {
    Header: (props) => (
      <span className="form-textHead cell-head">Assigned To</span>
    ),
    accessor: (d) => {
      return { assignmentId: d.assignmentId, id: d.queryId };
    },
    filterMethod: (filter, row) => {
      return row.assignmentId ? row.assignmentId.assignmentId?.toLowerCase().includes(filter.value.toLowerCase()) : false;
    },

    id: "assignmentId",
    Cell: (props) => (
      <span className="form-text cell">
        {props.value.assignmentId == undefined || props.value.assignmentId == 0 ? (
          "NA"
        ) : props.value.assignmentId == "NA" ? (
          props.value.assignmentId
        ) : (
          <Link
            className="link"
            to={"/assignment/view-assignment/" + props.value.id}
          >
            <span>{props.value.assignmentId}</span>
          </Link>
        )}
      </span>
    ),
    minWidth: 180,
  },
  {
    Header: (props) => <span className="form-textHead cell-head">Title</span>,
    accessor: (d) => {
      return { title: d.title, id: d.id };
    },
    filterMethod: (filter, row) => {
      return row.name.title.toLowerCase().includes(filter.value.toLowerCase());
    },

    id: "name",
    Cell: (props) => (
      <Link className="link" to={"./view-tasks/" + props.value.id}>
        {props.value.title}
      </Link>
    ),
    minWidth: 180,
  },
  {
    Header: (props) => (
      <span className="form-textHead cell-head">Due Date</span>
    ),
    filterMethod: (filter, row) => {
      return row.startDate.toLowerCase().includes(filter.value.toLowerCase());
    },
    accessor: "startDate",
    id: "startDate",

    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 170,
  },
  {
    Header: (props) => (
      <span className="form-textHead cell-head"> Overview</span>
    ),
    accessor: "overview",
    filterMethod: (filter, row) => {
      return row.overview.toLowerCase().includes(filter.value.toLowerCase());
    },
    id: "overview",
    Cell: (props) => (
      <span
        className="form-text cell"
        style={{ height: "140px !important", overflowY: "scroll !important" }}
        dangerouslySetInnerHTML={{ __html: props.value }}
      ></span>
    ),
    minWidth: 170,
  },
  {
    Header: (props) => <span className="form-textHead cell-head">Shared To</span>,
    accessor: (d) => {
      return { title: d.assignmentTitle, id: d.id };
    },
    filterMethod: (filter, row) => {
      return true
    },

    id: "teamMembers",
    Cell: (props) => (
      <Link className="link" to={"./view-tasks/" + props.value.id}>
        View Team
      </Link>
    ),
    minWidth: 180,
  } /*
  {
    Header: props => (
      <span className="form-textHead cell-head">Status</span>
    ),
    id: "status",
    accessor: d => {
      return {
        title: d.title,
        id: d.id
      };
    },
    Cell: props => (
      <select
        onChange={e => handleOnSelect(e, props.value)}
        name="status"
        value={props.value.status}
      >
      {statusList.map((value)=>{

return <option>{value.title}</option>
})}
      </select>
    ),
    minWidth: 200
  },*/,

  {
    Header: (props) => <span className="form-textHead cell-head">Client</span>,
    accessor: (d) => {
      return { name: d.selectedClient.name, id: d.selectedClient.id };
    },
    filterMethod: (filter, row) => {
      return row.selectedClient?.name?.toLowerCase().includes(filter.value.toLowerCase());
    },
    id: "selectedClient",
    Cell: (props) => (
      <span className="form-text cell">
        {props.value != undefined ? props.value.name : "-"}
      </span>
    ),
    minWidth: 170,
  },
  {
    Header: (props) => <span className="form-textHead cell-head">Organisation</span>,
    accessor: (d) => {
      return { name: d.selectedOrganisation ?  d.selectedOrganisation.name : '' };
    },
    filterMethod: (filter, row) => {
      return row.selectedOrganisation.name.toLowerCase().includes(filter.value.toLowerCase());
    },
    id: "selectedOrganisation",
    Cell: (props) => (
      <span className="form-text cell">
        {props.value != undefined ? props.value.name : "-"}
      </span>
    ),
    minWidth: 170,
  },
  {
    Header: (props) => (
      <span className="form-textHead cell-head">Priority</span>
    ),
    filterMethod: (filter, row) => {
      return row.priority.toLowerCase().includes(filter.value.toLowerCase());
    },
    accessor: "priority",
    id: "priority",
    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 170,
  },
  {
    Header: (props) => (
      <span className="form-textHead cell-head">Billing Type</span>
    ),
    accessor: "billingType",
    filterMethod: (filter, row) => {
      return row.billingType.toLowerCase().includes(filter.value.toLowerCase());
    },
    id: "billingType",
    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 170,
  },
  {
    Header: (props) => <span className="form-textHead cell-head">By</span>,
    accessor: "by",
    filterMethod: (filter, row) => {
      return row.by.toLowerCase().includes(filter.value.toLowerCase());
    },
    id: "by",
    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 170,
  },

  {
    Header: (props) => (
      <span className="form-textHead cell-head">Date of Creation</span>
    ),
    accessor: (d) => {
      return { date: d.date, id: d.id };
    },
    filterMethod: (filter, row) => {
      return new Date(row.date.date).toDateString().toLowerCase().includes(filter.value.toLowerCase());
    },

    id: "date",
    Cell: (props) => new Date(props.value.date).toDateString(),
    minWidth: 180,
  },
  {
    Header: (props) => (
      <span className="form-textHead cell-head">Time of Creation</span>
    ),
    accessor: (d) => {
      return { date: d.date, id: d.id };
    },
    filterMethod: (filter, row) => {
      return new Date(row.date.date).toLocaleTimeString().toLowerCase().includes(filter.value.toLowerCase());
    },

    id: "time",
    Cell: (props) => new Date(props.value.date).toLocaleTimeString(),
    minWidth: 280,
  },
];

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
export default class viewTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attachments: [],
      urls: [],
      docs: [],
      comments: [],
      opened: [],
      menu1: [],
      excelData: [],
    };
    context = this;
  }
  componentDidMount() {
    handleOnSelect = this.handleOnSelect;
    this.fetchQueries(this);
    this.fetchAllMails(this);
    this.fetchAllReources(this);
  }

  handleOnSelect = (e, query) => {
    var name = e.target.name;
    var selectedValue = e.target.value;
    var updatedData = {
      status: query.status != undefined ? query.status : "open",
      paymentStatus:
        selectedValue != undefined ? selectedValue : "Invoice Pending",
      checklists: query.checklists != undefined ? query.checklists : [],
    };
    if (name == "status") {
      updatedData = {
        status: selectedValue != undefined ? selectedValue : "open",
        paymentStatus:
          query.paymentStatus != undefined
            ? query.paymentStatus
            : "Invoice Pending",
        checklists: query.checklists != undefined ? query.checklists : [],
      };
    }

    var list = this.state.menu1;
    var index = _.findIndex(list, function (entry) {
      return entry.id == query.id;
    });

    if (name == "status") {
      list[index].status = selectedValue;
    } else {
      list[index].paymentStatus = selectedValue;
    }

    var newList = JSON.parse(JSON.stringify(list));
    context.setState({ menu1: newList });

    axios
      .post(apiBaseUrl1 + "businus/project/update/" + query.id, updatedData)
      .then(function (response) {
        debugger;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fetchAllMails(that) {
    axios
      .get(
        apiBaseUrl5 + "businus/allMails/" + localStorage.getItem("employeeId")
      )
      .then(function (response) {
        console.log(response.data);
        debugger;
        var threads =
          response.data == "" ? [] : _.sortBy(response.data, "date").reverse();

        threads = _.map(threads, function (t) {
          if (typeof t.attachment == "object") {
            t.attachment = context.getCommaSeparatedAttachment(t.attachment);
          }
          t.message = t.message.replace(/<[^>]*>/g, "");
          return t;
        });

        var documents = _.where(threads, { docType: "Document" });
        var proposals = _.where(threads, { docType: "Proposal" });
        var mails = _.where(threads, { docType: "Mail" });
        var teamMails = _.where(threads, { docType: "TEAM-MAILS" });
        var teamDocs = _.where(threads, { docType: "TEAM-DOCS" });
        that.setState({ documents, teamDocs, teamMails });
        that.setState({ proposals });
        that.setState({ mails });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fetchAllReources(that) {
    axios
      .get(
        apiBaseUrl5 +
          "businus/listAllResources/" +
          localStorage.getItem("employeeId")
      )
      .then(function (response) {
        let attachments = [];
        let docs = [];
        let urls = [];
        let comments = [];

        _.each(response.data, function (d) {
          if (d.attachment) $.merge(attachments, d.attachment);
          if (d.doc) $.merge(docs, d.doc);
          if (d.url) $.merge(urls, d.url);
          if (d.comment) $.merge(comments, d.comment);
        });

        debugger;
        that.setState({ attachments, docs, urls, comments });
      })
      .catch(function (error) {
        debugger;
        console.log(error);
      });
  }

  getFormattedDate(millis) {
    return new Date(millis).toDateString();
  }
  generateExcelData = (data) => {
    let excelData = [];
    _.each(data, function (d) {
      let row = {
        taskId: d.taskId,
        title: d.title,
        tags: d.tags,
        startDate: d.startDate,
        dueDate: d.dueDate,
        billingType: d.billingType,
        overview: d.overview.replace(/<[^>]*>/g, ""),
        selectedClient: d.selectedClient.name,
        selectedTask: d.selectedTask.title,
        teamMembers: context.getCommaSeparatedTeamName(d.sharedTo),
        priority: d.priority,
        by: d.by,
        date: new Date(d.date).toDateString(),
        time: new Date(d.date).toLocaleTimeString(),
      };

      excelData.push(row);
      console.log(excelData);
    });
    this.setState({ excelData });
  };

  getCommaSeparatedTeamName = (teamMember) => {
    console.log(teamMember);
    let names = _.map(teamMember, function (d) {
      return d.name;
    });
    console.log(names);
    return names.join(";");
  };

  getCommaSeparatedAttachment = (attachments) => {
    let names = _.map(attachments, function (d) {
      return d.attachment;
    });
    console.log(names);
    return names.join(";");
  };

  toggleOpen = (id) => {
    var list = this.state.menu1;
    var index = _.findIndex(list, function (entry) {
      return entry.id == id;
    });

    list[index].status =
      list[index].status == "open" || list[index].status == undefined
        ? "close"
        : "open";

    var newList = JSON.parse(JSON.stringify(list));
    context.setState({ menu1: newList });
    context.updateStatus(context, id, list[index].status);
  };

  fetchQueries(that, source) {
    axios
      .get(apiTaskMaster + "businusquery/task-list/" + localStorage.spId)
      .then(function (response) {
        console.log(response.data);
        that.setState({ menu1: _.sortBy(response.data, "issueId") });
        that.generateExcelData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    return (
      <div>
        <div className="Div">
          <div className="row breadcrumb-row">
            <div className="col-md-10">
              <BreadCrumbs />
            </div>
            <div className="col-md-2">
              <div className=" download-button">
                <CsvDownloader
                  text="Download in .csv format"
                  datas={this.state.excelData}
                  filename="Tasks.csv"
                  separator=","
                  wrapColumnChar=""
                  columns={columnsForExcel}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <Row>
              <Col>
                <div className="card">
                  <ReactTable
                    className="myTable"
                    resolveData={(data) => data.map((row) => row)}
                    data={this.state.menu1}
                    columns={columns}
                    filterable
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
