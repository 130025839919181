import React, { Component, createRef } from "react";

import BreadCrumbs from "../../BreadCrumbs";
import { Link } from "react-router-dom";

import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1, apiBaseUrl5 } from "../../apiUtils";
import { apiBaseUrl2 } from "../../apiUtils";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import FileBase64 from "react-file-base64";
import Modal from "react-modal";
import { ChatFeed, Message } from "react-chat-ui";
import Select from "react-select";
import SubmitButton from "../../common/SubmitButton";
import CreatableSelect from "react-select/creatable";
import { validate } from "../../common/Validation";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import _ from "underscore";
import CommonShareComponent from "../../common/CommonShareComponent";
let context = null;
const commonShareRef = createRef(null);

const data = [
  {
    name: "one",
    value: "one",
  },
  {
    name: "two",
    value: "two",
  },
  {
    name: "three",
    value: "three",
  },
  {
    name: "four",
    value: "four",
  },
  {
    name: "five",
    value: "five",
  },
  {
    name: "six",
    value: "six",
  },
];
export default class addAssignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: {
        attachment: [],
        url: [],
        comment: [],
        checklist: [],
        doc: [],
      },
      assignmentTitle: data.assignmentTitle,
      startDate: data.startDate,
      endDate: data.endDate,
      assignmentOverview: "",
      priority: data.priority,
      progressStatusList: [],
      currentTemplate: "",
      templates: [],
      sliderState: "1",
      reminderState: "1",
      teamMembers: [],
      team: "",
      teamLead: "",
      isSubmitting: false,
      occurenceArray: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        "Never",
      ],
    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber) {
    this.setState({ sliderState: orderNumber });
  }
  changeReminderState(orderNumber) {
    this.setState({ reminderState: orderNumber });
  }

  fetchStatus(that, status) {
    axios
      .get(apiBaseUrl1 + "businus/status/list/progress/" + localStorage.spId)
      .then(function (response) {
        console.log(response.data);

        response.data.unshift({ progressStatus: "Select Status" });
        that.setState({ progressStatusList: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //function for changing the status button on click to open or close
  handleOnChange = (e) => {
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });
    console.log(this.state);
  };

  handleQuillChange = (value) => {
    this.setState({ assignmentOverview: value });
    // document.getElementById('save').innerHTML = 'Add Act';
  };

  handleOnDateChange = (e) => {
    var value = e.target.value;
    var formName = e.target.name;
    var startDate = this.state.startDate;
    var d1 = new Date(startDate);
    var d2 = new Date(value);

    if (d1 < d2) {
      this.setState({ [formName]: value });
    } else {
      alert("Start date can not be greater than End date");
      this.setState({ [formName]: "" });
    }

    console.log(this.state);
  };

  handleOnTemplateChange = (e) => {
    var value = e.target.value;

    var selectedTemplate = this.state.templates[value];
    var formName = e.target.name;

    this.setState({ ["selectedTemplate"]: selectedTemplate.name });
    this.setState({ ["currentTemplate"]: value });
    this.setState({ priority: selectedTemplate.priority });
    this.setState({ visibleToClient: selectedTemplate.visibleToClient });
    this.setState({ team: selectedTemplate.team });
    if (selectedTemplate.teamLead) {
      this.setState({
        teamLead: selectedTemplate.teamLead
      });
    } 

    this.setState({ recurringFrequency: selectedTemplate.recurringFrequency });
    this.setState({ recurringNo: selectedTemplate.recurringNo });
    this.setState({ remindingFrequency: selectedTemplate.remindingFrequency });
    this.setState({ plainWords: selectedTemplate.plainWords });

    this.setState({ assignmentTitle: selectedTemplate.title });
    this.setState({ assignmentOverview: selectedTemplate.description });

    this.setState({ reminderType: selectedTemplate.reminderType });

    if (selectedTemplate.reminderType == "typeDueDate") {
      this.changeReminderState(1);
    } else if (selectedTemplate.reminderType == "typeSpecificDate") {
      this.changeReminderState(2);
    } else if (selectedTemplate.reminderType == "typeStatus") {
      this.changeReminderState(3);
    }

    if (selectedTemplate.resources) {
      this.setState({ resources: selectedTemplate.resources });
    }
  };

  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  componentDidMount() {
    this.fetchTemplates(this);
    this.fetchStatus(this);
  }

  fileUploadHandler = (that, docType) => {
    that.setState({ loading: true });
    debugger;
    axios
      .post(apiBaseUrl2 + "businusquery/uploadAttachment", {
        uploadImage: that.state.selectedFile.base64,

        imageName: that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({
            [docType]:
              "https://businus-files-live.s3.amazonaws.com/mailAttachments/" +
              that.state.selectedFile.name,
          });

          //that.successNotification("Uploaded Succesfully");
        } else {
          //   that.errorNotification("Some Error Has Occured");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleChange = (newValue, actionMeta) => {
    this.setState({ tags: newValue });
  };

  getFiles(files) {
    this.setState({ selectedFile: files[0] });
  }

  getFormattedDate(millis) {
    return new Date(millis).toDateString();
  }

  openForm = () => {
    // Create a "hidden" input
    var aux = document.createElement("input");

    let link = window.location.href.replace(
      "assignment/add-assignment",
      "userforms/assignment"
    );

    window.open(
      link,
      "Create new assignment",
      "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000"
    );
  };

  toggleOpen = (status) => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...this.state.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };

  changeDocType(type) {
    this.setState({ docType: type });
  }

  fetchTemplates(that, source) {
    axios
      .get(
        apiBaseUrl1 + "businus/template/list/Assignment/" + localStorage.spId
      )
      .then(function (response) {
        console.log(response.data);
        var templates = response.data;

        templates.unshift({ name: "custom", description: "" });
        templates.unshift({ name: "Select a template", description: "" });
        that.setState({ templates: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  sendMail() {
    var data = context.state;
    if (
      validate(
        "required",
        data.selectedOrganisation,
        data.assignmentOverview,
        data.assignmentTitle,
        data.selectedClient,
        data.team,
        data.startDate,
        data.endDate,
        data.priority,
        data.visibleToClient
      )
    ) {
      alert("Please fill all the required fields");
      return;
    }

    const result = commonShareRef.current.getCommonShareValues();
    if (!result) {
      return;
    }

    const { selectedClient, teamLead, selectedOrganisation, tags, team } =
      result;

    context.setState({ isSubmitting: true });
    axios
      .post(apiBaseUrl1 + "businus/project/add", {
        assignmentId: data.assignmentId,
        assignmentTitle: data.assignmentTitle,
        selectedTemplate: data.selectedTemplate,
        selectedClient: selectedClient,
        selectedOrganisation: selectedOrganisation,
        teamLead: teamLead != undefined ? teamLead : "NA",
        team: team,
        tags: tags,
        startDate: data.startDate,
        endDate: data.endDate,
        assignmentOverview: data.assignmentOverview,
        priority: data.priority,
        billingType: data.billingType,
        recurringFrequency: data.recurringFrequency || "NA",
        recurringNo: data.recurringNo || "NA",
        plainWords: data.plainWords,
        reminderType:
          data.reminderState === 1
            ? "typeDueDate"
            : data.reminderState === 2
            ? "typeSpecificDate"
            : data.reminderState === 3
            ? "typeStatus"
            : "",
        remindingFrequency:
          data.remindingFrequency != undefined ? data.remindingFrequency : "NA",
        remindingDate:
          data.remindingDate != undefined ? data.remindingDate : "NA",
        reminderStatus: data.reminderStatus,
        visibleToClient: data.visibleToClient,
        by: localStorage.userName,
        resources: data.resources,
        spId: localStorage.spId,
      })
      .then(function (response) {
        debugger;
        alert("Success!");
        //  context.fetchThreadById(context,query.id);
        context.setState({ isSubmitting: "done" });
        context.setState({});
      })
      .catch(function (error) {
        context.setState({ isSubmitting: false });
        console.log(error);
      });
  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    localStorage.setItem("MAILURL", url);
  }

  //updating the status button

  render() {
    const { sliderState } = this.state;
    const { reminderState } = this.state;
    const active = " active-button";
    const deactive = "";
    return (
      <div className="Div">
        <div className="row breadcrumb-row">
          <div className="col-md-8">
            <BreadCrumbs />
          </div>
          <div className="col-md-4">
            <button
              className="download-button-wrapper"
              onClick={() => this.openForm()}
            >
              {" "}
              <i style={{ fontSize: "15px" }} className="fa fa-share "></i>
            </button>
          </div>
        </div>
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page" style={{ padding: "0px" }}>
            <div className="row-resource-head">
              <h2 className="resource-head">Create Assignment </h2>
            </div>
            <div className="row ">
              <div className="col-md-12 info-column account-col2">
                <form id="field">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-9">
                        <div className="form-group">
                          <label className="control-label col-md-3">
                            Template
                          </label>
                          <div className="col-md-7">
                            <select
                              name="currentTemplate"
                              onChange={this.handleOnTemplateChange}
                              value={this.state.currentTemplate}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            >
                              {this.state.templates.map(function (template, i) {
                                return (
                                  <option value={i}>
                                    {template.templateId} {template.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-3">
                            Assignment Title
                          </label>
                          <div className="col-md-7">
                            <input
                              name="assignmentTitle"
                              onChange={this.handleOnChange}
                              value={this.state.assignmentTitle}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                        <div
                          className="form-group"
                          style={{ marginBottom: "50px" }}
                        >
                          <label className="control-label col-md-3">
                            Assignment Overview
                          </label>
                          <div className="col-md-7">
                            <ReactQuill
                              formats={[
                                "background",
                                "bold",
                                "color",
                                "font",
                                "code",
                                "italic",
                                "link",
                                "size",
                                "strike",
                                "script",
                                "underline",
                                "blockquote",
                                "header",
                                "indent",
                                "list",
                                "align",
                                "direction",
                                "code-block",
                                "formula",
                              ]}
                              value={this.state.assignmentOverview}
                              name="assignmentOverview"
                              onChange={this.handleQuillChange}
                              style={{ height: "200px" }}
                            />
                            <br />
                          </div>
                        </div>

                        {/*      <div className="form-group">
                    <label className="control-label col-md-4">
                      Customer Display Name
                    </label>
                    <div className="col-md-6">
                      <input
                          onChange={this.handleOnChange}
                          name="customerDisplayName"
                        value={this.state.customerDisplayName}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div> */}

                        <div style={{ marginTop: "100px" }} className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="control-label col-md-3">
                                Start Date:
                              </label>
                              <div className="col-md-7">
                                <input
                                  name="startDate"
                                  onChange={this.handleOnChange}
                                  value={this.state.startDate}
                                  className="form-control ember-text-field ember-view"
                                  type="date"
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="control-label col-md-3">
                                End Date:
                              </label>
                              <div className="col-md-7">
                                <input
                                  name="endDate"
                                  onChange={this.handleOnDateChange}
                                  value={this.state.endDate}
                                  className="form-control ember-text-field ember-view"
                                  type="date"
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="control-label col-md-3">
                                Billing Type
                              </label>
                              <div className="col-md-7">
                                <select
                                  name="billingType"
                                  onChange={this.handleOnChange}
                                  value={this.state.billingType}
                                  className="form-control ember-text-field ember-view"
                                  type="text"
                                >
                                  <option value="Select">Select Option</option>
                                  <option value="Billable">Billable</option>
                                  <option value="Non billable">
                                    Non billable
                                  </option>
                                </select>
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="control-label col-md-3">
                                Priority:
                              </label>
                              <div className="col-md-7">
                                <select
                                  name="priority"
                                  onChange={this.handleOnChange}
                                  value={this.state.priority}
                                  className="form-control ember-text-field ember-view"
                                  type="text"
                                >
                                  <option value="Select">
                                    Select priority
                                  </option>
                                  <option value="HIGH">High</option>
                                  <option value="MEDIUM">Medium</option>
                                  <option value="LOW">Low</option>
                                </select>
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="control-label col-md-3">
                                Visible to Client
                              </label>
                              <div className="col-md-7">
                                <select
                                  name="visibleToClient"
                                  onChange={this.handleOnChange}
                                  value={this.state.visibleToClient}
                                  className="form-control ember-text-field ember-view"
                                  type="text"
                                >
                                  <option value="">Select Option</option>
                                  <option value="NOT_VISIBLE">
                                    Not Visible
                                  </option>
                                  <option value="VISIBLE">Visible</option>
                                </select>
                              </div>
                            </div>

                            <CommonShareComponent
                              ref={commonShareRef}
                              defaultValues={{
                                id:this.state.selectedTemplate,
                                teamLead:this.state.teamLead,
                                team:this.state.team,
                              }}
                              
                              handleOnChange={this.handleOnChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row " style={{ marginTop: "40px" }}>
                    <div
                      className={
                        sliderState == 1
                          ? "col-md-6 activeOptionAss "
                          : "col-md-6"
                      }
                      style={{ backgroundColor: "#1c6193" }}
                    >
                      <div
                        className="options "
                        onClick={() => this.changeSliderState(1)}
                      >
                        Reccurence
                      </div>
                    </div>
                    <div
                      className={
                        sliderState == 2
                          ? "col-md-6 activeOptionAss "
                          : "col-md-6"
                      }
                      style={{ backgroundColor: "#3e8ac4" }}
                    >
                      <div
                        className="options"
                        onClick={() => this.changeSliderState(2)}
                      >
                        Reminders
                      </div>
                    </div>
                  </div>

                  {sliderState == 1 ? (
                    <div>
                      <div className=" header">Recurrences</div>
                      <div className="row">
                        <div className="col-md-8 offset-md-3">
                          <div className="form-group">
                            <label className="control-label col-md-3">
                              Recurring Frequency:
                            </label>
                            <div className="col-md-7">
                              <select
                                name="recurringFrequency"
                                onChange={this.handleOnChange}
                                value={this.state.recurringFrequency}
                                className="form-control ember-text-field ember-view"
                                type="text"
                              >
                                <option value="Select">Select frequency</option>
                                <option value="None">None</option>
                                <option value="Daily">Daily</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Yearly">Yearly</option>
                              </select>
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="control-label col-md-3">
                              End After:
                            </label>
                            <div className="col-md-7">
                              <select
                                name="recurringNo"
                                onChange={this.handleOnChange}
                                value={this.state.recurringNo}
                                className="form-control ember-text-field ember-view"
                                type="text"
                              >
                                <option value="Select">Select frequency</option>
                                {this.state.occurenceArray.map(function (
                                  item,
                                  i
                                ) {
                                  return <option value={item}> {item}</option>;
                                })}
                              </select>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="control-label col-md-3">
                              Plain Words:
                            </label>
                            <div className="col-md-7">
                              <input
                                name="plainWords"
                                onChange={this.handleOnChange}
                                value={this.state.plainWords}
                                className="form-control ember-text-field ember-view"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {sliderState == 2 ? (
                    <div>
                      <div className=" header">Reminder</div>
                      <div className="row ">
                        <div
                          className={
                            reminderState == 1 ? "col-md-4 active " : "col-md-4"
                          }
                        >
                          <div className="reminder-options ">
                            <input
                              type="radio"
                              onClick={() => this.changeReminderState(1)}
                              name="reminderType"
                              value="typeDueDate"
                            />
                            <label style={{ marginLeft: "10px" }}>
                              Based on Due date
                            </label>
                          </div>
                        </div>
                        <div
                          className={
                            reminderState == 2 ? "col-md-4 active " : "col-md-4"
                          }
                        >
                          <div className="reminder-options">
                            <input
                              type="radio"
                              name="reminderType"
                              onClick={() => this.changeReminderState(2)}
                              value="typeSpecificDate"
                            />
                            <label style={{ marginLeft: "10px" }}>
                              On a Specific date
                            </label>
                          </div>
                        </div>
                        <div
                          className={
                            reminderState == 3 ? "col-md-4 active " : "col-md-4"
                          }
                        >
                          <div className="reminder-options">
                            <input
                              type="radio"
                              name="reminderType"
                              onClick={() => this.changeReminderState(3)}
                              value="typeStatus"
                            />
                            <label style={{ marginLeft: "10px" }}>
                              Based on Status
                            </label>
                          </div>
                        </div>
                      </div>

                      {reminderState == 1 ? (
                        <div className="row">
                          <div className="col-md-8 offset-md-3">
                            <div className="form-group">
                              <label className="control-label col-md-3">
                                Reminder Frequency:
                              </label>
                              <div className="col-md-7">
                                <select
                                  name="remindingFrequency"
                                  onChange={this.handleOnChange}
                                  value={this.state.remindingFrequency}
                                  className="form-control ember-text-field ember-view"
                                  type="text"
                                >
                                  <option value="Select">
                                    Select frequency
                                  </option>
                                  <option value="None">None</option>
                                  <option value="Daily">Daily</option>
                                  <option value="Weekly">Weekly</option>
                                  <option value="Monthly">Monthly</option>
                                  <option value="Yearly">Yearly</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {reminderState == 2 ? (
                        <div className="row">
                          <div className="col-md-8 offset-md-3">
                            <div className="form-group">
                              <label className="control-label col-md-3">
                                Specific Date:
                              </label>
                              <div className="col-md-7">
                                <input
                                  name="remindingDate"
                                  onChange={this.handleOnChange}
                                  value={this.state.remindingDate}
                                  className="form-control ember-text-field ember-view"
                                  type="date"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {reminderState == 3 ? (
                        <div className="row">
                          <div className="col-md-8 offset-md-3">
                            <div className="form-group">
                              <label className="control-label col-md-3">
                                Status:
                              </label>
                              <div className="col-md-7">
                                <div className="head-button2">
                                  <select
                                    onChange={this.handleOnChange}
                                    className="processStatus"
                                    value={this.state.reminderStatus}
                                    name="reminderStatus"
                                  >
                                    {this.state.progressStatusList.map(
                                      (value) => {
                                        return (
                                          <option>
                                            {value.progressStatus}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-md-3">
                                Reminder Frequency:
                              </label>
                              <div className="col-md-7">
                                <select
                                  name="remindingFrequency"
                                  onChange={this.handleOnChange}
                                  value={this.state.remindingFrequency}
                                  className="form-control ember-text-field ember-view"
                                  type="text"
                                >
                                  <option value="Select">
                                    Select frequency
                                  </option>
                                  <option value="None">None</option>
                                  <option value="Daily">Daily</option>
                                  <option value="Weekly">Weekly</option>
                                  <option value="Monthly">Monthly</option>
                                  <option value="Yearly">Yearly</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="text-left">
                    <SubmitButton
                      clickHandler={this.sendMail}
                      isSubmitting={this.state.isSubmitting}
                    />
                  </div>
                  {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
