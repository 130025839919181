import React, { Component } from "react";
import "../style/panel.css";
import { Link } from "react-router-dom";
import $ from "jquery";

import MainApp from "./Home/mainApp";
import MainApp3 from "./Products/mainApp3";


 
import { getPrivilegeByName } from "./privilegeUtils";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";

const data = [];
export default class Masters extends Component {
  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.toggleState = this.toggleState.bind(this);
    this.state = {
      opened: [],
      pageName: "",
      client: {},
      employee: {},
      organisation: {},
    };
  }
  componentDidMount() {
    this.handleLine();
    var url = window.location.href.split("/")[3];
    if (url == "") {
      this.setState({
        pageName: "",
      });
    } else if (url == "account") {
      this.setState({
        pageName: "account",
      });
    } else if ((url = !""))
      this.setState({
        pageName: "common",
      });
  }

  componentWillReceiveProps(newProps) {
    this.handleLine();
    var url = window.location.href.split("/")[3];
    if (url == "") {
      this.setState({
        pageName: "",
      });
    } else if (url == "account") {
      this.setState({
        pageName: "account",
      });
    } else if ((url = !""))
      this.setState({
        pageName: "common",
      });
  }

  toggleState(com) {
    this.setState({
      pageName: com,
    });
  }

  handleLine() {
    const c1 = this.refs.c1;
    const tab1 = this.refs.tab1;
    const sidebar1 = this.refs.sidebar1;
    const section = this.refs.section;
    const c2 = this.refs.c2;
    const c3 = this.refs.c3;
    const c4 = this.refs.c4;
    const c5 = this.refs.c5;
    const c6 = this.refs.c6;
    const c7 = this.refs.c7;
    const c8 = this.refs.c8;
    const d1 = this.refs.d1;
    const d2 = this.refs.d2;
    const d3 = this.refs.d3;
    const d4 = this.refs.d4;
    const d5 = this.refs.d5;
    const d6 = this.refs.d6;
    const d7 = this.refs.d7;
    const d8 = this.refs.d8;

    $(function () {
      $("#tab1").click(function (e) {
        $(e.currentTarget).parent().toggleClass("sidebar-secondary");
        $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
        $("#section").toggle();
      });
    });
  }

  toggleOpen = (id) => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter((i) => i !== id)] });
    }
  };

  render() {
    return (
      <div>
        <div className="mainScreen">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="tab-row1">
                  <h2 className="tab-row-head">Masters</h2>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="tab text-center">
                        <Link to="/masters/client">
                          <i
                            className="	fa fa-users tab-image1"
                            style={{ fontSize: "40px" }}
                          ></i>
                          <h2 className="tab-text-main">Client Masters</h2>
                        </Link>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="tab text-center">
                        <Link to="/masters/employee">
                          <i
                            className="	fa fa-briefcase tab-image3"
                            style={{ fontSize: "40px" }}
                          ></i>
                          <h2 className="tab-text-main">Employee Masters</h2>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="tab text-center">
                        <Link to="/masters/organisation">
                          <i
                            className="	fa fa-university tab-image"
                            style={{ fontSize: "40px" }}
                          ></i>
                          <h2 className="tab-text-main">
                            Organisation Masters
                          </h2>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
