import axios from "axios";
import React, { Component } from "react";
import { ToastsStore } from 'react-toasts';
import { apiBaseUrl1, apiBaseUrl2 ,apiBaseUrl3} from "../../apiUtils";
import Select from 'react-select';
import _ from 'underscore';
import BreadCrumbs from "../../BreadCrumbs";
import CreatableSelect from "react-select/creatable";
let context = null;
const data = [];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px ",
    position: "Absolute",
    zIndex: "30000",
    backgroundColor: "none"
  }
};

export default class AddChecklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workflowId: "",
      assignments:[],
      templates:[],
      contactPersons:[],
      workflowName: "",
      currentRow:0,
      tags: [],
      message:"null",
      workflowOverview: "",
      customColumnTypes:[
        {label:"Select Type",value:"null"},
        {label:"Text",value:"text"},
        {label:"Numbers Type",value:"number"},
        {label:"Date",value:"date"},
        {label:"Description",value:"textarea"},
        {label:"File Uploader",value:"file"}
      ],
      workflow: [
        {
          isEdit:true,
          index:0,
          attachment: [],
          url: [],
          doc: [],
          comment: [],
          checklist: ""
        }
      ],
      workFlowList:[],
      columnName: "",
      contactPersonsClients:[],
      contactPersons:[],
      superSliderState: "additional",
      sliderState: 1
    };
    context = this;


    this.closeModal = this.closeModal.bind(this);
  }


    //setting tag values

    handleChange = (newValue, actionMeta) => {
      this.setState({ tags: newValue });
    };
  


  handleOnAttachment= (e) => {

    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });



    console.log(this.state);
  }

 
  fetchClients(that,source){

    axios.get(apiBaseUrl2+"businus/customer/list/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);
      that.setState({clients:_.sortBy(response.data,"customerId")});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  fetchAssignments(that,source){

    axios
    .get(
     apiBaseUrl1+"businus/project/list/" +
        localStorage.getItem("employeeId")
    )
    .then(function(response) {
      console.log(response.data);
      that.setState({ assignments: _.sortBy(response.data, "projectId") });
      that.generateExcelData(response.data);
    })
    .catch(function(error) {
      console.log(error);
    });
  }

  openWorkflowModal= ()=>{
    this.setState({ modalWorkflowIsOpen: true });
  }
  


  saveToWorkflowList = (workflow,index)=>{

    let workFlowList = this.state.workFlowList;

    workFlowList[index] = workflow;
    
  
    this.setState({ workFlowList: workFlowList });
    ToastsStore.success('Workflow Saved to Workflow List');

    /* UNCOMMENT IF WANT TO SAVE WORKFLOW CHANGES WITHOUT SUBMIT */
    /* ToastsStore.warning('Auto save in progress...');
    this.sendMail();
 */
  }

  addWorkflowInList = () =>{

    let columnName = this.state.columnName;
  
   let  newWorkflow =  {
      name:columnName
    };
    
    var workflowColumns = this.state.workFlowList;
    workflowColumns.push(newWorkflow);
    this.setState({ workFlowList: workflowColumns });
    this.setState({modalWorkflowIsOpen:false});

  }
  
  
  closeModal() {
    this.setState({ modalIsOpen: false });
    this.setState({ docModalIsOpen: false });
    this.setState({ modalWorkflowIsOpen: false });
    this.setState({ commentModalOpen: false });
  }

  
  handleOnTemplateChange = (e) => {
    debugger;
        var value = e.target.value;
    
        var selectedTemplate = this.state.templates[value];
        var formName = e.target.name;
    
    
    
    
        this.setState({ ['selectedTemplate']: selectedTemplate.title });
        this.setState({ ['currentTemplate']: value });
        this.setState({ 'checklistName': selectedTemplate.name });
        this.setState({ 'checklistOverview': selectedTemplate.update });
        this.setState({ 'checklists': selectedTemplate.checklists });
      
     
       /*  this.setState({ 'sharedTo': selectedTemplate.sharedTo });
        this.setState({ 'attachmentAssignment': selectedTemplate.hasAssignment });
        this.setState({ 'selectedAssignment': selectedTemplate.selectedAssignment }); */
    
    
      }

      fetchTemplates(that, type) {

        axios.get(apiBaseUrl1+"businus/template/list/resources/" + localStorage.spId)
          .then(function (response) {
            console.log(response.data);
            console.log(type);
            var templates = response.data;
            
           
    
    
            templates = _.where(templates,{templateType:type});
            templates.unshift({name:'custom',description:''});
            templates.unshift({name:'Select a template',description:''});
            that.setState({ templates: templates });
          })
          .catch(function (error) {
            console.log(error);
            
          });
    
      }


  componentDidMount() {
   

    this.fetchAssignments(this);
    this.fetchClients(this);
    this.fetchTemplates(this,'Checklist');
    this.fetchEmployees(this);
    //  this.fetchEmployees(this);
    //this.fetchQueryById(this, id);
    //this.fetchThreadById(this, id);
    //this.markAsRead(this, id);
  }

  handleOnChange = e => {
    debugger;
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });

    console.log(this.state);
  };



  fetchQueryById(that, id) {
    axios
      .get(apiBaseUrl2 + "businus/customer/list/"+localStorage.spId)
      .then(function(response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  

  sendMail() {
    
    var query = context.state;
  let hasAssignment = query.attachmentAssignment;
  let sharedTo = context.state.sharedTo;
  if(hasAssignment=='N'){

    sharedTo = _.union(sharedTo,context.state.sharedToClient);

  }
    
    axios
      .post(apiBaseUrl3+'businus/Checklist/add', {
     checklistId: context.state.checklistId,
     checklistName: context.state.checklistName,
     checklistOverview: context.state.checklistOverview,
     checklists:query.checklists || [],
     sharedTo:sharedTo,
     hasAssignment:context.state.attachmentAssignment,
     selectedAssignment:context.state.selectedAssignment,
     "by":localStorage.userName,
     docType:"checklist",
     tags:context.state.tags,
     spId: localStorage.spId,
      })
      .then(function(response) {
        debugger;
        ToastsStore.success('Checklist Added');

        context.setState({ subject: "" });
        context.setState({ message: "" });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    localStorage.setItem("MAILURL", url);
  }

  

  onSelect = selectedOption => {
    this.setState(
      { sharedTo:selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption)
    );
  };
  
  onRemove(optionList, removedItem) {
  context.setState({sharedTo:optionList});
  }
  

  
  handleOnAssignChange = (e) =>{
    debugger;
    const state = this.state
    state[e.target.name] = e.target.value;

    var selectedAssignment= _.findWhere(this.state.assignments,{id:e.target.value});
    let teamLead = selectedAssignment.teamLead;
    let team = selectedAssignment.team;
    let client = selectedAssignment.selectedClient;
    let contactPersons = [];
    contactPersons.push(
      {label:"Team Lead :"+teamLead.name,value:teamLead.id,...teamLead,type:'teamlead'});
      contactPersons.push(
        {label:"Client :"+client.name,value:client.id,...client,type:'client'});
        
        _.each(team,function(d){
          contactPersons.push(
            d);
        })
   // state['contactPersons'] = contactPersons;
    //state['subjectUpdate'] = selectedTemplate.update;



    state['contactPersons'] = contactPersons;


    this.setState(state);


    console.log(this.state);
  
  }
  

  fetchEmployees(that,source){

    axios.get(apiBaseUrl2+"businus/employee/list/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);

       let allEmp = response.data;
      if(localStorage.userRole!='Admin'){

        allEmp = _.filter(allEmp,function(emp){

          return emp.role!='Admin';
        });
      }
     
      let contactPersons =[];


      _.each(allEmp, function (member) {


        contactPersons.push({
          value:member.id,
            label: member.employeeId +" "+ member.firstName +" "+ member.lastName,
          name: member.employeeId +" "+ member.firstName +" "+  member.lastName,
          id: member.id,
          email:member.email
        })





      });
      
      that.setState({employees:contactPersons});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }
  
  handleOnClientChange = (e) =>{
    debugger;
    const state = this.state
    state[e.target.name] = e.target.value;

    var selectedClient= _.findWhere(this.state.clients,{id:e.target.value});
    let primaryContact = {id:selectedClient.id,name:selectedClient.primaryContact,email:selectedClient.customerEmail,label:selectedClient.primaryContact};
    let contacts = selectedClient.contactPersons;
   
    let contactPersons = [];
    contactPersons.push(primaryContact);
  
      
        _.each(contacts,function(d){
          contactPersons.push({label:d.name,type:'contact',...d});
        })
   // state['contactPersons'] = contactPersons;
    //state['subjectUpdate'] = selectedTemplate.update;



    state['contactPersonsClients'] = contactPersons;


    this.setState(state);


    console.log(this.state);
  
  }


  render() {
    const { sliderState } = this.state;
    const { superSliderState, entry } = this.state;
    const active = " active-button";
    const deactive = "";
    return (
      <div className="Div">
         <div className="row breadcrumb1-row" style={{marginLeft:'2px'}} >
      <div className="col-md-12"><BreadCrumbs/></div>
    
  </div>
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page">
            <div className="row ">
              <div className="col-md-12 info-column account-col2">
                <div id="field">
                <div className="row-resource-head">
                    <h2 className="resource-head">
                      {" "}
                      Add Checklist
                    </h2>
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-10">
                      <div className="form-group">
                        <label className="control-label col-md-3">Template</label>
                        <div className="col-md-7">
                          <select
                            name="currentTemplate"
                            onChange={this.handleOnTemplateChange}
                            value={this.state.currentTemplate}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >

                            {this.state.templates.map(function (template, i) {

                              return (<option value={i}>{template.templateId} {template.title || template.name}</option>)
                            })}

                          </select>
                        </div>
                      </div>

                        <div className="form-group">
                          <label className="control-label col-md-3">
                            Checklist Name
                          </label>
                          <div className="col-md-7">
                            <input
                              name="checklistName"
                              onChange={this.handleOnChange}
                              value={this.state.checklistName}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-3">
                              Checklist Overview
                          </label>
                          <div className="col-md-7">
                          <input
                              name="checklistOverview"
                              onChange={this.handleOnChange}
                              value={this.state.checklistOverview}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                            </div>
                         </div>
                    
                    
                
          

 
<span>
                  <div className="form-group">
                        <label className="control-label col-md-3">Client:</label>
                        <div className="col-md-7">
                        <select
                            name="selectedClient"
                            onChange={this.handleOnClientChange}
                            value={this.state.selectedClient}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >
                <option value="">Select Client</option>
                            {this.state.clients!=undefined ? this.state.clients.map(function (client, i) {

                              return (<option value={client.id}>{client.customerId} {client.companyName}</option>)
                            }):''}

                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="control-label col-md-3">Shared To (Client Contacts)</label>
                        <div className="col-md-7">

                        {this.state.contactPersonsClients != undefined ? this.state.contactPersonsClients.length > 0 ?
                             <Select
                             value={this.state.sharedToClient}
                             onChange={this.onSelectClients}
                             options={this.state.contactPersonsClients}
                             isMulti={true}
                           />
                            :  <input
                            name="subject"
                            disabled="true"
                            value='Select Client Above'
                            className="form-control ember-text-field ember-view"
                            type="text"
                          />:''}

                        
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="control-label col-md-3">Shared To (Team):</label>
                        <div className="col-md-7">

                        {this.state.employees != undefined ? this.state.employees.length > 0 ?
                             <Select
                             value={this.state.sharedTo}
                             onChange={this.onSelect}
                             options={this.state.employees}
                             isMulti={true}
                           />
                            :  '':''}

                        
                        </div>
                      </div>
                      </span>
                     
                      <div className="form-group">
                              <label className="control-label col-md-3">
                                Tags
                              </label>
                              <div className="col-md-7">
                                <CreatableSelect
                                  isMulti
                                  onChange={this.handleChange}
                                  options={this.state.tags}
                                  value={this.state.tags}
                                />
                              </div>
                            </div>


                
                 
                  <div className="text-left">
                    <button
                      type="button"
                      onClick={this.sendMail}
                      className="button1"
                    >
                      {" "}
                      Create{" "}
                    </button>
                  </div>
                  </div>
                    </div>
                  {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
