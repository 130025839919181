import React from "react";

export default function AttachmentList({ list }) {
return (
    <div
      className=" ember-text-field ember-view"
      style={{ display: "inline-flex" }}
    >{list.length}
      <ul>
        {list != undefined
          ? list.map((attach) => {
              return (
                <li>
                  <a href={attach.attachment} download>
                    {" "}
                    {attach.name} &nbsp;
                  </a>
                </li>
              );
            })
          : "No Attachments Available"}
      </ul>
    </div>
  );
}
