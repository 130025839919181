import React, { Component } from "react";

import "../../style/agency.css";
import "../../style/animate.css";
import StyleModal from "../Common/StyleModal";
import AboutUsModal from "./AboutUsModal";

/**
 * @author Megha Gautam <gautammegha2@gmail.com>
 */
/**
 * About modal component
 * this is segment of code through which data shows in about section of the website.
 * @module About
 */
class About extends Component {
  constructor(props) {
    super();
    this.state = {
      title: "",
      toggleStyleMode: false,
      hideModal: true,
      Products: [],
      showAboutUsModal: false,
      content:
        "dllus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in.",
      aboutUsImage:
        "https://www.pinclipart.com/picdir/middle/527-5270864_image-clipart-working-together-png-download.png",
      newUrl: "",
      styles: {
        backgroundImage: "Add one",
        backgroundColor: "#fff",
        color: "#000",
        headColor: "#000",
        descriptionColor: "#000",
        margin: "0px 0px 0px 0px",
        padding: "0px 60px 0px 60px",
      },
      aboutUsNodes: [
        {
          title: "Time1",
          subTitle: "Lorem ipsum",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt ut voluptatum eius sapiente, totam reiciendis temporibus qui quibusdam, recusandae sit vero unde, sed, incidunt et ea quo dolore laudantium consectetur!",
        },
        {
          title: "Time2",
          subTitle: "Beginnings",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt ut voluptatum eius sapiente, totam reiciendis temporibus qui quibusdam, recusandae sit vero unde, sed, incidunt et ea quo dolore laudantium consectetur!",
        },
        {
          title: "Time3",
          subTitle: "Beginnings",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt ut voluptatum eius sapiente, totam reiciendis temporibus qui quibusdam, recusandae sit vero unde, sed, incidunt et ea quo dolore laudantium consectetur!",
        },
        {
          title: "Time4",
          subTitle: "Beginnings",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt ut voluptatum eius sapiente, totam reiciendis temporibus qui quibusdam, recusandae sit vero unde, sed, incidunt et ea quo dolore laudantium consectetur!",
        },
      ],
    };

    this.saveStyle = this.saveStyle.bind(this);
    this.toggleStyleModal = this.toggleStyleModal.bind(this);

    this.saveAboutUs = this.saveAboutUs.bind(this);
    this.toggleAboutUsModal = this.toggleAboutUsModal.bind(this);
  }
  componentDidMount() {
    this.setState({ title: this.props.title });
    this.setState({ aboutUsImage: this.props.aboutUsImage });
    this.setState({ content: this.props.content });
    this.setState({ styles: this.props.productStyle });
  }

  /**
   * toggleStyleModal component
   * This is segment of code through which toggles style modal.
   * @module toggleStyleModal
   */

  toggleStyleModal() {
    var status = !this.state.toggleStyleMode;
    this.setState({ toggleStyleMode: status });
  }

  /**
   * toggleAboutUsModal component
   * This is segment of code through which toggles style modal.
   * @module toggleAboutUsModal
   */
  toggleAboutUsModal() {
    var status = !this.state.showAboutUsModal;
    this.setState({ showAboutUsModal: status });
  }

  /**
   * saveStyle component
   * This is segment of code through which saves style modal.
   * @module saveStyle
   */
  saveStyle(newstyle) {
    this.setState({ styles: newstyle });
    this.setState({ title: newstyle.title });

    this.props.saveSectionProperties(
      this.props.sectionKey,
      newstyle.title,
      newstyle,
      "no products",
      newstyle.content,
      newstyle.aboutImage
    );
  }

  /**
   * saveAboutUs component
   * This is segment of code which saves changes made to about us.
   * @module saveAboutUs
   */
  saveAboutUs(newContent) {
    this.props.saveSectionProperties(
      this.props.sectionKey,
      this.state.title,
      this.state.styles,
      "no products",
      newContent.content,
      newContent.headColor,
      newContent.aboutImage
    );
    this.setState({
      styles: {
        backgroundColor: newContent.backgroundColor,
        backgroundImage: newContent.backgroundImage,
        color: newContent.color,
        headColor: newContent.headColor,
      },
    });
    this.setState({ content: newContent.content });
    this.setState({ aboutUsImage: newContent.aboutImage });
  }

  render() {
    const { styles } = this.state;
    return (
      <div>
        {/* About */}
        <section
          style={{
            backgroundColor: styles.backgroundColor,
            backgroundImage:
              styles.backgroundImage != "no image"
                ? styles.backgroundImage
                : "",
            padding: styles.padding,
            margin: styles.margin,
          }}
          id={this.props.title.replace(/\s/g, "")}
        >
          {this.state.toggleStyleMode ? (
            <StyleModal
              title={this.state.title}
              saveProduct={this.saveStyle}
              toggleStyleModal={this.toggleStyleModal}
              saveStyle={this.saveStyle}
              styleProp={this.state.styles}
            />
          ) : (
            ""
          )}
          {this.state.showAboutUsModal ? (
            <AboutUsModal
              toggleStyleModal={this.toggleAboutUsModal}
              saveStyle={this.saveAboutUs}
              content={this.state.content}
              aboutUsImage={this.state.aboutUsImage}
              styleProp={this.state.styles}
            />
          ) : (
            ""
          )}
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="aboutus-section">
                  <div className="container">
                    <div className="row animatedParent">
                      <h2>
                        <span
                          className="editsign editclass"
                          onClick={() => this.toggleStyleModal()}
                        >
                          <i
                            className="fa fa-edit editclass"
                            style={{ marginLeft: "-30px" }}
                          ></i>
                        </span>
                      </h2>

                      <div className="col-md-6 col-sm-6 aboutsec1 animated fadeInLeft">
                        <div className="aboutus">
                          <h2
                            className="section-heading "
                            style={{ color: styles.color }}
                          >
                            <i
                              className="fa editclass fa-edit"
                              onClick={this.toggleAboutUsModal}
                            ></i>
                            {this.state.title}
                          </h2>
                          <p
                            style={{ color: styles.headColor }}
                            className="aboutus-text"
                          >
                            {this.state.content}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 aboutsec2 animated fadeInRight">
                        <div className="aboutus-banner">
                          <img
                            style={{ width: "90%" }}
                            src={
                              this.state.aboutUsImage != "no image"
                                ? this.state.aboutUsImage
                                : "url('https://www.pinclipart.com/picdir/middle/527-5270864_image-clipart-working-together-png-download.png')"
                            }
                            alt
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Team */}
      </div>
    );
  }
}

export default About;
