import React, { Component } from "react";
import "../../style/panel.css";
import BreadCrumbs from '../BreadCrumbs';
import { Link } from "react-router-dom";
import $ from "jquery";
import FileBase64 from 'react-file-base64';
import avatar  from "../../images/user-avatar.jpg";

import axios from 'axios';

import { apiBaseUrl2 } from '../apiUtils';
import { Auth } from 'aws-amplify';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

let context = null;
export default class AccountInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email:"",
      psw:"",
      password:"",
      name:"",
      phone:"",
      websiteName:"",
      websiteTitle:"",
      subTitle:"",
      showPassword:false,
      isEditing:false,
      profile:"",
      app:{},
      image:'',
      files:[],
      fileName:"",
      userName:'',
      isDropdownOpen:false,
      isEditingOn:false

    }
    context = this;


  }
  componentDidMount(){
    this.fetchfields();
  }


  fileUploadHandlerForAttachment = (that, docType,i) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });


          
          //that.setState({ [docType]: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          var attributesGroup = that.state.attributesGroup;
   
   

          attributesGroup[i]['attachment'] = "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name
      
          that.setState({attributesGroup:attributesGroup});

          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files-live.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  getFiles(files) {

    this.setState({ selectedFile: files[0] });

    this.fileUploadHandler(this, "profile")

  }

  getFileForAttach(files) {
    this.setState({ selectedFile: files[0] });

   

  }

   saveAccountDetails = () =>{
    
    var data = context.state;
    axios
      .post(
        apiBaseUrl2+"businus/employee/update/" + localStorage.userId,
        {

        
          "firstName": data.fname,
          "lastName": data.lname,
          "email": data.email,
          "mobile": data.phone,
          profile:data.profile,
       
        })
      .then(function (response) {
        debugger;
        alert("Success!");
        
    Auth.currentAuthenticatedUser()
    .then(user => {
      Auth.updateUserAttributes(
        user, 
        { "name":  data.fname  + " "  +  data.lname
    })  
    })
    .then(data => {


      localStorage.setItem("userName",context.state.fname + " " + context.state.lname);
      window.location.reload();


    })
    .catch(err =>alert(err.message));
        //  context.fetchThreadById(context,query.id);
      })
      .catch(function (error) {
        console.log(error);
      });


  }

  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }


  fetchfields(){
  

    context.setState({email:localStorage.getItem("userEmail")});
    context.setState({psw:""});
    context.setState({fname:localStorage.getItem("userName").split(" ")[0]});
    context.setState({lname:localStorage.getItem("userName").split(" ")[1]});
    context.setState({phone:localStorage.getItem("mobile")});
    context.setState({profile:localStorage.getItem("profile") || "https://cdn3.iconfinder.com/data/icons/business-avatar-1/512/3_avatar-512.png"});

}

  render() {
    return (
      <div>



     <div className="row breadCrumb-row" style={{marginLeft:'10px'}}>
      <BreadCrumbs/>
      </div>
      <div className="Account-Card">
    <div className="row ">
    <div className="col-md-5 text-center account-col1">
    <div class="col-md-4">

<img src={this.state.profile!="" ? this.state.profile : avatar}  className="avatar"/>   
<span>    <FileBase64
multiple={true}
onDone={this.getFiles.bind(this)} >
</FileBase64>
</span>
</div>

      </div>
    <div className="col-md-6 info-column account-col2">
    <form id="field">
    <h2 className="account-head"> Account Info</h2>
    <div className="row">
                    First Name: &nbsp;&nbsp;&nbsp;&nbsp; <input disabled={!this.state.isEditingOn} className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.fname} name="fname"/><br/>
                     Last Name: &nbsp;&nbsp;&nbsp;&nbsp; <input disabled={!this.state.isEditingOn} className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.lname} name="lname"/><br/>
                     Contact: &nbsp;&nbsp;<input className="fieldbox1"  disabled={!this.state.isEditingOn} onChange={this.onChange} type="text" value={this.state.phone} name="phone"/><br/>
                     Email: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input disabled={true} className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.email} name="email"/><br/>
                     </div>
                     <div className="text-center"><button type="button"  onClick={this.state.isEditingOn ? this.saveAccountDetails : ()=>this.setState({isEditingOn:true})} className="button1"> {this.state.isEditingOn ? 'Save' : 'Edit'} </button></div>
                                                                         {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
                   </form>



</div>

</div>
         </div>
     </div>
    );
  }
}
