import React, { Component } from "react";

import BreadCrumbs from '../../BreadCrumbs';
import { Link } from "react-router-dom";
import axios from 'axios';
import { apiBaseUrl1,apiBaseUrl5 } from '../../apiUtils';
import { apiBaseUrl2 } from '../../apiUtils';
import ReactTable from 'react-table';
import $ from "jquery";
import _ from 'underscore';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import { getCommonColumns } from "../../common/CommonColumns";

const data = [];


let context="null";

//defined all the columns and fetched the data for every cell.
const columns = [

{
  Header: props => <span className="form-textHead cell-head">Template ID</span>,
  accessor: 'templateId',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
},
{

  Header: props => <span className="form-textHead cell-head">Template Name</span>,
  accessor: d => {return {title:d.name,id:d.id}},
  filterMethod: (filter, row) =>
           { return row.name.name.toLowerCase().includes(filter.value.toLowerCase()) }
                   
                ,
  id:"name",
  Cell: props => 
  <Link className="link" to={"./view-assignmentTemplate/"+props.value.id}>{props.value.title}</Link>,
  minWidth:180 
  },
{
  Header: props => <span className="form-textHead cell-head">Title</span>,
  accessor: 'title',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
},

{
  Header: props => <span className="form-textHead cell-head">Description</span>,
  accessor: 'description',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
 
},
{
  Header: props => <span className="form-textHead cell-head">Priority</span>,
  accessor: 'priority',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
 
},
{
  Header: (props) => (
    <span className="form-textHead cell-head">Visible to Client</span>
  ),
  accessor: "visibleToClient",
  id: "visibleToClient",
  Cell: (props) => <span className="form-text cell">{props.value}</span>,
  minWidth: 160,
},

{
  Header: props => <span className="form-textHead cell-head">By</span>,
  accessor: 'by',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
 
},


{
  Header: props => <span className="form-textHead cell-head">Date of Creation</span>,
  accessor: d => {return {date:d.date,id:d.id}},
  filterMethod: (filter, row) =>
           { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }
                   
                ,
  id:"teamMembers",
  Cell: props => 
  new Date(props.value.date).toDateString(),
  minWidth:180
 
},
{
  Header: props => <span className="form-textHead cell-head">Time of Creation</span>,
  accessor: d => {return {date:d.date,id:d.id}},
  filterMethod: (filter, row) =>
           { return row.name.assignmentTitle.toLowerCase().includes(filter.value.toLowerCase()) }
                   
                ,
  id:"teamMembers",
  Cell: props => 
  new Date(props.value.date).toLocaleTimeString()
  ,
  minWidth:280
 
}
]
export default class viewAssignmentTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      menu1:[
       



 ]
 
    };
    context = this;
  }
  componentDidMount(){

  this.fetchQueries(this,this.props.source);}


  toggleOpen = id => {
    var list  =this.state.menu1;
    var index = _.findIndex(list,function(entry){return entry.id==id});
    
    list[index].status =  (list[index].status == "open" || list[index].status==undefined) ? "close" : 'open';
    
    var newList = JSON.parse(JSON.stringify( list ));
    context.setState({menu1:newList});
    context.updateStatus(context,id,list[index].status);
    
    
    
      };

  
  fetchQueries(that,source){

    axios.get(apiBaseUrl1+"businus/template/list/Assignment/"+localStorage.spId)
    .then(function (response) {
      console.log(response.data);
      that.setState({menu1:response.data});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  

  render() {
    return (
      <div>
       
      
      <div className="Div">
        <div className="row breadCrumb-row">
        <div className="col-md-10">
              <BreadCrumbs />
            </div>
      </div>
            <div className="row">
          
              <Row>
                <Col>
                  <div className="card">

                  <ReactTable className="myTable" resolveData={data => data.map(row => row)}  data={this.state.menu1} columns={columns} filterable />
                  


                    
                       
                      
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          </div>
    );
  }
}
