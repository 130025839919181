import React, { Component } from "react";
import reactCSS from "reactcss";
import "../../style/agency.css";

import { SketchPicker } from "react-color";
import "../../animations.css";
import axios from "axios";

import { apiBaseUrl, baseFilePath } from "../apiUtils";

import FileBase64 from "react-file-base64";

let context = null;

/**
 * Navbar Modal
 * It is the modal which takes in the data and style of navbar .
 * @module NavbarModal
 */
class NavbarLinkModal extends Component {
  constructor(props) {
    super();
    this.state = {
      backgroundColor: "",
      height: "",
      padding: "",
      logo: "",
      margin: "",
      color: "",
      backgroundImage: "",
      displayColorPicker: false,
      displayBgColorPicker: false,
      displayHighlightColorPicker: false,
      files: [],
      fileName: "",
      highlightColor: "",
      navLinkOrder: "",
      navLinkText: "",
    };

    context = this;
    this.uploadApi = this.uploadApi.bind(this);
    this.saveStyle = this.saveStyle.bind(this);

    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount() {
    var style = this.props.styleProp;
    this.setState({ navLinkText: style.navLinkText });
    this.setState({ navLinkOrder: style.navLinkOrder });
  }

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);

    console.log(this.state);
  };

  /**
   * uploadApi()
   * This is the api for uploading logo image to the server.
   * @function uploadApi()
   */
  uploadApi() {
    var data = {
      image: this.state.files.base64,
      fileName: this.state.files.name,
    };
    axios
      .post(apiBaseUrl + "/uploadImage", data)
      .then(function (response) {
        context.setState({ logo: response.data.Location });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  /**
   * uploadApi()
   * This is the api for temporarily storing image for uploading.
   * @function uploadApi()
   */

  getFiles(files) {
    this.setState({ files: files });
    this.uploadApi();
  }

  /**
   * saveStyle()
   * This is the api for saving the style and elements of navbar.
   * @function saveStyle()
   */

  saveStyle(index) {
    var context = this;
    var backgroundImage = "url('" + this.state.backgroundImage + "')";
    this.setState({ backgroundImage: backgroundImage });
    setTimeout(function () {
      var savingContent = context.state;
      delete savingContent.files;
      delete savingContent.fileName;
      context.props.saveStyle(savingContent);
      context.props.toggleStyleModal();
    }, 50);
  }

  /**
   * saveStyle()
   * This is the api for saving the style and elements of navbar.
   * @function saveStyle()
   */

  toggleColorPicker = (colorPicker) => {
    if (colorPicker == "bg") {
      this.setState({ displayBgColorPicker: !this.state.displayBgColorPicker });
    } else if (colorPicker == "highlight") {
      this.setState({
        displayHighlightColorPicker: !this.state.displayHighlightColorPicker,
      });
    } else {
      this.setState({ displayColorPicker: !this.state.displayColorPicker });
    }
  };

  /**
   * hideModal()
   * This is segment hides the navbar modal.
   * @function hideModal()
   */

  hideModal(key) {
    this.props.hideModal(key);
  }

  handleChangeComplete = (color) => {
    this.setState({ backgroundColor: color.hex });
  };
  handleChangeColorComplete = (color) => {
    this.setState({ color: color.hex });
  };
  handleChangeCardColorComplete = (color) => {
    this.setState({ color: color.hex });
  };

  handleChangeHighlightColorComplete = (color) => {
    this.setState({ highlightColor: color.hex });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
    this.setState({ displayBgColorPicker: false });
    this.setState({ displayHighlightColorPicker: false });
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: `${this.state.backgroundColor}`,
        },
        color2: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: `${this.state.color}`,
        },
        color3: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: `${this.state.highlightColor}`,
        },
        swatch: {
          padding: "5px",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });

    return this.state.hideModal ? (
      <div></div>
    ) : (
      <div className="modal-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <form>
                <div
                  id="contact-form"
                  className="form-container"
                  data-form-container
                  style={{ color: "rgb(46, 125, 50)", background: "white" }}
                >
                  <div className="row modal-back-color">
                    <div
                      style={{ marginLeft: "38px" }}
                      onClick={this.props.toggleStyleModal}
                    >
                      <h5
                        className="close-button"
                        style={{ marginLeft: "440px" }}
                      >
                        X
                      </h5>
                    </div>
                    <div className="form-title text-center">
                      <h5 className="modal-head"> Edit Nav Link </h5>
                    </div>
                  </div>
                  <div className="input-container">
                    <div className="row">
                      <div className="col-md-12">
                        <form id="field">
                          <div className="row">
                            <div className="col-md-3">
                              <br />{" "}
                              <h5 className="field-names">Nav Link Text </h5>{" "}
                            </div>{" "}
                            <div className="col-md-9 color modalrow">
                              <input
                                className="fieldbox"
                                onChange={this.onChange}
                                type="text"
                                value={this.state.navLinkText}
                                name="navLinkText"
                              />
                              <br />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <br />{" "}
                              <h5 className="field-names">Nav Link Order </h5>{" "}
                              <p className="subscriptt">(in digits (1,2..)</p>
                            </div>{" "}
                            <div className="col-md-9 color modalrow">
                              <input
                                className="fieldbox"
                                onChange={this.onChange}
                                type="text"
                                value={this.state.navLinkOrder}
                                name="navLinkOrder"
                              />
                              <br />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div className="row submit-row">
                      <div className="col-md-2"></div>
                      <div className="col-md-4">
                        <button
                          type="button"
                          onClick={() => this.saveStyle(this.props.keyindex)}
                          className="btn btn-block savebutton submit-form valid"
                        >
                          Save
                        </button>
                      </div>
                      <div className="col-md-4">
                        <button
                          type="button"
                          onClick={() =>
                            this.props.hideModal(this.props.keyindex)
                          }
                          className="btn btn-block cancelbutton submit-form valid"
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NavbarLinkModal;
