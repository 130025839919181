import React, { Component } from "react";
import About from "../About/About.js";
import $ from "jquery";
import { Link, withRouter } from "react-router-dom";
import Team from "../Team/Team.js";
import Timeline from "../Timeline/Testimonials.js";
import Footer from "../Footer/Footer.js";
import Products from "../Products/Products.js";
import Service from "../Services/Services.js";
import Contact from "../Contact/Contact.js";
import Query from "../Query/Query.js";
import ReactDragListView from "react-drag-listview";
import "../../style/agency.css";
import BannerImageModal from "../Banner/BannerImageModal";
import ProductSectionModal from "../Products/ProductSectionModal";
import AboutSectionModal from "../About/AboutSectionModal";
import ContactSectionModal from "../Contact/ContactSectionModal";
import QuerySectionModal from "../Query/QuerySectionModal";
import TeamSectionModal from "../Team/TeamSectionModal";
import TimelineSectionModal from "../Timeline/TimelineSectionModal";
import "../../animations.css";
import axios from "axios";
import NavbarModal from "../Navbar/NavbarModal";
import { apiBaseUrl } from "../apiUtils";
import _ from "underscore";

let context = null;

/**
 * Main Website
 * It is the published version of the combined template, comprising of the various sections .
 * @module InnerWebsitePage
 */
class InnerWebsitePage extends Component {
  constructor(props) {
    super();
    this.state = {
      pages: [],
      bannerImageUrl: "",
      navStyles: {
        backgroundColor: "#2680ee",
        height: "50px",
        padding: "",
        logo: "",

        margin: "",
        color: "#fff",
        backgroundImage: "",
      },
      isFetchingData: true,
      btnBackgroundColor: "#1266FF",
      btnColor: "#fff",
      btnLink: "products",
      btnText: "Tell Me More",
      websiteTitle: "",
      footerstyles: {
        backgroundColor: "",
        height: "",
        padding: "",
        color: "",
        copyright: "",
        policies: "",
        tou: "",
        pages: [],
        dp: "",
      },
      subTitle: "",
      showBannerImageModal: false,
      showProductSectionModal: false,
      showAboutSectionModal: false,
      showContactSectionModal: false,
      showNavbarModal: false,
      showQuerySectionModal: false,
      showTeamSectionModal: false,
      showTimelineSectionModal: false,
      showFooterModal: false,
      sections: [],
    };
    context = this;
    this.addSection = this.addSection.bind(this);
    this.toggleBannerImageModal = this.toggleBannerImageModal.bind(this);
    this.saveBannerImage = this.saveBannerImage.bind(this);
    this.toggleProductSectionModal = this.toggleProductSectionModal.bind(this);
    this.toggleAboutSectionModal = this.toggleAboutSectionModal.bind(this);
    this.toggleContactSectionModal = this.toggleContactSectionModal.bind(this);
    this.toggleQuerySectionModal = this.toggleQuerySectionModal.bind(this);
    this.toggleTeamSectionModal = this.toggleTeamSectionModal.bind(this);
    this.toggleTimelineSectionModal =
      this.toggleTimelineSectionModal.bind(this);
    this.saveProductSection = this.saveProductSection.bind(this);
    this.saveAboutSection = this.saveAboutSection.bind(this);
    this.saveContactSection = this.saveContactSection.bind(this);
    this.saveTimelineSection = this.saveTimelineSection.bind(this);
    this.saveTeamSection = this.saveTeamSection.bind(this);
    this.saveQuerySection = this.saveQuerySection.bind(this);
    this.saveWebsiteInfo = this.saveWebsiteInfo.bind(this);
    this.removeLastSection = this.removeLastSection.bind(this);
    this.removeSection = this.removeSection.bind(this);
    this.fetchSections = this.fetchSections.bind(this);
    this.toggleNavbarModal = this.toggleNavbarModal.bind(this);
    this.saveNavStyle = this.saveNavStyle.bind(this);
    this.functionPreview = this.functionPreview.bind(this);
    this.saveWebsiteToServer = this.saveWebsiteToServer.bind(this);
    this.saveSectionProperties = this.saveSectionProperties.bind(this);
    this.getAppIdByName = this.getAppIdByName.bind(this);
  }

  componentDidMount() {
    $("#applicationHeader").hide();
    this.functionPreview();
    this.afterLogin();
  }

  componentWillReceiveProps(newProps) {
    debugger;
    if (newProps.match.params.appId != this.props.match.params.appId) {
      const appId = newProps.match.params.appId;

      context.fetchSections(appId);
    } //localStorage.setItem("appId","6e6469d0-4306-11e9-9b78-8dd96189790f");
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  afterLogin() {
    //localStorage.setItem("appId","6e6469d0-4306-11e9-9b78-8dd96189790f");
    const appId = this.props.match.params.appId;

    context.fetchSections(appId);
  }

  getAppIdByName() {
    var websiteName = window.location.href.split("/")[4];
    var data = { websiteName: websiteName };
    axios
      .post(apiBaseUrl + "/getAppIdByName", data)
      .then(function (response) {
        if (response.status == 200) {
          context.fetchSections(response.data);
        }
      })
      .catch(function (error) {
        alert("Some Error has occured");
      });
  }

  fetchSections(appId) {
    axios
      .get(apiBaseUrl + "/" + appId)
      .then(function (response) {
        console.log(response.data);

        if (response.data.sections == "not created") {
          context.setState({ sections: [] });
        } else {
          context.setState({ sections: response.data.sections });
        }

        context.setState({ navStyles: response.data.websiteInfo.navStyles });
        context.setState({
          bannerImageUrl: response.data.websiteInfo.bannerImageUrl,
        });
        var title = response.data.websiteInfo.websiteTitle;
        var subTitle = response.data.websiteInfo.subTitle;
        context.setState({ websiteTitle: title == "EMPTY" ? "" : title });
        context.setState({ subTitle: subTitle == "EMPTY" ? "" : subTitle });
        context.setState({ name: response.data.websiteInfo.name });

        var btnInfo = response.data.websiteInfo.btnInfo;
        context.setState({ btnBackgroundColor: btnInfo.btnBackgroundColor });
        context.setState({ btnColor: btnInfo.btnColor });
        context.setState({ btnLink: btnInfo.btnLink });
        context.setState({ btnText: btnInfo.btnText });

        var pageType = response.data.websiteInfo.pageType;
        var parentId = response.data.websiteInfo.parentId;

        if (pageType) {
          context.setState({ parentId });
          context.setState({ pageType });
          context.setState({ navStyles: JSON.parse(localStorage.navStyles) });
          if (localStorage.pages) {
            context.setState({ pages: JSON.parse(localStorage.pages) });
          }
        } else {
          context.setState({ parentId: appId });
          context.setState({ pageType: "main" });
        }
        var footerstyles = response.data.websiteInfo.footer;
        if (footerstyles != undefined) {
          context.setState({ footerstyles: footerstyles });
        }
        context.setState({ isFetchingData: false });
      })

      .catch(function (error) {
        console.log(error);
      });
  }

  saveWebsiteToServer() {
    var appId = localStorage.getItem("appId");

    var navStyle = this.state.navStyles;
    if (this.state.navStyles.logo == "") {
      navStyle.logo = "no image";
    }
    var data = {
      websiteInfo: {
        navStyles: navStyle,
        name: "businus.com",
        bannerImageUrl:
          "https://wallpapertag.com/wallpaper/full/a/e/1/642877-cool-wallpaper-laptop-2560x1600-for-hd-1080p.jpg",
        websiteTitle: "Businus",
        subTitle: "Template Engine",
      },
      sections: this.state.sections,
    };

    axios
      .put(apiBaseUrl + "/" + appId, data)
      .then(function (response) {
        alert("PUBLISHED");
        console.log("success");
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  saveFooterProperties(newStyle) {
    var newFooter = {
      backgroundColor: newStyle.backgroundColor,
      height: newStyle.height != "" ? newStyle.height : "auto",
      color: newStyle.color != "" ? newStyle.color : "#fff",
      copyright: newStyle.copyright,
      policies: newStyle.policies,
      tou: newStyle.tou,
      dp: newStyle.dp,
    };
    this.setState({ footerstyles: newFooter });
  }
  createWebsite() {
    var data = {
      password: "admin123",
      userInfo: { phone: "8764738262", address: "abs noida" },
      footer: {
        twitter: "http://facebook.com",
        facebook: "http://facebook.com",
        linkedin: "http://facebook.com",
      },
      sections: [
        {
          innerProducts: [
            {
              showImageBox: false,
              showHeadBox: false,
              showContentBox: false,
              name: "Services 1",
              icon: "https://image.flaticon.com/icons/png/512/33/33750.png",
              styles: {
                color: "#000",
                backgroundColor: "#4a90e2",
                descriptionColor: "#000",
              },
              toggleEditMode: false,
              content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
            {
              showImageBox: false,
              showHeadBox: false,
              showContentBox: false,
              name: "seviec 2",
              icon: "https://image.flaticon.com/icons/png/512/33/33750.png",
              styles: {
                color: "#fa5d5d",
                backgroundColor: "#bd10e0",
                descriptionColor: "#000",
              },
              toggleEditMode: false,
              content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
            {
              showImageBox: false,
              showHeadBox: false,
              showContentBox: false,
              name: "Product1",
              icon: "https://image.flaticon.com/icons/png/512/33/33750.png",
              styles: {
                color: "#000",
                backgroundColor: "#fff",
                descriptionColor: "#000",
              },
              toggleEditMode: false,
              content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
          ],
          styles: {
            displayColorPicker: false,
            displayBgColorPicker: false,
            padding: "60px 10px 60px 10px",
            backgroundColor: "#fff",
            margin: "0px 0px 0px 0px",
            isBackgroundImage: true,
            color: "black",
            backgroundImage: "url('no image')",
            title: "pouutht",
          },
          title: "pouutht",
          type: "products",
          productCount: "3",
        },
        {
          styles: {
            displayColorPicker: false,
            displayBgColorPicker: false,
            padding: "60px 10px 60px 10px",
            backgroundColor: "#fff",
            margin: "0px 0px 0px 0px",
            isBackgroundImage: true,
            color: "#ee2d2d",
            backgroundImage:
              "url('url('https://blog.befunky.com/wp-content/uploads/2016/03/blur-background-featured-1.jpg')')",
            title: "New Products1",
          },
          innerProducts: [
            {
              showImageBox: false,
              showHeadBox: false,
              showContentBox: false,
              name: "Product 1",
              icon: "https://image.flaticon.com/icons/png/512/33/33750.png",
              styles: {
                color: "#ffffff",
                backgroundColor: "#b92626",
                descriptionColor: "#0b0202",
              },
              toggleEditMode: false,
              content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
            {
              showImageBox: false,
              showHeadBox: false,
              showContentBox: false,
              name: "megha",
              icon: "https://image.flaticon.com/icons/png/512/33/33750.png",
              styles: {
                color: "#ff2c2c",
                backgroundColor: "#f5a623",
                descriptionColor: "#000",
              },
              toggleEditMode: false,
              content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
            {
              showImageBox: false,
              showHeadBox: false,
              showContentBox: false,
              name: "Product1",
              icon: "https://image.flaticon.com/icons/png/512/33/33750.png",
              styles: {
                color: "#000",
                backgroundColor: "#aeaeae",
                descriptionColor: "#000",
              },
              toggleEditMode: false,
              content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
          ],
          title: "New Products1",
          type: "products",
          productCount: "3",
        },
        {
          innerProducts: [
            {
              bgcolor: "#fff",
              linkid: "dd",
              post: "lead",
              color: "#000",
              facebookid: "dd",
              name: "Product1",
              icon: "https://mycs.net.au/wp-content/uploads/2016/03/person-icon-flat.png",
              descriptionColor: "#000",
              toggleEditMode: false,
              twitterid: "dd",
            },
            {
              bgcolor: "#fff",
              linkid: "dd",
              post: "lead",
              color: "#000",
              facebookid: "dd",
              name: "Product1",
              icon: "https://mycs.net.au/wp-content/uploads/2016/03/person-icon-flat.png",
              descriptionColor: "#000",
              twitterid: "dd",
            },
            {
              bgcolor: "#fff",
              linkid: "dd",
              post: "lead",
              color: "#000",
              facebookid: "dd",
              name: "Product1",
              icon: "https://mycs.net.au/wp-content/uploads/2016/03/person-icon-flat.png",
              descriptionColor: "#000",
              twitterid: "dd",
            },
          ],
          styles: {
            displayColorPicker: false,
            displayBgColorPicker: false,
            padding: "60px 10px 60px 10px",
            backgroundColor: "#fff",
            margin: "100px 0px 0px 0px",
            isBackgroundImage: true,
            color: "black",
            backgroundImage:
              "url('https://images.pexels.com/photos/39811/pexels-photo-39811.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500')",
            title: "our team",
          },
          title: "our team",
          type: "team",
          productCount: "3",
        },
        {
          innerProducts: "no products",
          styles: {
            displayColorPicker: false,
            displayBgColorPicker: false,
            padding: "60px 10px 60px 10px",
            backgroundColor: "#fff",
            margin: "100px 0px 0px 0px",
            isBackgroundImage: true,
            color: "black",
            backgroundImage:
              "url('http://www.quoteambition.com/wp-content/uploads/2017/03/let-go-816x428.jpg')",
            title: "Contact us",
          },
          title: "Contact us",
          type: "contact",
          productCount: "0",
        },
        {
          innerProducts: "no products",
          styles: {
            displayColorPicker: false,
            displayBgColorPicker: false,
            padding: "60px 10px 60px 10px",
            backgroundColor: "#ec4444",
            margin: "100px 0px 0px 0px",
            isBackgroundImage: false,
            color: "black",
            backgroundImage: "no image",
            title: "Send us a QQHuery",
          },
          title: "Send us a QQHuery",
          type: "query",
          productCount: "0",
        },
        {
          aboutUsImage:
            "http://themeinnovation.com/demo2/html/build-up/img/home1/about1.jpg",
          innerProducts: [
            {
              showImageBox: false,
              showHeadBox: false,
              showContentBox: false,
              name: "Product1",
              icon: "https://image.flaticon.com/icons/png/512/33/33750.png",
              styles: {
                color: "#e92e2e",
                backgroundColor: "#fff",
                descriptionColor: "#000",
              },
              toggleEditMode: false,
              content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
            {
              showImageBox: false,
              showHeadBox: false,
              showContentBox: false,
              name: "hola1",
              icon: "https://image.flaticon.com/icons/png/512/33/33750.png",
              styles: {
                color: "#000",
                backgroundColor: "#d11b1b",
                descriptionColor: "#000",
              },
              toggleEditMode: false,
              content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
            {
              showImageBox: false,
              showHeadBox: false,
              showContentBox: false,
              name: "Product1",
              icon: "https://image.flaticon.com/icons/png/512/33/33750.png",
              styles: {
                color: "#000",
                backgroundColor: "#fff",
                descriptionColor: "#000",
              },
              toggleEditMode: false,
              content:
                "holaaaaaaaaaa consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
          ],
          styles: {
            padding: "60px 10px 60px 10px",
            backgroundColor: "#000",
            margin: "100px 0px 0px 0px",
            color: "black",
            backgroundImage:
              "url('https://webdesignerdepot.s3.amazonaws.com/default-images/26.jpg')",
          },
          title: "rtytyt",
          type: "products",
          productCount: "3",
          content:
            "dllus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in.",
        },
      ],
      email: "gautammegha2@gmail.com",
      id: "99d4a1d0-3d83-11e9-861d-ff0462262d64",
      name: "Megha",
      websiteInfo: {
        name: "businus.com",
        subTitle: "Template Engine",
        bannerImageUrl:
          "https://wallpapertag.com/wallpaper/full/a/e/1/642877-cool-wallpaper-laptop-2560x1600-for-hd-1080p.jpg",
        websiteTitle: "Businus",
      },
    };

    axios
      .post(apiBaseUrl, data)
      .then(function (response) {
        console.log("success");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  functionEditor() {
    $(
      function () {
        $(".editclass").fadeIn();
      },
      function () {
        // on mouseout, reset the background colour
        $(".editclass").fadeIn();
      }
    );
  }

  functionPreview() {
    $(
      function () {
        $(".editclass").hide();
      },
      function () {
        // on mouseout, reset the background colour
        $(".editclass").fadeIn();
      }
    );
  }

  componentWillUnmount() {
    $("#applicationHeader").show();
  }

  addSection(type, newSection) {
    var sections = this.state.sections;

    var sectionName = newSection.title;
    sections.push({
      title: sectionName,
      type: type,
      productCount: newSection.products,
      innerProducts: [],
      content:
        "dllus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in.",
      aboutUsImage:
        "http://themeinnovation.com/demo2/html/build-up/img/home1/about1.jpg",
      styles: {
        backgroundColor: "#000",
        padding: "60px 10px 60px 10px",
        margin: "100px 0px 0px 0px",
        color: "black",
        backgroundImage:
          "url('https://webdesignerdepot.s3.amazonaws.com/default-images/26.jpg')",
      },
    });
    this.setState({ sections: sections });
  }
  saveNavStyle(newStyle) {
    this.setState({ navStyles: newStyle });
  }

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);

    console.log(this.state);
  };

  removeLastSection() {
    var prod = this.state.sections;
    //var productName = prod.length+1;
    prod.pop();
    this.setState({ sections: prod });
  }

  removeSection(removeAt) {
    var allsections = this.state.sections;
    //var productName = prod.length+1;
    allsections.splice(removeAt, 1);
    this.setState({ sections: allsections });
  }

  toggleBannerImageModal() {
    var status = !this.state.toggleBannerImageModal;
    this.setState({ toggleBannerImageModal: status });
  }
  saveBannerImage(newBannerImageUrl) {
    this.setState({ bannerImageUrl: newBannerImageUrl });
  }
  saveWebsiteInfo(websiteTitle, subTitle) {
    this.setState({ websiteTitle: websiteTitle });
    this.setState({ subTitle: subTitle });
  }

  toggleProductSectionModal() {
    var status = !this.state.showProductSectionModal;
    this.setState({ showProductSectionModal: status });
  }

  toggleNavbarModal() {
    var status = !this.state.showNavbarModal;
    this.setState({ showNavbarModal: status });
  }

  toggleFooterModal() {
    var status = !this.state.showFooterModal;
    this.setState({ showFooterModal: status });
  }
  toggleAboutSectionModal() {
    var status = !this.state.showAboutSectionModal;
    this.setState({ showAboutSectionModal: status });
  }
  toggleTeamSectionModal() {
    var status = !this.state.showTeamSectionModal;
    this.setState({ showTeamSectionModal: status });
  }
  toggleTimelineSectionModal() {
    var status = !this.state.showTimelineSectionModal;
    this.setState({ showTimelineSectionModal: status });
  }
  toggleContactSectionModal() {
    var status = !this.state.showContactSectionModal;
    this.setState({ showContactSectionModal: status });
  }
  toggleQuerySectionModal() {
    var status = !this.state.showQuerySectionModal;
    this.setState({ showQuerySectionModal: status });
  }

  saveSectionProperties(
    index,
    newTitle,
    newStyle,
    innerProducts,
    newContent,
    aboutImage
  ) {
    var sections = this.state.sections;
    sections.forEach(function (prod, keyindex) {
      if (index == keyindex) {
        prod.title = newTitle;

        prod.styles = newStyle;

        prod.innerProducts = innerProducts;

        if (newContent != undefined) {
          prod.content = newContent;

          prod.aboutUsImage = aboutImage;
        }
      }
    });

    this.setState({ sections: sections });
  }

  saveProductSection(newSection) {
    this.addSection("products", newSection);
  }
  saveAboutSection(newTitle) {
    this.addSection("about", newTitle);
  }
  saveContactSection(newTitle) {
    this.addSection("contact", newTitle);
  }
  saveQuerySection(newTitle) {
    this.addSection("query", newTitle);
  }
  saveTeamSection(newTitle) {
    this.addSection("team", newTitle);
  }
  saveTimelineSection(newTitle) {
    this.addSection("timeline", newTitle);
  }

  removeProduct(removeAt) {
    var prod = this.state.Products;
    //var productName = prod.length+1;
    prod.splice(removeAt, 1);
    this.setState({ Products: prod });
  }

  render() {
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const { sections } = that.state;
        const item = sections.splice(fromIndex, 1)[0];
        sections.splice(toIndex, 0, item);
        that.setState({ sections });
      },
      nodeSelector: "div",
      handleSelector: "a",
    };

    return (
      <div id="published">
        {this.state.toggleBannerImageModal ? (
          <BannerImageModal
            websiteTitle={this.state.websiteTitle}
            subTitle={this.state.subTitle}
            bannerImageUrl={this.state.bannerImageUrl}
            saveBannerImage={this.saveBannerImage}
            saveWebsiteInfo={this.saveWebsiteInfo}
            toggleBannerImageModal={this.toggleBannerImageModal}
          />
        ) : (
          ""
        )}
        {this.state.showProductSectionModal ? (
          <ProductSectionModal
            saveProductSection={this.saveProductSection}
            toggleProductSectionModal={this.toggleProductSectionModal}
          />
        ) : (
          ""
        )}
        {this.state.showNavbarModal ? (
          <NavbarModal
            toggleStyleModal={this.toggleNavbarModal}
            saveStyle={this.saveNavStyle}
            styleProp={this.state.navStyles}
          />
        ) : (
          ""
        )}

        {this.state.showAboutSectionModal ? (
          <AboutSectionModal
            saveAboutSection={this.saveAboutSection}
            toggleAboutSectionModal={this.toggleAboutSectionModal}
          ></AboutSectionModal>
        ) : (
          ""
        )}
        {this.state.showContactSectionModal ? (
          <ContactSectionModal
            saveContactSection={this.saveContactSection}
            toggleContactSectionModal={this.toggleContactSectionModal}
          ></ContactSectionModal>
        ) : (
          ""
        )}
        {this.state.showQuerySectionModal ? (
          <QuerySectionModal
            saveQuerySection={this.saveQuerySection}
            toggleQuerySectionModal={this.toggleQuerySectionModal}
          ></QuerySectionModal>
        ) : (
          ""
        )}
        {this.state.showTeamSectionModal ? (
          <TeamSectionModal
            saveTeamSection={this.saveTeamSection}
            toggleModal={this.toggleTeamSectionModal}
          ></TeamSectionModal>
        ) : (
          ""
        )}
        {this.state.showTimelineSectionModal ? (
          <TimelineSectionModal
            saveTimelineSection={this.saveTimelineSection}
            toggleModal={this.toggleTimelineSectionModal}
          ></TimelineSectionModal>
        ) : (
          ""
        )}
        {/*PANEL NAV*/}

        {/*WESITE NAV*/}
        <nav
          style={{
            background: this.state.navStyles.backgroundColor,
            height: this.state.navStyles.height,
          }}
          className="navbar webzy-nav-top navbar-expand-lg navbar-dark fixed-top"
          id="mainNav"
        >
          <Link
            style={{ width: "8%" }}
            className="navbar-brand js-scroll-trigger"
            to={"./" + this.state.parentId}
          >
            {this.state.navStyles.logo != "" ? (
              <img
                style={{ width: "98%", padding: "0px 0px", height: "84%" }}
                className="webzylogo"
                src={"" + this.state.navStyles.logo}
              />
            ) : (
              this.state.websiteTitle
            )}
          </Link>

          {/*  <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        Menu
                        <i className="fas fa-bars" />
                      </button> */}

          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav text-uppercase ml-auto">
              <li
                style={{
                  marginRight: "2px",
                  marginTop: "10px",
                  listStyle: "none",
                }}
                className="nav-item"
              >
                <Link
                  style={{ color: this.state.navStyles.color }}
                  className="nav-link js-scroll-trigger"
                  to={"./" + this.state.parentId}
                >
                  Home
                </Link>
              </li>
              {this.state.pages.map((prod, i) => (
                <li
                  style={{
                    marginRight: "2px",
                    marginTop: "10px",
                    listStyle: "none",
                  }}
                  className="nav-item"
                >
                  <Link
                    style={{
                      color: this.state.navStyles.color,
                      background: prod.isHover
                        ? this.state.navStyles.highlightColor
                        : "",
                    }}
                    className="nav-link js-scroll-trigger"
                    to={"../subpage/" + prod.pageId}
                  >
                    {prod.title}
                  </Link>
                </li>
              ))}
              hello
            </ul>
          </div>
        </nav>
        <nav
          style={{
            background: this.state.navStyles.backgroundColor,
            height: this.state.navStyles.height,
          }}
          className="navbar webzy-nav-second navbar-expand-lg navbar-dark fixed-top navbar-second"
          id="mainNav"
        >
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav text-uppercase ml-auto">
              {this.state.sections.map((prod, i) => (
                <li
                  style={{
                    marginRight: "2px",
                    marginTop: "10px",
                    listStyle: "none",
                  }}
                  className="nav-item"
                >
                  <a
                    style={{ color: this.state.navStyles.color }}
                    className="nav-link js-scroll-trigger"
                    href={"#" + prod.title.replace(/\s/g, "")}
                  >
                    {prod.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </nav>
        {/* Header */}
        <header
          style={{ backgroundImage: `url(${this.state.bannerImageUrl})` }}
          className="masthead"
        >
          <div className="container">
            <div className="intro-text animatedParent">
              <div className="intro-lead-in animatedParent">
                <h1 contentEditable="true " className="animated fadeInRight">
                  {this.state.subTitle}
                </h1>
              </div>
              <div className="intro-heading text-uppercase animated fadeInLeft">
                {this.state.websiteTitle}
              </div>
              <div
                style={{
                  backgroundColor: this.state.btnBackgroundColor,
                  color: this.state.btnColor,
                }}
                className="btn btn-primary btn-xl text-uppercase js-scroll-trigger"
              >
                <a href={"#" + this.state.btnLink}>{this.state.btnText}</a>
              </div>
              <br />{" "}
              <i
                className="fa editclass  fa-edit"
                onClick={this.toggleBannerImageModal}
              ></i>
            </div>
          </div>
        </header>
        {/* Services */}
        <div className="addSection editclass ">
          <span
            className="adddivsign1 clickable"
            onClick={() => this.toggleProductSectionModal()}
          >
            + Products
          </span>
          <span
            className="adddivsign2 clickable"
            onClick={() => this.toggleProductSectionModal()}
          >
            + Services
          </span>
          <span
            className="adddivsign3 clickable"
            onClick={() => this.toggleAboutSectionModal()}
          >
            + About
          </span>
          <span
            className="adddivsign4 clickable"
            onClick={() => this.toggleTeamSectionModal()}
          >
            + Team
          </span>
          <span
            className="adddivsign8 clickable"
            onClick={() => this.toggleTimelineSectionModal()}
          >
            + Timeline
          </span>
          <span
            className="adddivsign5 clickable"
            onClick={() => this.toggleContactSectionModal()}
          >
            + Contact
          </span>

          <span
            className="adddivsign6 clickable"
            onClick={() => this.toggleQuerySectionModal()}
          >
            + Query
          </span>

          {/* <span className="adddivsign7 clickable" onClick={this.removeLastSection}>- Remove Section</span>*/}
        </div>

        <div className="sortSection editclass  ">
          <ReactDragListView {...dragProps}>
            {this.state.sections.map((item, index) => (
              <div className="sortitem" key={index}>
                <i
                  onClick={() => this.removeSection(index)}
                  className="fas fa-times"
                ></i>
                &nbsp;
                {item.title}
                <a style={{ marginLeft: "10px" }} href="#">
                  <i className="fa fa-arrows-alt"></i>
                </a>
              </div>
            ))}
          </ReactDragListView>{" "}
        </div>

        {this.state.sections.map((prod, i) =>
          prod.type == "products" ? (
            <Products
              saveSectionProperties={this.saveSectionProperties}
              productStyle={prod.styles}
              sectionKey={i}
              innerProducts={prod.innerProducts}
              productCount={prod.productCount}
              title={prod.title}
            />
          ) : prod.type == "services" ? (
            <Service
              productStyle={prod.styles}
              sectionKey={i}
              saveSectionProperties={this.saveSectionProperties}
              productCount={prod.productCount}
              title={prod.title}
            />
          ) : prod.type == "about" ? (
            <About
              productStyle={prod.styles}
              aboutUsImage={prod.aboutUsImage}
              content={prod.content}
              sectionKey={i}
              saveSectionProperties={this.saveSectionProperties}
              productCount={prod.productCount}
              title={prod.title}
            />
          ) : prod.type == "team" ? (
            <Team
              productStyle={prod.styles}
              innerProducts={prod.innerProducts}
              sectionKey={i}
              saveSectionProperties={this.saveSectionProperties}
              productCount={prod.productCount}
              title={prod.title}
            />
          ) : prod.type == "timeline" ? (
            <Timeline
              productStyle={prod.styles}
              sectionKey={i}
              innerProducts={prod.innerProducts}
              saveSectionProperties={this.saveSectionProperties}
              productCount={prod.productCount}
              title={prod.title}
            />
          ) : prod.type == "contact" ? (
            <Contact
              productStyle={prod.styles}
              sectionKey={i}
              saveSectionProperties={this.saveSectionProperties}
              title={prod.title}
            />
          ) : prod.type == "query" ? (
            <Query
              productStyle={prod.styles}
              sectionKey={i}
              saveSectionProperties={this.saveSectionProperties}
              title={prod.title}
            />
          ) : (
            ""
          )
        )}

        {!this.state.isFetchingData ? (
          <Footer
            websiteTitle={this.state.websiteTitle}
            saveFooterProperties={this.saveFooterProperties}
            footerstyles={this.state.footerstyles}
            footerLinks={this.state.sections}
          />
        ) : (
          ""
        )}

        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/js/bootstrap.min.js"></script>
      </div>
    );
  }
}

export default withRouter(InnerWebsitePage);
