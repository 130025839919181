import React, { Component } from "react";

import BreadCrumbs from "../../BreadCrumbs";
import { Link } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1, apiIssueMaster } from "../../apiUtils";
import { apiBaseUrl2 } from "../../apiUtils";
import ReactQuill from "react-quill"; // ES6
import {validate} from '../../common/Validation';
import FileBase64 from "react-file-base64";
import Modal from "react-modal";
import { ChatFeed, Message } from "react-chat-ui";
import Select from "react-select";
import SubmitButton from "../../common/SubmitButton";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import _ from "underscore";
let context = null;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px ",
    position: "Absolute",
    zIndex: "30000",
    backgroundColor: "none",
  },
};
const data = [
  {
    name: "one",
    value: "one",
  },
  {
    name: "two",
    value: "two",
  },
  {
    name: "three",
    value: "three",
  },
  {
    name: "four",
    value: "four",
  },
  {
    name: "five",
    value: "five",
  },
  {
    name: "six",
    value: "six",
  },
];
export default class AddIssues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: {
        attachment: [],
        url: [],
        comment: [],
        checklist: [],
        doc: [],
      },
      id:"",
      assignmentTitle: data.assignmentTitle,
      startDate: data.startDate,
      endDate: data.endDate,
      overview: "",
      priority: data.priority,
      clients: [],
      organisations: [],
      teamLeads: [],
      teams: [],
      progressStatusList: [],
      statuses: [],
      groups: [],
      types: [],
      currentTemplate: "",
      templates: [],
      employees: [],
      sliderState: "1",
      reminderState: "1",
      teamMembers: [],
      isSubmitting: false,
     
    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }


  componentDidMount() {
    //this.fetchQueryById(this, id);

    this.fetchGroups(this);
    this.fetchTypes(this);
    //sthis.fetchThreadById(this, id);
    this.fetchEmployees(this);
    this.fetchClients(this);
    this.fetchStatus(this);
    //this.markAsRead(this, id);
  }

  componentWillReceiveProps(newProps){
  
   this.props= newProps;

   this.setState({...this.state, ...this.props.entry,
    selectedType:this.props.entry?.selectedType?.id,
    selectedGroup:this.props.entry?.selectedGroup?.id,
    selectedStatus:this.props.entry?.selectedStatus?.id,
    selectedClient:this.props.entry?.selectedClient?.id,
    selectedOrganisation:this.props.entry?.selectedOrganisation?.id
    
  });

  }

  
  fetchStatus(that, status) {
    axios
      .get(
        apiIssueMaster + "businusquery/issue/master/STATUS/" + localStorage.spId
      )
      .then(function (response) {
        console.log(response.data);
        var statuses = response.data;
        statuses.unshift({ title: "Select Status", Description: "" });
        that.setState({ statuses });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //function for changing the status button on click to open or close
  handleOnChange = (e) => {
    debugger;
  
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });
  };

  handleQuillChange = (value) => {
    debugger;
    this.setState({ overview: value });
    // document.getElementById('save').innerHTML = 'Add Act';
  };

  handleOnDateChange = (e) => {
    var value = e.target.value;
    var formName = e.target.name;
    var startDate = this.state.startDate;
    var d1 = new Date(startDate);
    var d2 = new Date(value);

    if (d1 < d2) {
      this.setState({ [formName]: value });
    } else {
      alert("Start date can not be greater than End date");
    }

    console.log(this.state);
  };

  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  

  /*  markAsRead(that, id) {
    axios
      .get(
       apiBaseUrl2+"businusquery/read/" +
        id
      )
      .then(function (response) {


      })
      .catch(function (error) {
        console.log(error);
      });
  }
 */

  //fetching the data by id which this file recieved from the list

  /* fetchQueryById(that, id) {
    axios
      .get(
        apiBaseUrl1+"businus/customer/list/"+localStorage.spId

      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  } */

  fileUploadHandler = (that, docType) => {
    that.setState({ loading: true });
   
    axios
      .post(apiBaseUrl2 + "businusquery/uploadAttachment", {
        uploadImage: that.state.selectedFile.base64,

        imageName: that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({
            [docType]:
              "https://businus-files-live.s3.amazonaws.com/mailAttachments/" +
              that.state.selectedFile.name,
          });

          //that.successNotification("Uploaded Succesfully");
        } else {
          //   that.errorNotification("Some Error Has Occured");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  /*  fetchThreadById(that, id) {
    axios
      .get(
       apiBaseUrl2+"businusquery/listmail/" +
        id
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        var threads = response.data == "" ? [] : _.sortBy(response.data, 'date').reverse();
        that.setState({ thread: threads });

        var documents = _.where(threads, { docType: "Document" });
        var proposals = _.where(threads, { docType: "Proposal" });
        var mails = _.where(threads, { docType: "Mail" });
        that.setState({ documents });
        that.setState({ proposals });
        that.setState({ mails });


      })
      .catch(function (error) {
        console.log(error);
      });
  } */

  fetchClients(that, source) {
    axios
      .get(apiBaseUrl2 + "businus/customer/list/" + localStorage.spId)
      .then(function (response) {
        console.log(response.data);

        response.data.unshift({ clientId: "", companyName: "Select Client" });
        const assignmentData = that.props.assignmentData;

        that.setState({ clients: response.data } /* , () => {
        
          that.setState({ selectedClient: assignmentData.selectedClient.id });
        }*/);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fetchEmployees(that, source) {
    axios
      .get(apiBaseUrl2 + "businus/employee/list/" + localStorage.spId)
      .then(function (response) {
       
        console.log(response.data);
        var allEmployees = response.data;
        var employees = response.data;

        var teamMembers = allEmployees;

        var teamMembersMultiSelectData = [];

        _.each(teamMembers, function (member) {
          teamMembersMultiSelectData.push({
            value: member.id,
            label:
              member.employeeId +
              " " +
              member.firstName +
              " " +
              member.lastName,
            name:
              member.employeeId +
              " " +
              member.firstName +
              " " +
              member.lastName,
            id: member.id,
            email: member.email,
          });
        });

        employees = teamMembers;

        that.setState({
          teamMembers: teamMembersMultiSelectData,
          employees: employees,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  onSelect = (selectedOption) => {
    this.setState({ team: selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  onRemove(optionList, removedItem) {
    context.setState({ team: optionList });
  }

  onSelectShare = (selectedOption) => {
    this.setState({ sharedTo: selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  onRemoveShare(optionList, removedItem) {
    context.setState({ sharedTo: optionList });
  }

  getFiles(files) {
    this.setState({ selectedFile: files[0] });
  }

  getFormattedDate(millis) {
    return new Date(millis).toDateString();
  }

  openForm = () => {
    // Create a "hidden" input
    var aux = document.createElement("input");

    let link = window.location.href.replace(
      "assignment/add-assignment",
      "userforms/assignment"
    );

    window.open(
      link,
      "Create new assignment",
      "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000"
    );
  };

  toggleOpen = (status) => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...this.state.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };

  changeDocType(type) {
    this.setState({ docType: type });
  }

  fetchGroups(that, source) {
    axios
      .get(
        apiIssueMaster + "businusquery/issue/master/GROUP/" + localStorage.spId
      )
      .then(function (response) {
        console.log(response.data);
        var groups = response.data;
        groups.unshift({ title: "Select a Group", description: "" });
        that.setState({ groups });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  fetchTypes(that, source) {
    axios
      .get(
        apiIssueMaster + "businusquery/issue/master/TYPE/" + localStorage.spId
      )
      .then(function (response) {
        console.log(response.data);
        var types = response.data;
        types.unshift({ title: "Select a Type", description: "" });
        that.setState({ types });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleOnClientChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;

    var selectedClient = _.findWhere(this.state.clients,{id:e.target.value});
    var organisations =selectedClient.assignedOrganisation;
 
    if(organisations!="0" && organisations!==undefined){
      organisations.unshift({name:"Select Organisation",organisationId:""})
      
      console.log(organisations)
    }
    
    
    var contactPersons = selectedClient.contactPersons;
    
    contactPersons.push({email:selectedClient.customerEmail,name:selectedClient.primaryContact});
   // state['contactPersons'] = contactPersons;
    //state['subjectUpdate'] = selectedTemplate.update;


    contactPersons =   _.map(contactPersons, function (member,key) {


      member =   {
          value:member.id + key,
        label: member.name +" : "+ member.email,
        email:member.email
      };

      return member;




    });
    state['contactPersons'] = contactPersons;

    this.setState(state);

    this.setState({organisations});
    console.log(this.state);

  }


  sendMail() {
    let data = context.state;
    if(validate('required', data.title, data.overview, data.selectedClient, data.selectedOrganisation, data.selectedGroup, data.selectedStatus, data.selectedType, data.team, data.visibleToClient, data.priority, data.dueDate )){

      alert
      ("Please fill all the required fields");
      return;
    }
    if (context.props.mode == "component") {
      // data.selectedClient = this.props.assignmentData.selectedClient.id;
      data.assignmentId = context.props.assignmentData.assignmentId;
      data.queryId = context.props.assignmentData.id;
      data.sharedTo = _.union(context.props.assignmentData.team, [
        context.props.assignmentData.teamLead,
      ]);
      data.team =  data.sharedTo;
    }

    var selectedClient = _.findWhere(data.clients, { id: data.selectedClient });

    if (selectedClient) {
      selectedClient = {
        id: selectedClient.id,
        name: selectedClient.primaryContact,
        email: selectedClient.customerEmail,
        companyName: selectedClient.companyName,
        mode: selectedClient.mode,
      };
    } else {
      alert("Client can't be blank");
      return;
    }

    var selectedGroup = _.findWhere(context.state.groups, { id: data.selectedGroup });
    var selectedType = _.findWhere(context.state.types, { id: data.selectedType });
    var selectedStatus = _.findWhere(context.state.statuses, { id: data.selectedStatus });
    var selectedOrganisation= _.findWhere(context.state.organisations, { id: data.selectedOrganisation });
    
    let sharedTo = data.sharedTo;

    context.setState({ isSubmitting: true });
    let payload={ id:data.id,
       issueId: data.issueId,
      title: data.title,
      selectedType: selectedType,
      tags: data.tags,
      selectedStatus: selectedStatus,
      selectedGroup: selectedGroup,
      selectedOrganisation: selectedOrganisation,
      selectedClient: selectedClient,
      team: data.team,
      status: data.status,
      dueDate: data.dueDate,
      overview: data.overview,
      priority: data.priority,
      visibleToClient: data.visibleToClient,
      by: localStorage.userName,
      sharedTo: sharedTo,
      spId: localStorage.spId,
      assignmentId: data.assignmentId || 0,
      queryId: data.queryId || 0,}

    if(context.props.mode==="Edit"){
      axios
      .put(apiIssueMaster + "businusquery/issue/" + context.props.entry.id,
          payload,
        )
        .then(function (response) {
         
          alert("Success!");

          if (context.props.onSaveHandler) {
            context.props.onSaveHandler(response);
          }
          window.location.reload(false);
          context.setState({ isSubmitting: "done" });
        })
        .catch(function (error) {
          context.setState({ isSubmitting: false });
          console.log(error);
        });
    }
    else{
      axios
        .post(apiIssueMaster + "businusquery/issue", 
          payload,
        )
        .then(function (response) {
         
          alert("Success!");

          if (context.props.onSaveHandler) {
            context.props.onSaveHandler(response);
          }
          window.location.reload(false);
          context.setState({ isSubmitting: "done" });
        })
        .catch(function (error) {
          context.setState({ isSubmitting: false });
          console.log(error);
        });
    }
  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    localStorage.setItem("MAILURL", url);
  }

  handleChange = (newValue, actionMeta) => {
    this.setState({ tags: newValue });
  };

  //updating the status button

  render() {
    const { sliderState } = this.state;
    const { reminderState } = this.state;
    const active = " active-button";
    const deactive = "";
    return (
      <div className="Div">
        {this.props.mode != "component" ? (
          <div className="row breadcrumb-row">
            <div className="col-md-8">
              <BreadCrumbs />
            </div>
            <div className="col-md-4"></div>
          </div>
        ) : (
          ""
        )}
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page" style={{ padding: "0px" }}>
            <div className="row-resource-head">
              <h2 className="resource-head">{this.props.mode || "Create"} Issue </h2>
            </div>
            <div className="row ">
              <div className="col-md-12 info-column account-col2">
                <form id="field">
                  <div className="container" style={{paddingLeft:"40px"}}>
                    <div className="row">
                      <div className="col-md-9">
                        <div className="form-group">
                          <label className="control-label col-md-3">
                            Issue Title
                          </label>
                          <div className="col-md-7">
                            <input
                              name="title"
                              onChange={this.handleOnChange}
                              value={this.state.title}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="row">
                        <div
                          className="form-group"
                       
                        >
                          <label className="control-label col-md-3">
                            Overview
                          </label>
                          <div className="col-md-7">
                            <ReactQuill
                              value={this.state.overview}
                              name="overview"
                              onChange={this.handleQuillChange}
                              style={{ height: "200px" }}
                            />
                            <br />
                          </div>
                        </div>
                        </div>

                        <div className="row">
                        <div className="form-group">
                          <label className="control-label col-md-3">
                            Status
                          </label>
                          <div className="col-md-7">
                            <select
                              name="selectedStatus"
                              onChange={this.handleOnChange}
                              value={this.state.selectedStatus}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            >
                              {this.state.statuses.map(function (status, i) {
                                return (
                                  <option value={status.id}>{status.title}</option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        </div>
                        <div className="row">
                        <div className="form-group">
                          <label className="control-label col-md-3">
                            Group
                          </label>
                          <div className="col-md-7">
                            <select
                              name="selectedGroup"
                              onChange={this.handleOnChange}
                              value={this.state.selectedGroup}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            >
                              {this.state.groups.map(function (group, i) {
                                return (
                                  <option value={group.id}>{group.title}</option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        </div>
                        <div className="row">
                        <div className="form-group">
                          <label className="control-label col-md-3">Type</label>
                          <div className="col-md-7">
                            <select
                              name="selectedType"
                              onChange={this.handleOnChange}
                              value={this.state.selectedType}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            >
                              {this.state.types.map(function (type, i) {
                                return (
                                  <option value={type.id}>{type.title}</option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        </div>

                        {/*      <div className="form-group">
                    <label className="control-label col-md-4">
                      Customer Display Name
                    </label>
                    <div className="col-md-6">
                      <input
                          onChange={this.handleOnChange}
                          name="customerDisplayName"
                        value={this.state.customerDisplayName}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div> */}

                        <div  className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="control-label col-md-3">
                                Due Date:
                              </label>
                              <div className="col-md-7">
                                <input
                                  name="dueDate"
                                  onChange={this.handleOnChange}
                                  value={this.state.dueDate}
                                  className="form-control ember-text-field ember-view"
                                  type="date"
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="control-label col-md-3">
                                Priority:
                              </label>
                              <div className="col-md-7">
                                <select
                                  name="priority"
                                  onChange={this.handleOnChange}
                                  value={this.state.priority}
                                  className="form-control ember-text-field ember-view"
                                  type="text"
                                >
                                  <option value="Select">
                                    Select priority
                                  </option>
                                  <option value="HIGH">High</option>
                                  <option value="MEDIUM">Medium</option>
                                  <option value="LOW">Low</option>
                                </select>
                              </div>
                            </div>

                            <div className="row">
                              <div className="form-group">
                                <label className="control-label col-md-3">
                                  Visible to Client
                                </label>
                                <div className="col-md-7">
                                  <select
                                    name="visibleToClient"
                                    onChange={this.handleOnChange}
                                    value={this.state.visibleToClient}
                                    className="form-control ember-text-field ember-view"
                                    type="text"
                                  >
                                    <option value="Select">
                                      Select Option
                                    </option>
                                    <option value="NOT_VISIBLE">
                                      Not Visible
                                    </option>
                                    <option
                                      disabled={
                                        this.state.selectedClientMode == "both"
                                      }
                                      value="VISIBLE"
                                    >
                                      Visible
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="control-label col-md-3">
                                Tags
                              </label>
                              <div className="col-md-7">
                                <CreatableSelect
                                  isMulti
                                  onChange={this.handleChange}
                                  options={this.state.tags}
                                  value={this.state.tags}
                                />
                              </div>
                            </div>

                            {this.props.mode!='component' ?<>   <div className="form-group">
                              <label className="control-label col-md-3">
                                Client:
                                
                              </label>
                              <div className="col-md-7">
                                <select
                                  name="selectedClient"
                                  onChange={this.handleOnClientChange}
                                  value={this.state.selectedClient}
                                  className="form-control ember-text-field ember-view"
                                  type="text"
                                >
                                  {this.state.clients.map(function (client, i) {
                                    return (
                                      <option value={client.id}>
                                        {client.customerId} {client.companyName}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div> 
                            
                            <div className="form-group">
                        <label className="control-label col-md-3">Organisation:</label>
                        <div className="col-md-7">
                          <select
                            name="selectedOrganisation"
                             onChange={this.handleOnChange}
                            value={this.state.selectedOrganisation}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >

                            {this.state.organisations.map(function (organisation, i) {

                              return (<option value={organisation.id}> {organisation.organisationId+" "+organisation.name}</option>)
                            })}

                          </select>
                        </div>
                      </div>

                            <div className="form-group">
                              <label className="control-label col-md-3">
                                Team:
                              </label>
                              <div className="col-md-7">
                                {this.state.teamMembers.length > 0 ? (
                                  <Select
                                    value={this.state.team}
                                    onChange={this.onSelect}
                                    options={this.state.teamMembers}
                                    isMulti={true}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-md-3">
                                Shared To (Team):
                              </label>
                              <div className="col-md-7">
                                {this.state.employees.length > 0 ? (
                                  <Select
                                    value={this.state.sharedTo}
                                    onChange={this.onSelectShare}
                                    options={this.state.teamMembers}
                                    isMulti={true}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div></> : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div></div>

                  <div className="text-left">
                    <SubmitButton
                      clickHandler={this.sendMail}
                      isSubmitting={this.state.isSubmitting}
                    />
                  </div>
                  {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
